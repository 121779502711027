import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import level3 from "../../assets/level3.svg";
import idCard from "../../assets/idCard.svg";
import passport from "../../assets/passport.svg";
import driverLicense from "../../assets/driverLicense.svg";
import nin from "../../assets/nin.svg";
import Modal from "./Modal";
import KycFileUpload from "./KycFileUpload";
import KycSuccess from "./KycSuccess";
import KycPhoto from "./KycPhoto";
import KycSuccess3 from "./KycSuccess3";
import { useUserContext } from "../../context/UserContext";

const KycUpload = () => {
  const {user} = useUserContext()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const { setFile } = useUserContext();

  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    } else {
      toggleModal(); // Close the modal if it's the last step
    }
  };

  const renderModalContent = () => {
    switch (currentStep) {
      case 1:
        return <KycFileUpload onNext={nextStep} />;
      case 2:
        return <KycSuccess onNext={nextStep} />;
      case 3:
        return <KycPhoto onNext={nextStep} />;
      case 4:
        return <KycSuccess3 />;
      default:
        return null;
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClick = (e) => {
    const spanText = e.currentTarget.querySelector("span > span").textContent;
    setFile(undefined, spanText);
    toggleModal();
  };

  console.log(user)
  
  return (
    <div className="bg-light-grey mt-12 px-6 py-10 rounded-xl lg:mr-14">
      <div className="flex justify-between items-center">
        <h1 className="font-Space-Grotesk text-xl font-bold">Level 3</h1>
        <img className="w-1/6 h-1/6" src={level3} alt="level 3 progress" />
      </div>

      <p className="py-7 lg:w-3/4 w-full font-inter text-md capitalize ">
        Be prepared to take a photo of the selected document, or be ready to
        verify your I.D by uploading your legal document, Kindly select one to
        verify{" "}
      </p>

      <div className="lg:w-4/6 w-full mx-auto">
        <button
          onClick={handleClick}
          className="bg-white flex justify-between items-center w-full px-4 py-3 rounded-xl font-inter mb-5"
        >
          <span className="flex items-center">
            {" "}
            {/* Use span instead of div */}
            <img className="px-5" src={idCard} alt="idcard" />
            <span>National ID Card</span> {/* Text next to the image */}
          </span>
          <IoIosArrowForward size={30} /> {/* Icon at the end */}
        </button>

        <button
          onClick={handleClick}
          className="bg-white flex justify-between items-center w-full px-4 py-3 rounded-xl font-inter mb-5"
        >
          <span className="flex items-center">
            {" "}
            {/* Use span instead of div */}
            <img className="px-5" src={passport} alt="idcard" />
            <span>International Passport</span> {/* Text next to the image */}
          </span>
          <IoIosArrowForward size={30} /> {/* Icon at the end */}
        </button>

        <button
          onClick={handleClick}
          className="bg-white flex justify-between items-center w-full px-4 py-3 rounded-xl font-inter mb-5"
        >
          <span className="flex items-center">
            {" "}
            {/* Use span instead of div */}
            <img className="px-5" src={driverLicense} alt="idcard" />
            <span>Driver&apos;s License </span> {/* Text next to the image */}
          </span>
          <IoIosArrowForward size={30} /> {/* Icon at the end */}
        </button>

        <button
          onClick={handleClick}
          className="bg-white flex justify-between items-center w-full px-4 py-3 rounded-xl font-inter mb-5"
        >
          <span className="flex items-center">
            {" "}
            {/* Use span instead of div */}
            <img className="px-5" src={nin} alt="idcard" />
            <span>Voters ID</span> {/* Text next to the image */}
          </span>
          <IoIosArrowForward size={30} /> {/* Icon at the end */}
        </button>
        <button
          onClick={handleClick}
          className="bg-white flex justify-between items-center w-full px-4 py-3 rounded-xl font-inter mb-5"
        >
          <span className="flex items-center">
            {" "}
            {/* Use span instead of div */}
            <img className="px-5" src={nin} alt="idcard" />
            <span>NIN</span> {/* Text next to the image */}
          </span>
          <IoIosArrowForward size={30} /> {/* Icon at the end */}
        </button>
      </div>

      <Modal isOpen={isModalOpen} onClose={toggleModal}>
        {renderModalContent()}
      </Modal>
      
    </div>
  );
};

export default KycUpload;
