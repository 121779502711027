import React from "react";
import success from "../../assets/success_gold.svg";

const KycSuccess = ({onNext}) => {
  return (
    <div>
      <div className="flex justify-center items-center">
        <img className="w-1/4 h-1/4" src={success} alt="success" />{" "}
      </div>
      <h2 className="text-center font-inter my-5 font-bold text-3xl">Success</h2>
      <p className="text-center font-inter my-5">
        Your Level 2 verification is successful.
      </p>
      <div className="flex justify-center mt-10">
        <button
          onClick={onNext}
          className="bg-black text-white font-Space-Grotesk px-10 py-3 rounded-lg "
        >
          Take A Picture
        </button>
      </div>

    </div>
  );
};

export default KycSuccess;
