import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineClose,
} from "react-icons/ai";
import { CgSpinner } from "react-icons/cg";
import { errStyle, eyeStyle, product_name } from "../constants/details";
import {
  BASE_URL,
  CONFIRM_EMAIL_URL,
  SIGNUP_URL,
  rootLinks,
} from "../constants/links";
import { Bars } from "react-loader-spinner";
import success_gold from "../assets/success_gold.svg";
import Modal from "../components/footer/modal";
import logo from "../assets/logo.svg";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";
import { useUserContext } from "../context/UserContext";
import { useAuthContext } from "../context/AuthContext";

const SignupPage = () => {
  const { setUser, setWallets, setSelectedWallet } = useUserContext();
  const { setToken } = useAuthContext();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [showsecondpassword, setshowsecondpassword] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalSwitch, setModalSwitch] = useState({
    showModal: null,
  });
  const navigate = useNavigate();

  function toggleModal(modalId) {
    setModalSwitch((s) => ({
      ...s,
      showModal: s.showModal === modalId ? null : modalId,
    }));
  }
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    fullName: "",
    phoneNumber: "",
    secondPassword: "",
  });
  const [otp, setOtp] = useState("");
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    name: "weak",
    color: "red",
    message:
      "The string must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character and at least one special character",
  });

  // Using regex literals for simplicity and performance
  const strongRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
  const mediumRegex =
    /^(?:(?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[0-9])).{6,}$/;

  const checkPassword = (e) => {
    const newPassword = e.currentTarget.value;
    setFormData({ ...formData, password: newPassword });
    setShowPasswordStrength(true); // Assuming this shows the password strength indicator

    const uppercasePattern = /[A-Z]/; // Regex to check for at least one uppercase letter

    // Check for strong password
    if (strongRegex.test(newPassword)) {
      setPasswordStrength({
        name: "Strong",
        color: "green",
        message: "Good to go!",
      });
    } else if (mediumRegex.test(newPassword)) {
      setPasswordStrength({
        name: "Medium",
        color: "yellow",
        message:
          "Improve the password to make it stronger. Add special characters and numbers.",
      });
    } else if (newPassword.length < 8) {
      setPasswordStrength({
        name: "Weak",
        color: "red",
        message: "The password must be longer and more complex.",
      });
    }

    // Additional feedback for missing uppercase letter
    if (!uppercasePattern.test(newPassword)) {
      setPasswordStrength({
        name: "Notice",
        color: "red",
        message: "Please include at least one uppercase letter.",
      });
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));
  // function handleCreateWallet(e) {
  //   e.preventDefault();
  //   setLoading(true);

  //   //login in to get auth token
  //   axios
  //     .post(LOGIN_URL, {
  //       email: formData.email,
  //       password: formData.password,
  //     })
  //     .then((res) => {
  //       setLoading(false);
  //       setError(false);
  //       const token = res.data.token;
  //       const config = {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       };
  //       axios
  //         .post(
  //           CREATE_WALLET,
  //           {
  //             wallet_pin,
  //             id: user._id,
  //           },
  //           config
  //         )
  //         .then((res) => {
  //           setLoading(false);
  //           setEmailConfirmed(true);
  //           setWalletCreation(false);
  //           console.log(res);
  //         })
  //         .catch((err) => {
  //           setLoading(false);
  //           setError(true);
  //           setErrorMessage(err.response.data.message);
  //           console.log(err);
  //         });
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       setError(true);
  //       if (err.message) {
  //         setErrorMessage(err.message);
  //       } else {
  //         setErrorMessage(err.response.data.message);
  //       }
  //     });
  // }

  async function handleConfirmEmail(e) {
    e.preventDefault();
    setLoading(true);
    console.log(user);
    await axios
      .post(CONFIRM_EMAIL_URL, {
        otp,
        id: user._id,
      })
      .then((res) => {
        setLoading(false);
        setEmailConfirmed(true);
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setErrorMessage(err.response.data.message);
        console.log(err);
      });
  }

  const ConfirmPassword = (e) => {
    setFormData({ ...formData, secondPassword: e.currentTarget.value });
    if (formData.password !== e.currentTarget.value) {
      setError(true);
      setConfirmPasswordErrorMessage("Passwords do not match");
    } else {
      setError(false);
      setConfirmPasswordErrorMessage("");
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (passwordStrength.name !== "Strong") {
      setError(true);
      setErrorMessage(passwordStrength.message);
      setLoading(false);
      return;
    }
    await axios
      .post(SIGNUP_URL, {
        email: formData.email,
        password: formData.password,
        fullName: formData.fullName,
        phoneNumber: formData.phoneNumber,
      })
      .then((res) => {
        setLoading(false);
        setShowModal(true);
        setError(false);
        setEmailConfirmed(false);
        console.log(res);
        localStorage.setItem("user", JSON.stringify(res.data.data));
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        if (err.message === "Network Error") {
          setErrorMessage(
            "Network Error, Please check your internet connection"
          );
        } else {
          setErrorMessage("Email already exists");
        }
      });
  }

  const signup = useGoogleLogin({
    onSuccess: codeResponse => {
      const code = codeResponse.code;
      axios
        .post(`${BASE_URL}/auth/google/signup/callback`, {
          code: code,
        })
        .then((res) => {
          console.log(res);
          const tokenData = {
            token: res?.data?.token,
            expiresIn: res?.data?.expiresIn,
          };

          const userData = res?.data?.data;

          setLoading(false);
          setToken(tokenData);
          setUser(userData);
          setWallets(res.data.wallets);
          setSelectedWallet(Array(...res.data.wallets)[0]);
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setError(true);
        if (err.message === "Network Error") {
          setErrorMessage(
            "Network Error, Please check your internet connection"
          );
        } else {
          setErrorMessage("Email already exists");
        }
        });
    },
    flow: 'auth-code'
  });


  const handleSignup = (event) => {
    event.preventDefault(); // Prevent the default form submission
    setLoading(true);
    signup(); // Call the Google login function

  };


  return (
    <div className=" relative transition-all ease-in-out duration-300">
      {showModal && !emailConfirmed && (
        <div className="bg-black/80  fixed inset-0 flex justify-center items-center z-50">
          <div className="bg-white p-8 lg:w-1/3 w-full  mx-5 lg:mx-auto">
            {/* <div className="bg-white absolute flex justify-center items-center w-screen h-screen z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"> */}
            <div
              className=" text-right left-full w-max p-1 relative cursor-pointer hover:bg-gray-300"
              onClick={() => setShowModal(false)}
            >
              <AiOutlineClose />
            </div>
            <div className=" mx-auto w-2/5 md:mb-4">
              <img src={success_gold} alt="success" />
            </div>
            <form
              className=" text-center md:w-3/4 mx-auto"
              onSubmit={(e) => handleConfirmEmail(e)}
            >
              <p className=" text-sm">
                Check email {formData.email} for an OTP to confirm your email
              </p>
              <div className=" bg-gray-300  p-2 my-2">
                <input
                  type="number"
                  className=" bg-gray-300 w-full tracking-widest focus:outline-none text-center"
                  placeholder="123456"
                  name="otp"
                  inputMode="numeric"
                  disabled={loading}
                  value={otp}
                  max={999999}
                  min={100000}
                  onChange={(e) => setOtp(e.currentTarget.value)}
                />
              </div>
              {error && <p className={errStyle}>{errorMessage}</p>}
              <div>
                <button className=" p-3 bg-black hover:bg-gray-900 text-white text-center rounded-md">
                  Confirm Email
                </button>
              </div>
              <p className=" text-xs text-red-500 py-2">
                If not found check your spam folder and star the email.
              </p>
            </form>
          </div>
        </div>
      )}
      {showModal && emailConfirmed && (
        <div className="bg-black/80  fixed inset-0 flex justify-center items-center z-50">
          <div className="bg-white p-8 lg:w-1/3 w-full mx-5 lg:mx-auto">
            <div
              className="absolute right-0 top-0 p-2 cursor-pointer hover:bg-gray-300"
              onClick={() => setShowModal(false)}
            >
              <AiOutlineClose />
            </div>
            <div className="mx-auto w-1/2">
              <img src={success_gold} alt="Success" />
            </div>
            <p className="text-3xl text-center text-green-500">Success</p>
            <p className="py-6 text-center">
              Your verification is successful, let&apos;s start making money
            </p>
            <Link to={rootLinks.signin_link}>
              <div className="text-center">
                <p className="p-2 bg-black text-white rounded-md">Login</p>
              </div>
            </Link>
          </div>
        </div>
      )}
      <div className=" flex justify-start items-center">
        {loading && (
          <div className="bg-black bg-cover opacity-60 fixed inset-0 flex justify-center items-center w-full h-screen z-50">
            <Bars
              height="100"
              width="300"
              color="#FFBF00"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClassName="mx-auto"
              visible={true}
            />
          </div>
        )}

        <div className="flex-1 bg-auth-pattern bg-cover w-full md:w-7/12 min-h-screen lg:h-auto md:mt-0">
          <div>
            <Link to="/">
              <div className="block ipad-portrait:block ipad-landscape:block lg:block cursor-pointer w-52 py-4 pl-10">
                <img
                  src={logo}
                  alt={`${product_name} logo`}
                  className="w-4/5 md:w-4/5 lg:w-full"
                />
              </div>
            </Link>
          </div>

          <div className="flex min-h-[90vh] lg:h-auto items-center">
            <div className="lg:w-1/3 hidden lg:flex justify-center flex-col pl-10">
              <h1 className="font-Smooch text-gold-light text-6xl leading-none">
                Welcome!
              </h1>
              <p className="font-bold text-4xl leading-1">
                Create your <br></br> Cambio Account
              </p>
            </div>

            <form
              className=" lg:mx-auto flex-1 flex flex-col items-center mb-7"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="bg-white lg:w-[50%] lg:mx-0 w-11/12 mx-10 rounded-3xl py-8">
                <h2 className=" text-2xl md:text-2xl lg:leading-3 font-bold text-center text-black lg:mb-4 ">
                  Sign Up
                </h2>
                <p className=" text-center text-xs text-pri-grey/60">
                  Join the fastest and safest Giftcard app <br></br> with the
                  best rates in minutes.
                </p>

                <div className="w-10/12 mx-auto pt-3">
                  <label htmlFor="email" className="text-base text-pri-grey">
                    FullName <span className="text-red-500">*</span>
                  </label>
                  <div className="rounded-lg p-0 overflow-hidden border-2 border-solid border-black focus-within:border-gold">
                    <input
                      type="text"
                      minLength={3}
                      className="px-4 py-3 w-full focus:outline-none placeholder:text-black/50 "
                      name="fullName"
                      value={formData.fullName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          fullName: e.currentTarget.value,
                        })
                      }
                      placeholder="Full Name"
                      required
                    />
                  </div>
                </div>

                <div className="w-10/12 mx-auto pt-3">
                  <label htmlFor="email" className="text-base text-pri-grey">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <div className="border-solid border-black border-2 rounded-lg p-0 overflow-hidden focus-within:border-gold ">
                    <input
                      type="email"
                      className="px-4 py-3 w-full placeholder:text-black/50 focus:outline-none invalid:text-red-600"
                      disabled={loading}
                      name="email"
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          email: e.currentTarget.value,
                        })
                      }
                      placeholder="Enter Your Email"
                      required
                    />
                  </div>
                </div>

                {/* <div className="lg:w-4/5 w-4/5 mx-auto pt-3">
                  <label htmlFor="email" className="text-base text-pri-grey">
                    Phone Number <span className="text-red-500">*</span>
                  </label>
                  <div className=" border-solid border-black border-2 bg-pri-pink rounded-lg p-0 overflow-hidden focus-within:border-gold">
                    <input
                      type="text"
                      maxLength="11"
                      disabled={loading}
                      className="px-4 py-3 w-full placeholder:text-black/50 focus:outline-none"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={(e) => {
                        let value = e.target.value;
                        // Ensure only numbers are entered by removing non-numeric characters
                        value = value.replace(/\D/g, "");
                        if (value.length > 11) {
                          value = value.slice(0, 11); // Ensures the length does not exceed 11 digits
                        }
                        setFormData({
                          ...formData,
                          phoneNumber: value,
                        });
                      }}
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                </div> */}

                <div className="w-10/12 mx-auto pt-3">
                  <label htmlFor="email" className="text-base text-pri-grey">
                    Password <span className="text-red-500">*</span>
                  </label>
                  <div className=" flex justify-between items-center border-2 border-solid border-black rounded-lg p-0 overflow-hidden mb-2 focus-within:border-gold">
                    <input
                      type={show ? "text" : "password"}
                      name="password"
                      className=" px-4 py-3 w-full placeholder:text-black/50 focus:outline-none"
                      value={formData.password}
                      disabled={loading}
                      onChange={checkPassword}
                      placeholder="Password"
                      required
                    />
                    {show ? (
                      <div className={eyeStyle} onClick={() => setShow(!show)}>
                        <AiOutlineEye />
                      </div>
                    ) : (
                      <div className={eyeStyle} onClick={() => setShow(!show)}>
                        <AiOutlineEyeInvisible />
                      </div>
                    )}
                  </div>

                  {showPasswordStrength && formData.password !== "" && (
                    <p
                      className={`text-sm text-right text-${passwordStrength.color}-500`}
                    >
                      {passwordStrength.name} ({passwordStrength.message})
                    </p>
                  )}
                </div>

                <div className="w-10/12 mx-auto pt-1">
                  <label htmlFor="email" className="text-base text-pri-grey">
                    Confirm Password <span className="text-red-500">*</span>
                  </label>
                  <div className=" flex justify-between items-center border-2 border-solid border-black rounded-lg p-0 overflow-hidden mb-2 focus-within:border-gold">
                    <input
                      type={showsecondpassword ? "text" : "password"}
                      name="passwordsecond"
                      className=" px-4 py-3 w-full placeholder:text-black/50 focus:outline-none"
                      value={formData.secondPassword}
                      disabled={loading}
                      onChange={ConfirmPassword}
                      placeholder="Confirm Password"
                      required
                    />
                    {showsecondpassword ? (
                      <div
                        className={eyeStyle}
                        onClick={() =>
                          setshowsecondpassword(!showsecondpassword)
                        }
                      >
                        <AiOutlineEye />
                      </div>
                    ) : (
                      <div
                        className={eyeStyle}
                        onClick={() =>
                          setshowsecondpassword(!showsecondpassword)
                        }
                      >
                        <AiOutlineEyeInvisible />
                      </div>
                    )}
                  </div>
                  {confirmPasswordErrorMessage && (
                    <p
                      className={`text-sm cursor-pointer text-left text-red-500`}
                    >
                      {confirmPasswordErrorMessage}
                    </p>
                  )}
                </div>

                <div className=" flex justify-start w-10/12 mx-auto py-2">
                  <input
                    type="checkbox"
                    id="TC"
                    disabled={loading}
                    className=" w-4 h-4 text-gray-500 rounded cursor-point"
                    name="TC"
                    value="TC"
                    required
                  />
                  <label
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    htmlFor="TC"
                  >
                    I agree with the{" "}
                    <Link onClick={() => toggleModal("terms")} to="">
                      <span className=" text-gray-700 dark:text-blue-500 hover:underline">
                        terms and conditions
                      </span>
                    </Link>
                    .
                  </label>
                </div>
                {error && (
                  <p className={errStyle + " text-base"}>{errorMessage}</p>
                )}

                {/* Sign up button */}
                <button
                  disabled={loading}
                  className={`text-white block ${
                    loading
                      ? "bg-gray-700 cursor-wait"
                      : "bg-black cursor-pointer"
                  }  rounded-lg px-6 py-3 w-10/12 mx-auto mb-6 text-center text-bold flex items-center justify-center gap-5`}
                >
                  <CgSpinner
                    size={30}
                    className={`${loading ? "block animate-spin" : "hidden"} `}
                  />{" "}
                  Sign Up
                </button>

                <div className="relative flex items-center mb-2">
                  <div className="flex-grow border-t border-gray-500"></div>
                  <span className="flex-shrink mx-4 text-gray-500 font-sans">
                    OR
                  </span>
                  <div className="flex-grow border-t border-gray-500"></div>
                </div>

                <button
                  onClick={handleSignup}
                  className={`text-white block ${
                    loading
                      ? "bg-gray-100 cursor-wait"
                      : "bg-gray-700 cursor-pointer"
                  }  border border-solid border-gray-800 rounded-lg px-6 py-3 w-10/12 mx-auto mb-4 text-center text-sm text-[#9B9B9B] flex items-center justify-center gap-5 disabled:text-black`}
                >
                  <FcGoogle size={20} className="" />
                  Continue with Google
                </button>

                <p className=" text-center text-sm text-[#9B9B9B]">
                  Already have an account?{" "}
                  <Link to={rootLinks.signin_link}>
                    <span className=" cursor-pointer text-yellow-500">
                      Log In
                    </span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal state={modalSwitch} toggleModal={toggleModal} modalId={"terms"} />
    </div>
  );
};

export default SignupPage;
