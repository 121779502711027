import React from 'react'
import { TeamCard } from './TeamCard'
import TeamSlider from './TeamSlider';
import { teamMembers } from '../../constants/details';

export const MeetTeam = () => {
    return (
        <div className=' w-full lg:w-max mx-auto text-center mt-8 mb-8'>
            <h2 className=' font-Space-Grotesk font-bold text-lg lg:text-3xl mb-8'>Meet the brains making things work</h2>
            <div className='hidden lg:flex justify-center gap-8 w-full px-8 overflow-x-auto'>
            {teamMembers.map((teamMember, slideIndex) => (
                <div key={slideIndex}>
                    <TeamCard url={teamMember.url} name={teamMember.name} role={teamMember.role} image={teamMember.image}/>
                </div>
            ))}
            </div>
            <div className='lg:hidden h-48'>
                <TeamSlider/>
            </div>
        </div>
    )
}
