import React from "react";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import imagegroup from "../../assets/imagegroup.svg";
import fileupload from "../../assets/fileupload.svg";
import { useUserContext } from "../../context/UserContext";
import { shortenString } from "../../hooks/services";

const KycFileUpload = ({ onNext }) => {
  const { setFile} = useUserContext();
  const [Imgfile, setImgFile] = useState();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [msg, setMsg] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const convertToBase64 = (image) => {
    const reader = new FileReader();

    reader.readAsDataURL(image);

    reader.onload = () => {
      const result = reader.result;
      const base64Data = result.split(",")[1];
      setFile(base64Data);
    };

    reader.onerror = (error) => {
      console.log("Error converting file to base64:", error);
    };
  };

  const checkFileType = (e, eventType) => {
    let extension;

    if (eventType === "drop") {
      extension = e.dataTransfer.files[0].name.match(/\.[0-9a-z]+$/i)[0];
    } else {
      extension = e.target.value.match(/\.[0-9a-z]+$/i)[0];
    }

    switch (extension) {
      case ".jpg":
      case ".jpeg":
      case ".png":
        eventType !== "drop"
          ? setImgFile(e.target.files[0])
          : setImgFile(e.dataTransfer.files[0]);
        setMsg("");
        break;
      default:
        setImgFile(null);
        setMsg(`${extension} format is not supported.`);
    }
  };

  const checkSize = (e, eventType) => {
    let size;
    if (eventType === "drop") {
      size = e.dataTransfer.files[0].size / 8;
    } else {
      size = e.target.files[0].size / 8;
    }

    if (size <= 2.5 * 1024 * 1024) {
      checkFileType(e, eventType);
    } else {
      setMsg("Size should be less than 50KB");
    }
  };

  const chooseFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      checkSize(e);
      checkFileType(e);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkSize(e, "drop");
      checkFileType(e, "drop");
    }
  };

  const uploadFile = () => {
    setIsButtonClicked(true);
    convertToBase64(Imgfile); // Convert the image to base64
    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setTimeout(onNext, 0);
          return 100;
        }
        return prevProgress + 10; // Increment progress
      });
    }, 500);
  };

  const formatFileSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  };

  return (
    <div className="my-5 mx-10">
      <form
        className={`border-2 border-[#CDCDCD] border-dashed pt-14 rounded-xl ${
          dragActive ? "bg-light-blue" : "bg-light-grey"
        }`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onSubmit={(e) => e.preventDefault()}
      >
       
        <div className="flex justify-center items-center">
          <img src={fileupload} alt="fileupload" />
        </div>

        <div>
          <div className="">
            <div className="flex justify-center items-center mt-3">
              <p className="">
                Drop your file here <br />
                <span className="block text-center mt-3">or</span>
              </p>
            </div>

            <div className="flex justify-center items-center mt-5">
              <label
                htmlFor="img"
                className="bg-black py-2 px-10 rounded-xl text-white text-center font-Space-Grotesk"
                onClick={() => document.getElementById("getFile").click()}
              >
                Browse
                <input
                  type="file"
                  data-max-size="1048"
                  id="getFile"
                  className="hidden"
                  onChange={chooseFile}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center py-4">
          <p className="text-[#727272] font-inter text-sm">
            Maximum File Size : 1.5mb
          </p>
        </div>
      </form>

      {Imgfile && (
        <div className="flex space-x-5 items-center mt-5">
          <FaCheckCircle
            className={`${uploadProgress >= 100 ? "flex" : "hidden"}`}
            color="#30DB98"
          />
          <img src={imagegroup} alt="uploadimage" />
          <p className="p-0 m-0 text-gray-400 text-xs font-inter ">
            {shortenString(Imgfile.name, 10)}
            <span className="block text-xs p-0" style={{ marginTop: "-5px" }}>
              {formatFileSize(Imgfile.size)}
            </span>
          </p>

          <div className="w-3/5">
            <progress
              style={{ width: "100%" }}
              className="[&::-webkit-progress-bar]:rounded-lg [&::-webkit-progress-value]:rounded-lg [&::-webkit-progress-bar]:w-full [&::-webkit-progress-value]:w-full [&::-webkit-progress-bar]:h-1/2 [&::-webkit-progress-value]:h-1/2 [&::-webkit-progress-bar]:bg-slate-300 [&::-webkit-progress-value]:bg-bright-green [&::-moz-progress-bar]:bg-bright-green"
              value={uploadProgress}
              max="100"
            ></progress>
          </div>

          <div>
            {!isButtonClicked && (
              <button
                className="bg-black lg:px-3 lg:py-3 p-2 text-white font-Space-Grotesk rounded-lg text-sm lg:text-base"
                onClick={uploadFile}
              >
                Upload
              </button>
            )}
          </div>
        </div>
      )}
      {msg && <p className="text-red-500">{msg}</p>}
    </div>
  );
};

export default KycFileUpload;
