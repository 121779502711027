import React from "react";

const FeaturesCard = (props) => {
  const { color, image, title, description } = props;
   
  let colours
  // eslint-disable-next-line
  colours = "bg-cream bg-light-purple bg-light-blue bg-light-brown" /* Very IMPORTANT !!! */
 
  return (
    <div className={`bg-${color} text-black px-10 pt-20 w-full h-full `}>
      <p className="text-center text-2xl font-semibold mb-4 font-Space-Grotesk">
        {title}
      </p>
      <p className=" text-xl text-center font-inter">{description}</p>
      <div className="flex justify-center py-6">
        <button
          className="mx-auto border-solid border-2 border-black rounded-md hover:bg-black hover:text-white py-2 px-7"
          type="button"
        >
          Learn more
        </button>
      </div>

      <div className="flex items-end h-80">
        {" "}
        {/* Set the desired fixed height */}
        <img src={image} className="w-full h-full " alt={title} />
      </div>
    </div>
  );
};

export default FeaturesCard;
