import React from 'react';
import CryptoOverview from '../components/crypto/CryptoOverview';

const CryptoTradePage = () => {
  return (
    <div className=''>
      <CryptoOverview/>
    </div>
  );
};

export default CryptoTradePage;
