import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { headerActiveLink, linkStyle } from "../../constants/styles";
import { rootLinks } from "../../constants/links";
import LogoBtn from "../buttons/LogoBtn";
import { useUserContext } from "../../context/UserContext";
import useLogOut from "../../hooks/useLogOut";
import image1 from "../../assets/globe.svg";
import image_placeholder from '../../assets/profile_image.png';


const Navbar = () => {
  const { user } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);

  const logOut = useLogOut();

  return (
    <div className="">
      <img
        className="hidden lg:block absolute top-0 right-0 w-2/5 h-3/6"
        src={image1}
        alt="globe"
      />
      <div className="relative z-20">
        <nav className="lg:bg-transparent bg-light-cream  px-6 py-2 lg:pt-7 lg:pb-2 font-Space-Grotesk lg:px-12">
          <div className="mx-auto">
            <div className="flex justify-between lg:block">
              <div className="flex justify-between items-center">
                <LogoBtn />

                <div className="hidden sm:flex items-center">
                  <nav className=" hidden lg:flex items-center font-Space-Grotesk font-semibold justify-evenly space-x-10">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? linkStyle + headerActiveLink : linkStyle
                      }
                      to={rootLinks.crypto}
                    >
                      <p className="">CryptoCurrency</p>
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? linkStyle + headerActiveLink : linkStyle
                      }
                      to={rootLinks.giftcards}
                    >
                      <p className="">Giftcards</p>
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? linkStyle + headerActiveLink : linkStyle
                      }
                      to={rootLinks.rates}
                    >
                      <p className="">Rates</p>
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? linkStyle + headerActiveLink : linkStyle
                      }
                      to={rootLinks.about}
                    >
                      <p>About Us</p>
                    </NavLink>
                  </nav>
                </div>
                {user ? (
                  <div className="hidden lg:flex items-center space-x-4 font-Space-Grotesk">
                    <Link
                      to={rootLinks.dashboard}
                      className=" flex items-center gap-2 justify-center"
                    >
                      <div className=" w-12 rounded-full overflow-hidden">
                        <img
                          src={user?.profilePicture_url || image_placeholder}
                          alt="portrait in black"
                        />
                      </div>
                      <p>{user?.fullName}</p>
                    </Link>
                    <div onClick={logOut}>
                      <button
                        className=" border-solid border border-black rounded-md hover:bg-black hover:text-white py-2 px-7"
                        type="button"
                      >
                        Log Out
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="hidden lg:flex space-x-4 font-Space-Grotesk">
                    <Link to={rootLinks.signin_link}>
                      <button
                        className=" border-solid border-2 border-black rounded-md hover:bg-black hover:text-white py-2 px-7"
                        type="button"
                      >
                        Sign In
                      </button>
                    </Link>

                    <Link to={rootLinks.signup_link}>
                      <button
                        className="  rounded-md bg-black hover:bg-white hover:text-black text-white py-2 border border-black px-8"
                        type="button"
                      >
                        Create Account
                      </button>
                    </Link>
                  </div>
                )}
              </div>
              <div className="-mr-2 flex items-center lg:hidden">
                <p
                  className=" cursor-pointer hover:bg-yellow-700 hover:text-white w-max transition-all ease-in-out px-2 py-1 text-xl font-bold lg:hidden block rounded-md"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? <>&#9932;</> : <>&#9778;</>}
                </p>
              </div>
            </div>
          </div>
          {isOpen && (
            <div
              onClick={() => setIsOpen(false)}
              className="lg:hidden py-8 ease-in-out h-full transition transform delay-300"
              id="mobile-menu"
            >
              <div className="px-2 pt-2 pb-3 space-y-1 font-semibold ">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? linkStyle + headerActiveLink : linkStyle
                  }
                  to={rootLinks.crypto}
                >
                  <p className="py-4">CryptoCurrency</p>
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? linkStyle + headerActiveLink : linkStyle
                  }
                  to={rootLinks.giftcards}
                >
                  <p className="py-4">Giftcards</p>
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? linkStyle + headerActiveLink : linkStyle
                  }
                  to={rootLinks.rates}
                >
                  <p className="py-4">Rates</p>
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? linkStyle + headerActiveLink : linkStyle
                  }
                  to="/about"
                >
                  <p className="py-4">About Us</p>
                </NavLink>
              </div>
              {user ? (
                <div className="flex items-center space-x-4 font-Space-Grotesk">
                  <Link
                    to={rootLinks.dashboard}
                    className=" flex items-center gap-2 justify-center"
                  >
                    <div className=" w-12 rounded-full overflow-hidden">
                      <img
                        src={user?.profilePicture_url || image_placeholder}
                        alt="portrait in black"
                      />
                    </div>
                    <p>{user?.fullName}</p>
                  </Link>
                  <div onClick={logOut}>
                    <button
                      className=" border-solid border border-black rounded-md hover:bg-black hover:text-white py-2 px-7"
                      type="button"
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex space-x-4 font-Space-Grotesk">
                  <Link to={rootLinks.signin_link}>
                    <button
                      className=" border-solid border border-black rounded-md hover:bg-black hover:text-white py-2 px-7"
                      type="button"
                    >
                      Sign In
                    </button>
                  </Link>

                  <Link to={rootLinks.signup_link}>
                    <button
                      className="  rounded-md bg-black hover:bg-white hover:text-black text-white py-2 border border-black px-8"
                      type="button"
                    >
                      Create Account
                    </button>
                  </Link>
                </div>
              )}
            </div>
          )}
        </nav>

       
      </div>
    </div>
  );
};
export default Navbar;
