import React from "react";
import { useUserContext } from "../context/UserContext";
import profile_image_placeholder from "../assets/profile_image.png";
import { BalanceDropDown } from "./BalanceDropDown";

const MobileHeader = () => {
  const { user } = useUserContext();
  return (
    <div className="flex justify-between py-8">
      <div className=" flex space-x-4 justify-between items-center">
        <div className=" flex space-x-4 items-center">
          <div className=" w-10">
            <img
              src={
                user?.profilePicture_url
                  ? user?.profilePicture_url
                  : profile_image_placeholder
              }
              alt="profile"
            />
          </div>
          <div className="font-Space-Grotesk">
            <p className=" text-gray-600 font-inter text-xs md:text-base">
              <span className="text-xs font-bold">Hello,</span>{" "}<br/>
              <span className=" text-yellow-500 font-semibold">
                {user?.fullName}
              </span>
            </p>
          </div>
        </div>
      </div>
      <BalanceDropDown textSize={'text-lg'}/>
    </div>
  );
};

export default MobileHeader;
