import React from 'react';
import { privacyPolicyUrl, termNConditionsUrl } from '../../constants/details';

export default function Modal({ state, toggleModal, modalId }) {
  const styles = {
    overlay: {
      position: 'fixed',
      top: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      width: '100vw',
      height: '100vh',
    },
  }

  const terms = {
    heading: 'Terms of Service',
    body: privacyPolicyUrl
  };
  const privacy = {
    heading: 'Privacy Policies',
    body: termNConditionsUrl
  }

  let modalContent;
  if (modalId === 'terms') {
    modalContent = terms;
  } else if (modalId === 'privacy') {
    modalContent = privacy;
  }

  return (
    <>
      <div style={{ ...styles.overlay, display: state.showModal === modalId ? 'block' : 'none' }}>
          <div className=' bg-white w-2/3 h-full m-auto'>
            <div className=' flex justify-between items-center px-8'>
              <h2 className=' font-Space-Grotesk text-2xl font-extrabold h-max'>{modalContent.heading}</h2>
              <button className='text-4xl h-max' onClick={toggleModal}>&times;</button>
            </div>
            <div className=' h-full w-full'>
              <iframe src={modalContent.body} width={'100%'} height={'80%'} />
            </div>
          </div>
      </div>
    </>
  )
}