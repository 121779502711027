import axios from "axios";
import React, { useState, useEffect } from "react";
import { GET_WALLET_BY_ID } from "../constants/links";
import { useUserContext } from "../context/UserContext";
import { useAuthContext } from "../context/AuthContext";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export const BalanceDropDown = ({textSize}) => {
  const { selectedWallet } = useUserContext();
  const { token } = useAuthContext();
  const [wallet_balance, setWallet_balance] = useState(selectedWallet?.balance ?? 0);
  const hidden_balance = "******";
  const [show, setShow] = useState(false);

  BalanceDropDown.defaultProps = {
    textSize: "text-3xl", // default text size
  };

  useEffect(() => {
    if (selectedWallet?._id) {
      axios
        .get(GET_WALLET_BY_ID + selectedWallet._id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token?.token,
          },
        })
        .then((res) => {
          if (res.data?.data?.balance !== undefined) {
            setWallet_balance(res.data.data.balance);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [selectedWallet?._id]); // Dependency array to run useEffect when selectedWallet._id changes

  const toggleVisibility = () => {
    setShow(prevShow => !prevShow); // Toggle show state using function form of setState
  };

  return (
    <div className="flex items-center">
      <p className={`lg:font-Montserrat font-Space-Grotesk font-bold lg:font-semibold ${textSize} md:text-2xl lg:text-md`}>
        <span className="text-gold ">
          {selectedWallet?.currency?.symbol}
          {show ? hidden_balance : wallet_balance.toLocaleString("en-US", { currency: "NGN" })}
        </span>{" "}
      </p>
      <div className="p-3 cursor-pointer" onClick={toggleVisibility}>
        {show ? (
          <AiOutlineEyeInvisible />
        ) : (
          <AiOutlineEye />
        )}
      </div>
    </div>
  );
};
