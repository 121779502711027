import axios from "axios";
import {
  CHANGE_WALLET_PIN,
  CHECK_BANKNUMBER,
  CHECK_WALLET_PIN,
  CREDIT_WALLET,
  DELETE_USER_PICTURE,
  GENERATE_PAYMENT_LINK,
  GET_GIFTCARD,
  GET_SINGLE_GIFTCARD_RATES,
  GET_SUPPORTED_BANKS,
  GET_USER_TRANSACTIONS,
  GET_USER_WALLETS,
  PAY_TO_BANK,
  REQUEST_OTP,
  RESET_WALLET_PIN,
  SET_2FACTOR,
  UPDATE_USER,
  WALLET_TRANFER,
  BASE_URL,
  GET_BROADCAST,
} from "../constants/links";

export const config = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const updateUser = async (id, data, token) => {
  try {
    const res = await axios.put(`${UPDATE_USER}${id}`, data, config(token));
    return { data: res, error: undefined };
  } catch (error) {
    return { data: undefined, error: error };
  }
};
export const changeWalletPin = async (token, data) => {
  return await axios.put(CHANGE_WALLET_PIN, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const requestOtp = async (email, phoneNumber, token) =>
  await axios.post(REQUEST_OTP, { email, phoneNumber }, config(token));
export const set2FA = async (data, token) =>
  await axios.post(SET_2FACTOR, data, config(token));

export const getPaymentUrl = async (data, token) => {
  return await axios.post(GENERATE_PAYMENT_LINK, data, config(token));
};

export const getWallets = async (userId, token) =>
  await axios.get(`${GET_USER_WALLETS}${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getFaqs = async () => await axios.get(`${BASE_URL}/faq/questions`);
export const askFaq = async (data) => await axios.post(`${BASE_URL}/faq/ask-question`, data);

export const getTransactions = async (userId, token) =>
  await axios.get(GET_USER_TRANSACTIONS + userId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getOneTransaction = async (id, token) => await axios.get(`${BASE_URL}/transaction/transaction-number/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const getSupportedGiftcards = async () => await axios.get(GET_GIFTCARD);
export const getSingleGiftcardRates = async (giftcardId) =>
  await axios.get(GET_SINGLE_GIFTCARD_RATES + giftcardId);

export const deletePicture = async (id, token) => {
  try {
    const res = await axios.delete(`${DELETE_USER_PICTURE}${id}`, {
      ...config(token),
    });
    return { data: res, error: undefined };
  } catch (error) {
    return { data: undefined, error: error };
  }
};

export const resetWalletPin = async (token, data) => {
  return await axios.post(RESET_WALLET_PIN, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const checkWalletPin = async (token, data) => {
  return await axios.post(CHECK_WALLET_PIN, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const sendToBank = async (token, data) => {
  return await axios.post(PAY_TO_BANK, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const creditWalet = async (token, data) => {
  return await axios.post(CREDIT_WALLET, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const walletTransfer = async (token, data) => {
  return await axios.post(WALLET_TRANFER, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export function getMonthString(date) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthString = monthNames[date];

  return monthString;
}

export const getBanks = async (token) =>
  await axios.get(GET_SUPPORTED_BANKS, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const checkBankDetails = async (token, accountNumber, bankCode) =>
  await axios.post(
    CHECK_BANKNUMBER,
    {
      bank_code: bankCode,
      account_number: accountNumber,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  export const getBroadcast = async (token) =>
    await axios.get(GET_BROADCAST, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });



export function shortenString(str, length) {
  if (str.length <= length) {
    return str;
  } else {
    return str.slice(0, length) + "...";
  }
}

