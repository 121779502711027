import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import { useUserContext } from "../context/UserContext";
import { getTransactions } from "../hooks/services";
import { useAuthContext } from "../context/AuthContext";

const BarCharts = () => {
  const { user } = useUserContext();
  const { token } = useAuthContext();
  const [transactions, setTransactions] = useState([]);
  // const [years, setYears] = useState([]);
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (user && token) {
      getTransactions(user._id, token.token)
        .then((res) => {
          const transactionsData = res.data.data.all;
          // setYears([
          //   ...new Set(
          //     transactionsData.map((t) => new Date(t.createdAt).getFullYear())
          //   ),
          // ]);
          processTransactions(transactionsData);
        })
        .catch((error) => {
          console.error("Failed to fetch transactions:", error);
        });
    }
  }, [user, token]);

  const processTransactions = (transactionsData) => {
    const months = [
      { month: "Jan", credit: 0, debit: 0 },
      { month: "Feb", credit: 0, debit: 0 },
      { month: "Mar", credit: 0, debit: 0 },
      { month: "Apr", credit: 0, debit: 0 },
      { month: "May", credit: 0, debit: 0 },
      { month: "Jun", credit: 0, debit: 0 },
      { month: "Jul", credit: 0, debit: 0 },
      { month: "Aug", credit: 0, debit: 0 },
      { month: "Sep", credit: 0, debit: 0 },
      { month: "Oct", credit: 0, debit: 0 },
      { month: "Nov", credit: 0, debit: 0 },
      { month: "Dec", credit: 0, debit: 0 },
    ];

    transactionsData.forEach((transaction) => {
      const monthIndex = new Date(transaction.createdAt).getMonth();
      if (transaction.transaction_direction === "credit") {
        months[monthIndex].credit += transaction.amount;
      } else if (transaction.transaction_direction === "debit") {
        months[monthIndex].debit += transaction.amount;
      }
    });

    setTransactions(months);
  };

  // const filterByYear = (year) => {
  //   console.log("filtering by year:", year);
  //   setSelectedYear(year);
  //   getTransactions(user._id, token.token)
  //     .then((res) => {
  //       const filteredTransactions = res.data.data.all.filter(
  //         (t) => new Date(t.createdAt).getFullYear() === year
  //       );
  //       processTransactions(filteredTransactions);
  //     })
  //     .catch((error) => console.error("Failed to filter transactions:", error));
  // };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="rounded-xl bg-[#F1E8D3]"
          style={{ padding: "6px", border: "1px solid #ccc" }}
        >
          {payload.map((entry, index) => (
            <p key={index} className="text-[#B98100] text-sm">{`$${entry.value}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="w-full ">
      <h1 className="font-bold text-xl capitalize font-Space-Grotesk">My Statistics</h1>
      <div className="overflow-x-auto w-full py-2 pr-24 flex justify-end">
        {/* <div>
          {years.map((year, index) => (
            <button
              key={index}
              style={
                year === selectedYear ? { color: "black" } : { color: "grey" }
              }
              className="my-2 p-1 text-xs hover:bg-gray-200 border border-black border-solid font-bold rounded-sm mr-2"
              onClick={() => filterByYear(year)}
            >
              {year}
            </button>
          ))}
        </div> */}
        <label className="text-sm font-inter font-semibold" htmlFor='years'>Sort by:</label>
        <select className="text-sm text-yellow-500 font-semibold" id="years">
          <option value="yearly">Yearly</option>
          <option value="monthly">Monthly</option>
          <option value="weekly">Weekly</option>
        </select>
      </div>
      <BarChart
        width={800}
        height={300}
        data={transactions}
        margin={{ top: 10, right: 0, left: 0, bottom: 5 }}
      >
        <XAxis
          dataKey="month"
          axisLine={false}
          tickLine={false}
          tickMargin={10} // Adjust this value for space between labels and bars
          padding={{ left: 20, right: 20 }} // Adjust these values for padding
          style={{
            fontSize: "10px",
            fontFamily: "Inter",
            fontWeight: "600",
            color: "#3F3F3F",
          }}
        />
        <YAxis
          width={80}
          axisLine={false}
          tickLine={false}
          style={{
            fontSize: "10px",
            fontFamily: "Inter",
            fontWeight: "600",
            color: "#9C9898",
          }}
        />
        <Tooltip cursor={false} content={<CustomTooltip />} />
        {/* <Legend
          align="left"
          verticalAlign="top"
          height="54"
          iconType="circle"
          wrapperStyle={{ top: -15 }}
        /> */}
        <Bar
          dataKey="credit"
          fill="#B98100"
          radius={[6, 6, 6, 6]}
          barSize={18}
        />
      </BarChart>
    </div>
  );
};

export default BarCharts;
