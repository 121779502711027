import React from 'react';
import bitcoin_grey from '../../assets/bitcoin_grey.svg';

export const CultureCard = ({title, description}) => {
    return (
        <div className=' p-4 w-full lg:w-72 my-4 border border-black hover:border flex gap-4 lg:gap-0 justify-center lg:justify-start items-center lg:items-start lg:flex lg:flex-col hover:border-pri-pink-light rounded-lg transition-all ease-in'>
            <div className=' w-32 lg:max-w-lg'>
                <img src={bitcoin_grey} className='' alt='₿'/>
            </div>
            <div>
                <h4 className=' text-sm lg:text-base font-bold text-slate-200 my-2'>{title}</h4>
                <p className=' text-2xs lg:text-sm text-slate-200'>{description}</p>
            </div>
            
        </div>
    )
}
