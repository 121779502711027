import React, { useState, useEffect, useRef } from "react";
import {
  AiOutlineSend,
  AiOutlinePaperClip,
  AiOutlineClose,
} from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegSquarePlus } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProfileBalance } from "./ProfileBalance";
import { ref, push, onValue } from "firebase/database";
import { firebaseDb } from "../constants/firebase";
import spinner from "../assets/Spinner.gif";
import { CardTypes, roles } from "../constants/details";
import { config } from "../hooks/services";
import { useLocation ,useParams } from "react-router-dom";
import { Chatbubble } from "./Chatbubble";
import axios from "axios";
import "react-image-crop/dist/ReactCrop.css";
import {
  GET_TRANSACTION_MESSAGES,
  // GET_USER_MESSAGES,
  SEND_MESSAGE,
  UPLOAD_IMAGE,
} from "../constants/links";
import { QuoteMapBanner } from "./QuoteMapBanner";
import { useUserContext } from "../context/UserContext";
import { useAuthContext } from "../context/AuthContext";

export const MessagesPage = () => {
  const messageContainer = useRef();
  const location = useLocation();
  const newTrade = location.state;
  const { user } = useUserContext();
  const { token } = useAuthContext();
  const selectedGiftcard = newTrade ? newTrade.giftcard : {};
  let order = newTrade ? newTrade.order : [];
  const currencyType = JSON.parse(localStorage.getItem("currencyType")) || {
    name: "NG Naira",
    symbol: "₦",
    code: "NGN",
  };
  const [message, setMessage] = useState("");
  const [chat] = useState({});
  const [Messages, setMessages] = useState([]);
  const configObj = config(token?.token);
  const isInitialMount = useRef(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const [activeImage, setActiveImage] = useState();

  const { chat_id } = useParams();


  const addimages = (newFiles) => {
    const fileReaders = [];
    const imageUrls = [];
    const MAX_LENGTH = 3;
    const currentTotal = imagePreviewUrl.length + newFiles.length;

    if (currentTotal > MAX_LENGTH) {
      toast.error(`Cannot upload files more than ${MAX_LENGTH}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      return;
    }

    newFiles.forEach((file) => {
      let reader = new FileReader();

      reader.onloadend = () => {
        fileReaders.splice(fileReaders.indexOf(reader), 1);
        imageUrls.push(reader.result);

        if (fileReaders.length === 0) {
          // All files are read
          setImagePreviewUrl((prevUrls) => [...prevUrls, ...imageUrls]);
          setImageFile((prevFiles) => [...prevFiles, ...newFiles]); // Moved outside the loop
        }

        if (!activeImage && imageUrls.length > 0) {
          setActiveImage(imageUrls[0]);
        }
      };

      fileReaders.push(reader);
      reader.readAsDataURL(file);
    });
};


  const handleImageChange = (e) => {
    e.preventDefault();

    if (!e.target.files || e.target.files.length === 0) {
      console.log("here");
      // No files selected, exit the function
      return;
    }

    const files = Array.from(e.target.files); // Convert FileList to Array
    addimages(files);

    e.target.value = "";
  };

  const removeImage = (index) => {
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
    const newImagePreviews = imagePreviewUrl.filter((_, i) => i !== index);

    setSelectedFiles(newSelectedFiles);
    setImagePreviewUrl(newImagePreviews);

    // Update the active image if the removed one was active
    if (imagePreviewUrl[index] === activeImage) {
      setActiveImage(newImagePreviews[0] || null);
    }
  };

  const handleThumbnailClick = (imageUrl) => {
    setActiveImage(imageUrl);
  };

 
  // useEffect(() => {
  //   if (!user?._id || !configObj) return;

  //   const fetchChat = async () => {
  //     try {
  //       const res = await axios.get(
  //         GET_TRANSACTION_MESSAGES + transactionNumber,
  //         configObj
  //       );
  //       const resChat = res.data.data;
  //       setChat(() => resChat);

  //       // if (resChat.length > 0) {
  //       //   const messagesRes = await axios.get(
  //       //     GET_USER_MESSAGES + resChat[0]._id,
  //       //     configObj
  //       //   );
  //       //   setMessages(() => messagesRes.data.data);
  //       //   console.log('running')
  //       // }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchChat();
  // }, [user?._id, configObj, chat]);

  useEffect(() => {
    if (!user?._id || !chat_id || !configObj) return;
    let messageRef = ref(firebaseDb, "chats/" + user?._id + "/messages");
    onValue(messageRef, () => {
      axios
        .get(GET_TRANSACTION_MESSAGES + chat_id, configObj)
        .then((res) => {
          const resMessages = res.data.data;
          setMessages(() => resMessages);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, [chat, user, message, configObj]);

  useEffect(() => {
    if (isInitialMount.current && Messages.length > 0) {
      isInitialMount.current = false;
      // Ensures scroll happens after the messages are rendered
      setTimeout(() => {
        messageContainer.current.scrollTop =
          messageContainer.current.scrollHeight;
      }, 100); // You may adjust this timeout as needed
    }
  }, [Messages]);

  const sendMessage = async (e) => {
    e.preventDefault();

    if (message === "") {
      toast.error("message cannot be empty", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      return;
    }

    let attachmentsUrl = [];

    if (imageFile.length > 0) {
      for (let file of imageFile) {
        console.log("Image files to upload:", imageFile);

        const formData = new FormData();
        formData.append("image", file);
        console.log(formData)
        configObj.headers["Content-Type"] = "multipart/form-data";

        try {
          const response = await axios.post(UPLOAD_IMAGE, formData, configObj);
          const attachment = {
            file_name: file.name,
            file_type: file.type,
            url: response.data.data.secure_url
        };
 
        attachmentsUrl.push(attachment);
          
        } catch (error) {
          console.log(error);
          return; // Exit if there's an error in uploading
        }
      }
      setImagePreviewUrl([]); // Reset image preview URLs if needed
    }
    
    // Now, prepare the message data
    const sendData = {
      client: user._id,
      sender: user._id,
      chat_id: chat_id,
      message: message,
      recipient: roles.admin,
      attachments: attachmentsUrl  // Add attachment if it exists
    };

    configObj.headers["Content-Type"] = "application/json";
    try {
      console.log(sendData)
      await axios.post(SEND_MESSAGE, sendData, configObj);
      setMessage("");
      push(ref(firebaseDb, "chats/" + user._id + "/messages"), sendData);
      setImageFile([])
    } catch (error) {
      console.log(error);
    }
  };

  let sum = 0;
  if (order !== null) {
    order.map((value) => (sum += +value.amount));
  } else {
    order = [];
  }

  const handleClosePreview = () => {
    setImagePreviewUrl([]);
    setActiveImage(null);
  };

  return (
    <div className=" flex flex-col justify-between lg:space-y-0 space-y-2 h-full items-end p-4">
      <ProfileBalance />
      <div className=" lg:flex lg:items-end justify-center w-full h-5/6 lg:space-x-8 mb-4 ">
        <div className="relative flex flex-col justify-end items-center h-full lg:w-3/5 w-full border  border-black rounded-lg">
          <div
            className={` w-full h-full py-8 bg-message-bg bg-contain overflow-y-auto`}
            ref={messageContainer}
          >
            <div className="">
              {Messages.length > 0 ? (
                Messages.map((value, index) => (
                  <Chatbubble
                    name={value.recipient.fullName}
                    timestamp={value.timestamp}
                    sender={value.sender._id === user._id}
                    message={value.message}
                    attachments={value.attachments}
                    key={index}
                  />
                ))
              ) : (
                <p className=" text-center font-inter italic">A Cambio Rep will be with you shortly</p>
              )}
            </div>

            {imagePreviewUrl.length > 0 && (
              <div className=" flex flex-col bg-grey  h-full w-full shadow-md absolute rounded-md overflow-hidden overflow-x-auto bottom-0 left-0 z-10">
                <div className=" absolute top-0 right-0 m-2 z-10">
                  <button
                    onClick={handleClosePreview}
                    className="text-white rounded-full p-2"
                  >
                    <AiOutlineClose size={25} />
                  </button>
                </div>
                <div className="h-3/5 md:h-2/4 lg:h-[60%] m-5 relative flex justify-center items-center">
                  <div className="flex justify-center items-center w-full h-full">
                    {activeImage && (
                      <img
                        src={activeImage}
                        alt="Preview"
                        className="max-w-full max-h-full object-contain"
                      />
                      //   {/* <ReactCrop
                      //     crop={crop}
                      //     onImageLoaded={onImageLoaded}
                      //     onComplete={onCropComplete}
                      //     onChange={onCropChange}
                      //   /> */}
                    )}
                  </div>
                </div>

                <hr></hr>

                <div className="flex-grow flex items-center">
                  {/* <div className="">  */}
                  <div className="flex flex-row gap-2 my-7 md:my-3 lg:mx-3 lg:my-3 mx-auto">
                    <div className="w-20 h-24 md:w-24 md:h-28 lg:w-14 lg:h-14 relative">
                      <label
                        htmlFor="attachment1"
                        className="absolute bg-grey border-solid border-white border-4 lg:border-none opacity-60 w-20 h-24 md:w-24 md:h-28 lg:w-14 lg:h-14 p-1 flex justify-center items-center cursor-pointer"
                      >
                        <FaRegSquarePlus color="white" size={60} />
                      </label>
                    </div>
                    <input
                      className={` hidden`}
                      type="file"
                      id="attachment1"
                      name="card_pic"
                      multiple
                      onChange={handleImageChange}
                    />
                    {imagePreviewUrl.map((url, index) => (
                      <div
                         key={index}
                        className={` w-20 h-24 md:w-24 md:h-28 lg:w-14 lg:h-14 bg-white flex justify-center items-center rounded-lg shadow ${
                          url === activeImage ? "border-4 border-blue-500" : ""
                        }`}
                        onClick={() => handleThumbnailClick(url)}
                      >
                        <img
                          src={url}
                          alt={`Preview ${index}`}
                          className="object-contain w-full h-full"
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            removeImage(index);
                          }}
                          className={`${
                            url === activeImage
                              ? "absolute bg-grey opacity-60 w-20 h-24 md:w-24 md:h-28 lg:w-14 lg:h-14 p-1 flex justify-center items-center"
                              : "hidden"
                          } `}
                        >
                          <RiDeleteBin6Line size={40} color="white" />
                        </button>
                      </div>
                    ))}
                  </div>
                  {/* </div> */}
                </div>

                <div className="mt-auto">
                  <div className="flex items-center h-1/12 w-full bg-pri-pink px-4 py-2 space-x-2">
                    <input
                      className=" w-5/6 focus:outline-none border border-black p-2 bg-transparent mx-auto placeholder:text-black"
                      type="text"
                      name="message"
                      value={message}
                      placeholder="Type a message"
                      onChange={(e) => setMessage(e.currentTarget.value)}
                    />
                    <div
                      className=" cursor-pointer hover:bg-gray-900 text-white border-2 border-black  bg-black rounded-lg px-2 py-1"
                      onClick={sendMessage}
                    >
                      <AiOutlineSend size={25} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className=" rounded-lg flex items-center h-1/12 w-full bg-pri-pink px-2 py-2 space-x-2">
            <div className=" cursor-pointer px-2 py-1">
              <label htmlFor="attachment">
                <AiOutlinePaperClip size={25} className="" />
              </label>
              <input
                className={` hidden`}
                type="file"
                id="attachment"
                name="card_pic"
                multiple
                onChange={handleImageChange}
              />
            </div>
            <input
              className=" w-5/6 focus:outline-none border border-black rounded-lg p-2 bg-transparent placeholder:text-black mx-auto "
              type="text"
              name="message"
              value={message}
              placeholder="Type a message"
              onChange={(e) => setMessage(e.currentTarget.value)}
            />
            <div
              className=" cursor-pointer hover:bg-gray-900 text-white border-2 border-black  bg-black rounded-lg px-2 py-1"
              onClick={sendMessage}
            >
              <AiOutlineSend size={25} />
            </div>
            <ToastContainer />
          </div>
        </div>
        <div className=" lg:flex flex-col lg:w-60 w-full lg:space-y-4">
          <div className=" border-2 border-gray-500 rounded-md p-4 mt-6">
            <p className=" font-Space-Grotesk">Order Summary</p>
            <div className=" flex justify-center">
              <img
                src={
                  selectedGiftcard !== null
                    ? selectedGiftcard.cardImage
                    : spinner
                }
                alt={selectedGiftcard !== null ? selectedGiftcard.cardName : ""}
              />
            </div>
            <div className=" text-center">
              <p className=" text-sm">
                <span>{currencyType.code}</span>
              </p>
              {order.length > 0 ? (
                order.map((value, index) => (
                  <div
                    className=" flex justify-center space-x-2 items-center mx-auto p-2 bg-gray-200 w-1/2 "
                    key={index}
                  >
                    <p>
                      <span>{currencyType.symbol}</span>
                      {value.amount}
                    </p>
                    {value && value.card_type === CardTypes.physical ? (
                      <div className=" w-10">
                        <img
                          className="w-full"
                          src={value.card_item}
                          alt={value.card_item}
                        />
                      </div>
                    ) : (
                      <span>{value.card_item.substring(0, 3)}...</span>
                    )}
                  </div>
                ))
              ) : (
                <div>
                  <p>
                    <span>{currencyType.code}</span> 0
                  </p>
                </div>
              )}
              <hr className=" bg-gray-400  mb" />
              {/* <p className=" text-sm">
                <span>{currencyType.symbol}</span>
                <span>{sum}</span> x <span>{selectedGiftcard.rate}</span> = ₦
                {sum * selectedGiftcard.rate}{" "}
              </p> */}
            </div>
          </div>
          <QuoteMapBanner />
        </div>
      </div>
    </div>
  );
};
