import React from "react";
import { Link } from "react-router-dom";

const DashboardActionBtn = (props) => {
  return (
    <div className="flex flex-col items-center lg:block md:block">
      <div className="flex justify-center mt-3 w-full rounded-lg border border-pri-pink text-black py-5 items-center transition-all hover:bg-black hover:text-white">
        <Link to={props.url} className="flex items-center">
          <p className="md:w-4/5 text-sm font-bold font-inter lg:block md:block hidden">
            {props.name}
          </p>
          <div className="flex items-center justify-center w-7 lg:mx-2">
            <img
              src={props.icon}
              alt={props.name}
              className="h-7 object-contain"
            />
          </div>
        </Link>
        </div>
        <p className="text-xss font-bold font-inter mt-2 w-full md:w-1/5 text-center lg:hidden md:hidden block">
          {props.name}
        </p>
      
    </div>
  );
};

export default DashboardActionBtn;
