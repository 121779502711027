import React from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useUserContext } from "../../context/UserContext";

const KycHeader = () => {
  const { kyc, showKyc, setShowKyc } = useUserContext();

  const onToggleKyc = () => {
    setShowKyc(!showKyc);
  }

  return (
    <div
      className={`${
        kyc ? "hidden" : "flex"
      }  bg-light-green rounded-3xl p-2 mb-4 ml-4 items-center justify-evenly`}
    >
      <p className="font-inter ">
        KYC verification needed. Please complete your KYC.
      </p>
      <button onClick={onToggleKyc} className="bg-dark-green rounded-full text-white px-5 py-3">
        Complete KYC
      </button>
      <IoIosCloseCircleOutline
        color="#426E6E"
        size={30}
      />
    </div>
  );
};

export default KycHeader;
