import React from 'react'
import { ProfileBalance } from './ProfileBalance'
import { TransactionTable } from './table/TransactionTable'

export const TransactionsPage = () => {
    return (
        <div className=' p-8'>
            <div className=' flex justify-between'>
                <h2 className=' font-Space-Grotesk font-bold'>Transactions</h2>
                <ProfileBalance/>
            </div>
            <div>
                <TransactionTable/>
            </div>
        </div>
    )
}
