import React from 'react'
import BlackBtn from '../buttons/BlackBtn'

export const AboutHeroBanner = () => {
    return (
        <div className=' bg-about-hero-bg bg-center bg-no-repeat h-screen mx-auto p-12 lg:my-8 rounded-lg shadow-lg shadow-slate-900'>

            <h2 className=' mx-auto lg:text-4xl font-Space-Grotesk my-8 font-bold text-center '>We are changing the whole game.</h2>
            <div className=' flex flex-col lg:flex-row justify-center items-center lg:gap-4'>
                <div className='  mb-2'>
                    <BlackBtn url='/dashboard' text='Trade With Us'/>
                </div>
                <div className=''>
                    <BlackBtn url='/rates' text='Check Rates'/>
                </div>

            </div>
        </div>
    )
}
