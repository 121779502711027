import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { MdOutlineGppGood } from 'react-icons/md'
import { Link } from 'react-router-dom'

export const Success = ({message}) => {
    return (
        <div className=' font-bold text-lg text-green-600 p-16'>
          <div className=' border border-yellow-300 py-8'>
            <p>Successful</p>
            <MdOutlineGppGood size={50} className=' mx-auto'/>
          </div>
          <p>{message}</p>
          <Link to={-1} className=' text-yellow-500 cursor-pointer mb-2 flex gap-1 justify-center px-2 py-1 items-center'>
                <AiOutlineArrowLeft/>
                <p>Back</p>
            </Link>
        </div>
    )
}
