import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";

const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [user, updateUser] = useState();
  const [wallets, updateWallets] = useState([]);
  const [selectedWallet, updateSelectedWallet] = useState();
  const [kyc, updateKyc] = useState(false);
  const [showKyc, updateShowKyc] = useState(false);
  const [urldata, updateUrlData] = useState();
  const [dob, updateDob] = useState("");
  const [Documentid, updateDocumentId] = useState("");
  const [file, updateFile] = useState({
    selectedFile: null,
    type: "",
  });

  useEffect(() => {
    const userCookie = Cookies.get("userData");
    if (userCookie) {
      updateUser(JSON.parse(userCookie));
    }

    const walletsCookie = Cookies.get("walletsData");
    if (walletsCookie) {
      updateWallets(JSON.parse(walletsCookie));
    }

    const selectedWalletCookie = Cookies.get("selectedWalletData");
    if (selectedWalletCookie) {
      updateSelectedWallet(JSON.parse(selectedWalletCookie));
    }
    
  }, []);
  
  const setUser = (newData) => {
    if (newData) {
      const userData = JSON.stringify(newData);
      updateUser(newData);
      Cookies.set("userData", userData, { expires: 1 });
    } else {
      Cookies.remove("userData");
      updateUser(newData);
    }
  };

  const setWallets = (newData) => {
    if (newData) {
      const walletsData = JSON.stringify(newData);
      updateWallets(newData);
      setSelectedWallet(
        Array(newData).find(
          (wallet) => wallet.wallet_number === selectedWallet?.wallet_number
        )
      );
      Cookies.set("walletsData", walletsData, { expires: 1 });
    } else {
      Cookies.remove("walletsData");
      updateWallets(newData);
    }
  };

  const setSelectedWallet = (newData) => {
    if (newData) {
      const selectedWalletData = JSON.stringify(newData);
      updateSelectedWallet(newData);
      Cookies.set("selectedWalletData", selectedWalletData, { expires: 1 });
    } else {
      Cookies.remove("selectedWalletData");
      updateSelectedWallet(newData);
    }
  };

  const setKyc = (newData) => {
    updateKyc(newData);
  };

  const setShowKyc = (newData) => {
    updateShowKyc(newData);
  };

  const setUrlData = (newData) => {
    updateUrlData(newData);
  };
  const setDob = (newData) => {
    updateDob(newData);
  };
  const setDocumentId = (newData) => {
    updateDocumentId(newData);
  };

  const setFile = (newData, typeData) => {
    updateFile((prevState) => ({
      ...prevState,
      ...(newData !== undefined && { selectedFile: newData }),
      ...(typeData !== undefined && { type: typeData }),
    }));
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        wallets,
        setWallets,
        selectedWallet,
        setSelectedWallet,
        kyc,
        setKyc,
        showKyc,
        setShowKyc,
        urldata,
        setUrlData,
        file,
        setFile,
        dob,
        setDob,
        Documentid,
        setDocumentId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
export function useUserContext() {
  return useContext(UserContext);
}
