export const linkStyle = `
hover:text-yellow-600 
cursor-pointer 
border-b-4
border-transparent 
hover:border-solid 
hover:border-yellow-600 
transition-all 
ease-in-out`; 

export const headerActiveLink = " border-solid border-yellow-600";

export const eyeStyle = ' hover:bg-gray-200 p-3 cursor-pointer';

export const cardNavLinkStyle = ' w-max ease-in-out md:text-3xl text-base font-Space-Grotesk font-bold hover:text-black hover:bg-white hover:border-black border-2 border-transparent px-2 py-2 desktop:px-20 md:py-8 md:rounded-2xl rounded-lg';

export const paymentBtnStyle = 'p-2 border border-yellow-300 mx-auto cursor-pointer font-inter font-semibold hover:bg-yellow-200 w-max';