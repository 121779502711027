import React from 'react'

export const WithdrawReceipt = ({transaction, done}) => {
    const { amount, accountNumber, accountName, walletNumber, bank, date } = transaction;
    const time = new Date(date).toDateString();
    const { success, message } = done;
    return (
        <div className=' h-full lg:w-1/2 mx-auto w-full'>
            {success? <p className=' text-green-400 text-4xl text-center mx-auto drop-shadow-xl mb-4 shadow-black'>&#10003;</p>: <p className=' text-red-400 text-4xl text-center mx-auto drop-shadow-xl mb-4 shadow-black'>&#120;</p>}
            <p className=' text-center'>{message}</p>
            <div className=' flex flex-col justify-evenly p-4 h-2/3'>
                <div className=' flex justify-between'>
                    <p className=' font-bold uppercase text-sm text-slate-600'>Amount: </p>
                    <p className=' font-bold uppercase text-sm text-gray-600'>₦{(amount).toLocaleString('en-US', { currency: 'NGN' })}</p>
                </div>
                <div className=' flex justify-between'>
                    <p className=' font-bold uppercase text-sm text-slate-600'>Bank Name: </p>
                    <p className=' font-bold uppercase text-sm text-gray-600'>{bank}</p>
                </div>
                <div className=' flex justify-between'>
                    <p className=' font-bold uppercase text-sm text-slate-600'>Account Name: </p>
                    <p className=' font-bold uppercase text-sm text-gray-600'>{accountName}</p>
                </div>
                <div className=' flex justify-between'>
                    <p className=' font-bold uppercase text-sm text-slate-600'>Account Number: </p>
                    <p className=' font-bold uppercase text-sm text-gray-600'>{accountNumber}</p>
                </div>
                <div className=' flex justify-between'>
                    <p className=' font-bold uppercase text-sm text-slate-600'>Wallet Number: </p>
                    <p className=' font-bold uppercase text-sm text-gray-600'>{walletNumber}</p>
                </div>
                <div className=' flex justify-between'>
                    <p className=' font-bold uppercase text-sm text-slate-600'>Date: </p>
                    <p className=' font-bold uppercase text-sm text-gray-600'>{time}</p>
                </div>
            </div>
            
        </div>
    )
}
