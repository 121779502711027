import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";

const TransactionContext = createContext();

export function TransactionContextProvider ({ children }){
    const [giftcardTransaction, setGiftcardTransaction] = useState({});
    const [selectedGiftcard, setSelectedGiftcard] = useState({});

    // Get gift card transaction from cookies if they exist.
    useEffect(() => {
        const giftcardTransactionCookie = Cookies.get("giftcardTransaction");
        if (giftcardTransactionCookie) {
            setGiftcardTransaction(JSON.parse(giftcardTransactionCookie));
        } else {
            setGiftcardTransaction({});
        }

        const selectedGiftcardCookie = Cookies.get("selectedGiftcard");
        if (selectedGiftcardCookie) {
            setSelectedGiftcard(JSON.parse(selectedGiftcardCookie));
        } else {
            setSelectedGiftcard({});
        }
    }, []);

    const setGiftcardTransactionData = (newData) => {
        if (newData) {
            const giftcardTransactionData = JSON.stringify(newData);
            setGiftcardTransaction(newData);
            Cookies.set("giftcardTransaction", giftcardTransactionData, { expires: 1 });
        } else {
            Cookies.remove("giftcardTransaction");
            setGiftcardTransaction({});
        }
    }

    const setSelectedGiftcardData = (newData) => {
        if (newData) {
            const selectedGiftcardData = JSON.stringify(newData);
            setSelectedGiftcard(newData);
            Cookies.set("selectedGiftcard", selectedGiftcardData, { expires: 1 });
        } else {
            Cookies.remove("selectedGiftcard");
            setSelectedGiftcard({});
        }
    }

    return (
        <TransactionContext.Provider
            value={{giftcardTransaction, setGiftcardTransactionData, selectedGiftcard, setSelectedGiftcardData}}>
            {children}
        </TransactionContext.Provider>
    );
}

export function useTransactionContext() {
    return useContext(TransactionContext);
}