import React from 'react'
import { RateCalculator } from './RateCalculator'

export const RatesPage = () => {
    return (
        <div className=' p-20'>
            <section className=''><RateCalculator/></section>
        </div>
    )
}
