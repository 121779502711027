import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import { FiArrowDownCircle } from "react-icons/fi";
import Modal from "react-modal";
import { getFaqs, askFaq } from "../hooks/services";
import { useForm } from "react-hook-form";
import { Bars } from "react-loader-spinner";

export default function FAQ() {
  const location = useLocation();
  const [dropdownStates, setDropdownStates] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('')

  // eslint-disable-next-line no-unused-vars
  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const targetElement = document.getElementById(hash.slice(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    setIsLoading(true);
    getFaqs()
      .then((res) => {
        console.log(res.data.data);
        setQuestionList(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  const toggleDropdown = (question) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [question]: !prevState[question],
    }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function onSubmit(data) {
    setIsLoading(true);
    console.log(data);
    askFaq(data)
      .then((res) => {
        console.log(res.data);
        setIsLoading(false);
        alert("Your question has been submitted successfully");
        closeModal();
      })
      .catch((err) => {
        console.error(err);
        alert("There was an error post your question");
        setIsLoading(false);
      });
  }

  // eslint-disable-next-line no-unused-vars
  function handleSearch(e) {
    console.log(e.target.value);
    const serachTerm = e.target.value
    setSearchTerm(serachTerm)
    if (serachTerm === '') {
      setFilteredQuestions(questionList);
    } else {
      setFilteredQuestions(questionList.filter(i => {
        const question = i.question
        return question.toLowerCase().includes(searchTerm.toLowerCase());
      }))
    }
  }

  console.log(questionList)

  if (isLoading) return(
  <div className="opacity-50 flex justify-center items-center w-screen h-screen z-50">
    <Bars
      height="100"
      width="300"
      color="#FFBF00"
      ariaLabel="bars-loading"
      wrapperStyle={{}}
      wrapperClassName=" mx-auto"
      visible={true}
    />
  </div>
  ); 
  return (
    <section id="faq">
      <p className="bg-black text-white text-center lg:text-3xl text-2xl py-6 mb-6">
        Frequently Asked Questions
      </p>

      <div className="flex justify-center items-center md:flex-col md:flex-wrap md:gap-4 flex-col flex-wrap gap-4">
        {questionList.length > 0 ? (
          questionList.map((item) => (
            <div
              key={item._id}
              className="relative lg:w-1/2 lg:px-0 w-full px-5"
            >
              <button
                onClick={() => toggleDropdown(item.question)}
                className={`flex justify-between items-center capitalize font-medium bg-gray-100 text-black lg:py-4 lg:px-8 py-4 px-4 rounded focus:outline-none w-full ${
                  dropdownStates[item.question] ? "text-red-500" : ""
                }`}
              >
                <span>{item.question}</span>
                <FiArrowDownCircle
                  size={30}
                  className={`ml-2 text-green-500 ${
                    dropdownStates[item.question]
                      ? "transform rotate-180  text-red-500"
                      : ""
                  }`}
                />
              </button>
              {dropdownStates[item.question] && (
                <div className="top-full left-0 bg-white shadow-md py-2 px-4 rounded mt-1 w-full md:w-auto z-50">
                  <ul>
                    <li>{item.answer}</li>
                  </ul>
                </div>
              )}
            </div>
          ))
        ) : isLoading ? (
          <div className="opacity-50 flex justify-center items-center w-screen h-screen z-50">
            <Bars
              height="100"
              width="300"
              color="#FFBF00"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClassName=" mx-auto"
              visible={true}
            />
          </div>
        ) : (
          <p>No questions available</p>
        )}
      </div>
      <hr className=' w-2/3 my-2 mx-auto'/>


      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        className="modal"
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        }}
      >
        <div className="modal-content bg-white rounded-lg px-12 py-10 mx-auto lg:w-2/5 w-4/5 lg:w-98 lg:mt-28 mt-32">
          <div className="modal-header flex justify-between items-center mb-4">
            <h2 className="text-center m-auto font-Space-Grotesk font-bold">
              Ask me anything
            </h2>
            <button onClick={closeModal} className="close-icon">
              <HiOutlineX size={25} />
            </button>
          </div>
          <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <input
              {...register("fullName")}
              type="text"
              // name="name"
              placeholder="Enter your name"
              required
              className="input-field w-full p-2 border bg-gray-200 border-black rounded-lg placeholder:text-sm"
            />
            <br />
            <input
              {...register("email")}
              type="email"
              // name="email"
              placeholder="Enter your email address"
              required
              className="input-field w-full p-2 border bg-gray-200 border-black rounded-lg placeholder:text-sm"
            />
            <br />
            <select
              {...register("category")}
              // name="category"
              required
              className="input-field w-full p-2 pr-8 border bg-gray-200 border-black rounded-lg text-sm font-Space-Grotesk"
            >
              <option value="">Select category</option>
              <option value="giftcards">Giftcards</option>
              <option value="crypto">Crypto</option>
              <option value="wallet">Wallet</option>
              <option value="others">Others</option>
            </select>
            <br />
            <textarea
              {...register("question")}
              // name="question"
              placeholder="Type your question here"
              required
              className="input-field w-full mb-2 p-2 border bg-gray-200 border-black rounded-lg placeholder:text-sm resize-none"
            ></textarea>
            <br />
            <button
              type="submit"
              className="bg-black text-center m-auto text-white h-8 w-20 font-bold py-1 px-2 rounded"
            >
              {isLoading ? (
                <div className="flex justify-center items-center w-full h-full">
                  <Bars
                    height="10"
                    width="30"
                    color="#FFBF00"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClassName=" mx-auto"
                    visible={true}
                  />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </Modal>

      {/* Button to open the modal */}
      <div className="bg-pri-pink lg:w-2/5  w-4/5 mx-auto my-7 text-center py-5  rounded-lg">
        <h3 className="w-1/2 mx-auto text-center py-2 font-bold">
          Still not satisfied?
        </h3>
        <p className="mb-4">
          Submit a question and we will get back <br></br>to you with a tailored
          answer.
        </p>
        <button
          onClick={openModal}
          className="bg-black hover:bg-grey text-white font-bold py-2 px-4 rounded"
        >
          Ask a Question
        </button>
      </div>
    </section>
  );
}

Modal.setAppElement("#root"); // Set the root element for the modal
