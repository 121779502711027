import React from 'react';

export const AmountInput = ({ name, placeholder, value, onChange, state }) => {
  return (
    <div className=' flex justify-between items-center border border-solid border-yellow-400 rounded-lg p-0 overflow-hidden mx-auto mb-2 focus:outline-none w-full'>
                    <input type='text'
                        name={name}
                        className=' pl-6 pr-2 py-2 w-full focus:outline-none'
                        value={value}
                        inputMode='numeric'
                        pattern='^[0-9]+$'
                        min='0'
                        onChange={(e)=> onChange(e.target.value > 0? Math.abs(e.target.value) : '')}
                        disabled={state}
                        required
                        placeholder={placeholder}/>
                </div>
  )
}
