import React, { useEffect, useState } from "react";
import { sex } from "../../constants/details";
// eslint-disable-next-line
import { AiFillCaretDown } from "react-icons/ai";
import { Input } from "./CustomInput";
import { updateUser } from "../../hooks/services";
import { BallTriangle } from "react-loader-spinner";
import { useUserContext } from "../../context/UserContext";
import { useAuthContext } from "../../context/AuthContext";

export const BioForm = () => {
  const labelStyles = "text-2xs font-bold font-inter text-gray-600";
  const dropdownStyles =
    "relative focus:outline-none border border-gray-900 cursor-pointer w-56";
  const dropdownContentStyles =
    "font-Space-Grotesk text-right flex justify-evenly p-2";
 
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const sexes = sex;
  const { user, setUser } = useUserContext();
  const { token } = useAuthContext();
  const [fullName, setFullName] = useState(user?.fullName);
  const [state, setState] = useState(user?.state);
  const [city, setCity] = useState(user?.city);
  const [phone, setPhone] = useState(user?.phoneNumber);
  const [country, setCountry] = useState(user?.country ?? "");
  const [address, setAddress] = useState(user?.address);
  const [selectSex, setSelectedSex] = useState("Male");
  const [isAllInputsEditable, setIsAllInputsEditable] = useState(edit);

  // eslint-disable-next-line
  const handleSexClick = (selectedSex) => {
    setSelectedSex(selectedSex);
    setIsOpen(false);
  };

  const handleInputChange = (e, setter) => {
    setEdit(true);
    setter(e.target.value);
  };

  const submitOrEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setEdit(false);
    const body = {
      fullName,
      sex: selectSex,
      country,
      state,
      city,
      address,
    };
    const result = await updateUser(user._id, body, token.token);
    setUser(result.data.data.data);
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      setCity(user.city);
      setAddress(user.address);
      setCountry(user.country);
      setFullName(user.fullName);
      setPhone(user.phoneNumber);
      setState(user.state);
      setSelectedSex(user.sex);
    }
  }, [user]);

  return (
    <div className=" px-8 py-2 overflow-auto">
      <form onSubmit={submitOrEdit}>
        <div className="flex flex-col lg:flex-row items-center gap-4 text-left">
          <Input
            name="fullname"
            placeholder="your full name"
            value={fullName}
            onChange={(e) => handleInputChange(e, setFullName)}
            state={isAllInputsEditable}
            edited = {edit}
          />
          <Input
            name="country"
            placeholder="country"
            value={country}
            onChange={(e) => handleInputChange(e, setCountry)}
            state={isAllInputsEditable}
            edited = {edit}
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-4 text-left">
          <Input
            name="state"
            placeholder="Your State"
            value={state}
            onChange={(e) => handleInputChange(e, setState)}
            state={isAllInputsEditable}
            edited = {edit}
          />
          <Input
            name="address"
            placeholder="Street Address"
            value={address}
            onChange={(e) => handleInputChange(e, setAddress)}
            state={isAllInputsEditable}
            edited = {edit}
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-4 text-left">
          <Input
            name="city"
            placeholder="City"
            value={city}
            onChange={(e) => handleInputChange(e, setCity)}
            state={isAllInputsEditable}
            edited = {edit}
          />
          <Input
            name="phone"
            placeholder="Phone Number"
            value={phone}
          />
        </div>
        <div className=" flex flex-col lg:flex-row items-center text-left gap-4 w-full">
          <Input
            name="email"
            placeholder="your email"
            value={user?.email}
            state={false}
          />

          <div className="flex ">
            <div>
              <label htmlFor={"sex"} className={labelStyles}>
                Sex
              </label>
              <div
                className={` ${dropdownStyles} ${
                  edit ? "bg-white" : "bg-gray-300"
                } `}
                id="sex"
              >
                <div
                  className={dropdownContentStyles}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <p className="text-black capitalize ">{selectSex}</p>{" "}
                  <AiFillCaretDown />
                </div>
                {isOpen && edit && (
                  <ul className=" absolute bg-pri-pink options-list text-left py-2 font-Space-Grotesk border border-yellow-400 w-32">
                    {sexes?.map((selectedSex, index) => (
                      <li
                        key={index}
                        className={`cursor-pointer capitalize hover:bg-yellow-300 px-4`}
                        onClick={() => handleSexClick(selectedSex)}
                      ><hr/>
                        {selectedSex}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        {edit ? (
          <button className=" block bg-black text-gray-100 w-max px-4 ml-auto mt-4 py-2 rounded-lg">
            Save{" "}
          </button>
        ) : loading ? (
          <div className="ml-auto w-max py-2 px-4 bg-black rounded-lg">
            <BallTriangle
              height="22"
              width="50"
              color="#FFBF00"
              ariaLabel="ball-triangle"
              wrapperStyle={{}}
              wrapperClassName=" mx-auto"
              visible={true}
            />
          </div>
        ) : (
          <p
            className=" bg-black text-gray-100 w-max px-4 ml-auto mt-4 py-2 rounded-lg"
            onClick={() => {
              setEdit(!edit);
              setIsAllInputsEditable(!isAllInputsEditable); // Toggle the state
            }}
          >
            Edit
          </p>
        )}
      </form>
    </div>
  );
};
