import React, { useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import { TeamCard } from './TeamCard';
import { teamMembers } from '../../constants/details';

function TeamSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? teamMembers.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === teamMembers.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className='max-w-[1400px] w-full mx-auto relative'>
      <div
        className=' mx-auto duration-500'
      >
        <TeamCard url={teamMembers[currentIndex].url} name={teamMembers[currentIndex].name} role={teamMembers[currentIndex].role} image={teamMembers[currentIndex].image}/>
      </div>
      {/* Left Arrow */}
      <div className='md:hidden group-hover:block absolute top-[0%] mt-40 -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className='md:hidden group-hover:block absolute top-[0%] mt-40 -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className='flex top-4 justify-center py-2'>
        {teamMembers.map((_, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className='text-2xl cursor-pointer hover:bg-yellow-300 rounded-full'
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeamSlider;