import React, { useState } from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

export const DropDownSelect = ({options, setValue, placeholder}) => {
    const [selectedOption, setSelectedOption] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setValue(option)
        setIsOpen(false);
      };
    return (
        <div className=" relative custom-dropdown rounded-md border border-yellow-400 mb-8 cursor-pointer" onMouseLeave={()=>setIsOpen(false)} >
                    <div className="selected-option font-bold font-Space-Grotesk text-center flex justify-between gap-2 items-center py-2 px-4" onClick={() => setIsOpen(!isOpen)}>
                        <p>{ selectedOption ?? placeholder}</p> {isOpen? <AiFillCaretUp/> :<AiFillCaretDown/> }
                    </div>
                    {isOpen && (
                        <ul className=" absolute bg-slate-50 options-list text-left py-4 w-full h-40 overflow-y-auto font-bold font-Space-Grotesk z-50 rounded-md border border-yellow-400">
                        {options? options.map((option, index) => (
                            <li
                            key={index}
                            className={`${option === selectedOption ? 'selected ' : ''} cursor-pointer hover:bg-yellow-300 px-4`}
                            onClick={() => handleOptionClick(option)}
                            >
                            {option.toLocaleUpperCase()}
                            </li>
                        )) : <li>loading</li>}
                        </ul>
                    )}
                </div>
    )
}
