import React, { useEffect, useRef, useState } from "react";
import "@smile_identity/smart-camera-web";
import axios from "axios";
import { useUserContext } from "../../context/UserContext";
import { useAuthContext } from "../../context/AuthContext";
import { BIOMETRIC_KYC } from "../../constants/links";


const KycPhoto = ({onNext}) => {
  const cameraRef = useRef(null);
  const { token } = useAuthContext();
  const { user, file } = useUserContext();
  const [fileType, setFileType] = useState("");
  

  useEffect(() => {
    switch (file?.type) {
      case "National ID Card":
        setFileType("IDENTITY_CARD");
        break;
      case "Driver's License":
        setFileType("DRIVERS_LICENSE");
        break;
      case "International Passport":
        setFileType("PASSPORT");
        break;
      case "Voters ID":
        setFileType("VOTER_ID");
        break;
      case "NIN":
        setFileType("IDENTITY_CARD");
        break;
      default:
        setFileType("");
        break;
    }

    const handleImagesComputed = async (e) => {
      try {
        if (fileType && file.selectedFile) {
          
          const jsonpayload = {
            documentBase64StringImage: file?.selectedFile,
            selfieBase64StringImage: e.detail.images[0].image,
            id_type: fileType,
          };

          // Use formData with Axios or another method to send the data
          axios
            .post(`${BIOMETRIC_KYC}/${user._id}`, jsonpayload, {
              headers: {
                Authorization: `Bearer ${token.token}`,
              },
            })
            .then(() => {
              onNext()
            })
            .catch(() => {
              onNext()
            });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const cameraEl = cameraRef.current;
    cameraEl.addEventListener("imagesComputed", handleImagesComputed);

    return () => {
      cameraEl.removeEventListener("imagesComputed", handleImagesComputed);
    };
    // eslint-disable-next-line
  }, [file, fileType]);

  return (
    <div className="">
      <div className="">
        <div className=" rounded-lg h-1/2 overflow-x-hidden">
          <smart-camera-web ref={cameraRef}></smart-camera-web>
        </div>
      </div>
    </div>
  );
};

export default KycPhoto;
