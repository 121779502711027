import React,{ useEffect, useState } from 'react'
import { SelectWallet } from './SelectWallet'
import { Bars } from 'react-loader-spinner';
import TimerRetry from '../../Modals/TimerRetry';
import { requestOtp, resetWalletPin } from '../../../hooks/services';
import { useUserContext } from '../../../context/UserContext';
import { useAuthContext } from '../../../context/AuthContext';
import { PinInput } from '../PinInput';
import { errStyle } from '../../../constants/details';
import { PasswordInput } from '../PasswordInput';
import { Success } from './Success';

export const ResetPin = () => {
    const [selectedWallet, setSelectedWallet] = useState();
    const {token, setOtpExpiration} = useAuthContext();
    const [success, setSuccess] = useState(false)
    const [otpSent, setOtpSent] = useState(false);
    const [confirmPin , setConfirmPin] = useState('');
    const [pin , setPin] = useState('');
    const [otp, setOtp] =useState('');
    const { user } = useUserContext();
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const requestResetOtp = (e) =>{
        e.preventDefault();
        setLoading(true)
        requestOtp(user?.email).then(result=>{
          setOtpExpiration(result.data.expiresIn)
          setOtpSent(true)
        }).catch(error=>{
            console.log(error)
            setError(true)
            setErrorMessage(error.response?.data?.message)
        });
        setLoading(false)
      }
    const resetPinSubmit = (e) =>{
        e.preventDefault();
          setLoading(true);
        if (error) {
          setLoading(false);
          return;
        }
        const payload = {
          wallet_number : selectedWallet?.wallet_number,
          otp,
          pin 
        }
        console.log(payload);
        resetWalletPin(token.token, payload).then(res=>{
          console.log(res.data);
          setLoading(false);
          setSuccess(res.data.success);
          setMessage(res.data.message);
        }).catch(error=>{
          console.log(error);
          setLoading(false);
          setError(true)
          setErrorMessage(error.response?.data?.message)
        })
      }

    useEffect(() => {
        if (confirmPin !== pin) {
            setError(true)
            setErrorMessage('Confirm pin does not match')
        }
        else{
            setError(false)
        }
      }, [confirmPin, pin])
    return (
        <div>
            {(otpSent && !success) && 
                <div>
                    <SelectWallet setSelectedWallet={setSelectedWallet} />
                    <form className=' mx-auto p-8 block mb-2' onSubmit={(e)=>resetPinSubmit(e)}>
                        <PinInput name="newPin" placeholder="New Pin" value={pin} onChange={(e)=>setPin(e)} state={loading} />
                        <PinInput name="confirmPin" placeholder="Confirm Pin" value={confirmPin} onChange={(e)=>setConfirmPin(e)} state={loading} />
                        <PasswordInput name={'Otp'} placeholder={'Enter OTP'} value={otp} onChange={setOtp} state={loading}/>
                        {loading? <div className=' w-4/6 mx-auto rounded-lg p-1 flex justify-center bg-black cursor-pointer'>
                        <Bars
                                height="40"
                                width="100"
                                color="#FFBF00"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                                /> 
                        </div>
                                : <input type='submit' value='Reset Pin' disabled={loading} className={`text-white block ${loading? 'bg-gray-700 cursor-wait': 'bg-black cursor-pointer'} transition-all ease-in-out rounded-lg px-6 py-4 w-4/6 mx-auto mb-4 text-center`}/>}
                        
                        {error && <p className={errStyle}>{errorMessage}</p>}
                    <TimerRetry/>
                    </form>
                    <p>{message}</p>
                </div>}
            {(!otpSent && !success) &&
                <div>
                <h3 className=' font-Space-Grotesk font-bold my-4 text-lg'>Reset Wallet Transaction Pin</h3>
                <p className=' text-2xs mb-4 bg-slate-400 w-1/2 mx-auto p-2'>An OTP will be sent to your registered email which you would use to reset your pin</p>
                {loading? <div className=' w-36 mx-auto rounded-md p-1 flex justify-center bg-black cursor-pointer'>
                        <Bars
                            height="30"
                            width="40"
                            color="#FFBF00"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                            />
                        </div>
                        : <button onClick={requestResetOtp} className=" bg-pri-pink transition-all ease-in-out hover:bg-slate-800 hover:text-white px-4 py-2 w-36 rounded-md font-Space-Grotesk font-bold border border-black">Request OTP</button> }
                
            </div>
            }
            {success && <Success message={message}/>}
        </div>
    )
}
