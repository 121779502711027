import axios from "axios";
import React from "react";
import { useState } from "react";
import { Bars } from "react-loader-spinner";
import success_tick from "../../assets/success_tick.svg";

export const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submited, setSubmited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    error: false,
    msg: "something went wrong!",
  });
  const contactInputStyle =
    " focus:outline-none w-full p-2 text-lg text-center mb-2 rounded-md border border-solid border-slate-600 placeholder:text-base";

  const sendMessage = (e) => {
    e.preventDefault();
    const newError = {
      error: false,
      msg: "",
    };
    setError(() => newError);
    setLoading(true);
    axios
      .post("https://formsubmit.co/ajax/" + process.env.REACT_APP_EMAILID, {
        name,
        email,
        message,
      })
      .then((res) => {
        setLoading(false);
        setSubmited(true);
        console.log(res);
      })
      .catch((error) => {
        setLoading(false);
        const newError = {
          error: true,
          msg: error.message,
        };
        setError(() => newError);
        console.log(error);
      });
  };
  return (
    <div className=" w-full py-16 bg-slate-200 h-full px-4">
      <h2 className=" font-Space-Grotesk font-bold text-2xl text-center mb-4">
        We want to hear from you.
      </h2>
      {loading ? (
        <div className=" mx-auto w-max">
          <Bars
            height="100"
            width="300"
            color="#FFBF00"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClassName=" mx-auto"
            visible={true}
          />
        </div>
      ) : submited ? (
        <div className=" my-8">
          <div className=" w-28 mx-auto flex flex-col justify-center mb-4">
            <img src={success_tick} alt="successful" />
          </div>
          <p className=" text-center font-inter font-semibold text-green-700">
            Message Sent.
          </p>
        </div>
      ) : (
        <form
          onSubmit={sendMessage}
          className=" mx-auto p-2 lg:w-3/5 text-center rounded-xl"
        >
          <input
            type="text"
            value={name}
            name="fullname"
            placeholder="Enter your name"
            onChange={(e) => setName(e.target.value)}
            className={contactInputStyle}
          />
          <input
            type="email"
            name="email"
            value={email}
            placeholder="Enter a valid email"
            onChange={(e) => setEmail(e.target.value)}
            className={contactInputStyle}
          />
          <textarea
            type="text"
            value={message}
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            className="resize-none h-40 focus:outline-none w-full p-2 text-lg text-center mb-2 rounded-md border border-solid border-slate-400"
          />
          <input
            type="submit"
            value={"Submit"}
            className=" px-20 py-4 uppercase bg-black text-white cursor-pointer rounded-2xl hover:text-slate-300 "
          />
          {error.error && (
            <p className=" text-red-600 font-inter font-semibold text-sm">
              Sorry and error occured please try again later
            </p>
          )}
        </form>
      )}
    </div>
  );
};
