import React, { useEffect, useState } from "react";

export const Input = ({ name, placeholder, value, onChange, state, edited }) => {
    const labelStyles = ' text-2xs font-bold font-inter text-gray-600 mb-2 ';
    const inputStyles = 'block p-2 px-5 border border-gray-900  focus:outline-none ';
    const [edit, setEdit] = useState(state);

    useEffect(() => {
        setEdit(state);
      }, [state]);
    return (
        <div className=" flex items-center">
            <div onBlur={()=> setEdit(false)} >
                <label htmlFor={name} className={labelStyles}>{name[0].toLocaleUpperCase() + name.toLocaleLowerCase().slice(1)}</label>
                <input type="text " className={`${edited ? 'bg-white ' : 'bg-gray-300'} ${inputStyles}`} id={name} value={value || ""} placeholder={placeholder} disabled={!edit} onChange={onChange} />
            </div>
            {/* <div className='  p-2 cursor-pointer text-xs rounded-full' onClick={()=> setEdit(!edit)}>
                {onChange? <AiOutlineEdit/> : <AiOutlineEdit className=" opacity-0"/>}
            </div> */}
            
          </div>
    );
  };