import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {AiOutlineArrowLeft} from 'react-icons/ai';
import {MdOutlineGppGood} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { CHANGE_PASSWORD } from '../../../constants/links';
import { config } from '../../../hooks/services';
import { PasswordInput } from '../PasswordInput';
import { Bars } from 'react-loader-spinner';
import { useAuthContext } from '../../../context/AuthContext';

export const ChangePassword = () => {
    const {token} = useAuthContext();
    const [currentPassword , setCurrentPassword] = useState('');
    const [confirmPassword , setConfirmPassword] = useState('');
    const [password , setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const errStyle = ` mx-auto w-2/3 -mt-4 text-red-500`;

    async function handleSubmit(e){
        e.preventDefault();
        setLoading(true);
      if (error) {
        return;
      }
        await axios.post(CHANGE_PASSWORD, {
              currentPassword,
              password: password
          },config(token.token)).then(res => {
              setLoading(false);
              setSuccess(true)
              console.log(res);
              setError(false)
          }).catch(err => {
              setLoading(false);
              setSuccess(false)
              setError(true);
              console.log(err);
              if (err.message) {
                setErrorMessage(err.message)
              }else{
                setErrorMessage(err.response.data.message)
              }
              
          });
      }
      useEffect(() => {
        if (confirmPassword !== password) {
            setError(true)
            setErrorMessage('Confirm password does not match')
        }
        else{
            setError(false)
        }
      }, [confirmPassword, password])
  return (
    <div className=' px-8 py-2'>
        {success?
        <div className=' font-bold text-lg text-green-600 p-16'>
          <div className=' border border-yellow-300 py-8'>
            <p>Successful</p>
            <MdOutlineGppGood size={50} className=' mx-auto'/>
          </div>
          <Link to={-1} className=' text-yellow-500 cursor-pointer mb-2 flex gap-1 justify-center px-2 py-1 items-center'>
                <AiOutlineArrowLeft/>
                <p>Back</p>
            </Link>
        </div>
        :
        <div className=' w-full mt-12 md:mt-2'>
            <Link to={-1} className=' text-yellow-500 text-left cursor-pointer mb-2 flex gap-1 items-center'>
                <AiOutlineArrowLeft/>
                <p>Back</p>
            </Link>
            <form className=' mx-auto p-8 block' onSubmit={(e)=>handleSubmit(e)}>
                <PasswordInput name="currentPassword" placeholder="Current Password'" value={currentPassword} onChange={(e)=>setCurrentPassword(e)} state={loading} />
                <PasswordInput name="newPassword" placeholder="New Password" value={password} onChange={(e)=>setPassword(e)} state={loading} />
                <PasswordInput name="confirmPassword" placeholder="Confirm Password" value={confirmPassword} onChange={(e)=>setConfirmPassword(e)} state={loading} />
                {loading? <div className=' w-4/6 mx-auto rounded-lg p-1 flex justify-center bg-black cursor-pointer'>
                  <Bars
                          height="40"
                          width="100"
                          color="#FFBF00"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                          /> 
                </div>
                          : <input type='submit' value='Change Password' disabled={loading} className={`text-white block ${loading? 'bg-gray-700 cursor-wait': 'bg-black cursor-pointer'}  rounded-lg px-6 py-4 w-4/6 mx-auto mb-6 text-center`}/>}
                
                {error && <p className={errStyle}>{errorMessage}</p>}
            </form>
            
          </div>
        }
    </div>
  )
}
