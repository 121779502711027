import React, { useState } from "react";
import { product_name } from "../../constants/details";
import { AiOutlineHome } from "react-icons/ai";
import { IoMdArrowDropright } from "react-icons/io";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { HiOutlineUserCircle } from "react-icons/hi";
import { BsCreditCard2Front, BsBoxArrowInRight } from "react-icons/bs";
import { BiTransferAlt } from "react-icons/bi";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/logo.svg";
import useLogOut from "../../hooks/useLogOut";

const SideBar = () => {
  const [show, setShow] = useState(false);
  // const [messages, setMessages] = useState(0)
  const navLinkStyleDefault =
    " flex group-hover:block font-inter rounded-md p-3 lg:pl-8 hover:bg-pri-pink-light hover:text-black transition-all justify-between group items-center md:space-x-20 mb-4 ";
  const navLabelStyle = " md:block";

  const logOut = useLogOut();
  return (
    <div className=" bg-pri-pink lg:h-full transition-all ease-in-out lg:pl-4 py-1.5 px-4 flex items-center flex-col lg:gap-y-7">
      <div className=" flex justify-between w-full items-center p-2">
        <Link to="/dashboard/">
          <div className="block w-40 ease-in-out mx-auto">
            <img src={logo} alt={`${product_name} logo`} className=" w-full" />
          </div>
        </Link>
        <p
          className=" cursor-pointer hover:bg-yellow-700 hover:text-white w-max transition-all ease-in-out px-3 py-2 text-xl font-bold lg:hidden block rounded-md"
          onClick={() => setShow(!show)}
        >
          {show ? <>&#9932;</> : <>&#9778;</>}
        </p>
      </div>
      <div className=" hidden lg:block">
        <div>
          <NavLink
            exact="true"
            className={({ isActive }) =>
              isActive
                ? navLinkStyleDefault + " bg-black  text-white"
                : navLinkStyleDefault + " text-black"
            }
            to="overview"
          >
            <div className=" flex justify-start items-center space-x-6">
              <AiOutlineHome />
              <p className={navLabelStyle}>Dashboard</p>
            </div>
            <div className="opacity-0 group-hover:opacity-100">
              <IoMdArrowDropright />
            </div>
          </NavLink>
          <NavLink
            exact="true"
            className={({ isActive }) =>
              isActive
                ? navLinkStyleDefault + " bg-black text-white"
                : navLinkStyleDefault + " text-black"
            }
            to="giftcards"
          >
            <div className=" flex justify-start items-center space-x-6">
              <BsCreditCard2Front />
              <p className={navLabelStyle}>GiftCards</p>
            </div>
            <div className="opacity-0 group-hover:opacity-100">
              <IoMdArrowDropright />
            </div>
          </NavLink>
          <NavLink
            exact="true"
            className={({ isActive }) =>
              isActive
                ? navLinkStyleDefault + " bg-black text-white"
                : navLinkStyleDefault + " text-black"
            }
            to="crypto"
          >
            <div className=" flex justify-start items-center space-x-6">
              <AiOutlineHome />
              <p className={navLabelStyle}>Crypto</p>
            </div>
            <div className="opacity-0 group-hover:opacity-100">
              <IoMdArrowDropright />
            </div>
          </NavLink>

          <NavLink
            exact="true"
            className={({ isActive }) =>
              isActive
                ? navLinkStyleDefault + " bg-black text-white"
                : navLinkStyleDefault + " text-black"
            }
            to="wallet"
          >
            <div className=" flex justify-start items-center space-x-6">
              <MdOutlineAccountBalanceWallet />
              <p className={navLabelStyle}>Wallet</p>
            </div>
            <div className="opacity-0 transition-all group-hover:opacity-100">
              <IoMdArrowDropright />
            </div>
          </NavLink>

          <NavLink
            exact="true"
            className={({ isActive }) =>
              isActive
                ? navLinkStyleDefault + " bg-black text-white"
                : navLinkStyleDefault + " text-black"
            }
            to="transactions"
          >
            <div className=" flex justify-start items-center space-x-6">
              <BiTransferAlt />
              <p className={navLabelStyle}>Transactions</p>
            </div>
            <div className="opacity-0 group-hover:opacity-100">
              <IoMdArrowDropright />
            </div>
          </NavLink>

          <NavLink
            exact="true"
            className={({ isActive }) =>
              isActive
                ? navLinkStyleDefault + " bg-black text-white"
                : navLinkStyleDefault + " text-black"
            }
            to="profile"
          >
            <div className=" flex justify-start items-center space-x-6">
              <HiOutlineUserCircle />
              <p className={navLabelStyle}>Profile</p>
            </div>
            <div className="opacity-0 group-hover:opacity-100">
              <IoMdArrowDropright />
            </div>
          </NavLink>
        </div>
        <div>
          <div
            onClick={() => logOut()}
            className=" flex justify-start items-center p-2 lg:pl-8 space-x-6 hover:bg-black hover:text-white cursor-pointer rounded-md"
          >
            <BsBoxArrowInRight />
            <p className={navLabelStyle}>Log Out</p>
          </div>
        </div>
      </div>

      <div className="lg:hidden relative w-full">
        <nav
          onClick={() => setShow(!show)}
          className={`${
            show ? "flex" : "hidden"
          } flex-col justify-between  mx-auto z-50 bg-pri-pink lg:bg-transparent p-4 lg:p-0`}
        >
          <div>
            <NavLink
              exact="true"
              className={({ isActive }) =>
                isActive
                  ? navLinkStyleDefault + " bg-black  text-white"
                  : navLinkStyleDefault + " text-black"
              }
              to="overview"
            >
              <div className=" flex justify-start items-center space-x-6">
                <AiOutlineHome />
                <p className={navLabelStyle}>Dashboard</p>
              </div>
              <div className="opacity-0 group-hover:opacity-100">
                <IoMdArrowDropright />
              </div>
            </NavLink>
            <NavLink
              exact="true"
              className={({ isActive }) =>
                isActive
                  ? navLinkStyleDefault + " bg-black text-white"
                  : navLinkStyleDefault + " text-black"
              }
              to="giftcards"
            >
              <div className=" flex justify-start items-center space-x-6">
                <BsCreditCard2Front />
                <p className={navLabelStyle}>GiftCards</p>
              </div>
              <div className="opacity-0 group-hover:opacity-100">
                <IoMdArrowDropright />
              </div>
            </NavLink>
            <NavLink
              exact="true"
              className={({ isActive }) =>
                isActive
                  ? navLinkStyleDefault + " bg-black text-white"
                  : navLinkStyleDefault + " text-black"
              }
              to="crypto"
            >
              <div className=" flex justify-start items-center space-x-6">
                <AiOutlineHome />
                <p className={navLabelStyle}>Crypto</p>
              </div>
              <div className="opacity-0 group-hover:opacity-100">
                <IoMdArrowDropright />
              </div>
            </NavLink>
            <NavLink
              exact="true"
              className={({ isActive }) =>
                isActive
                  ? navLinkStyleDefault + " bg-black text-white"
                  : navLinkStyleDefault + " text-black"
              }
              to="wallet"
            >
              <div className=" flex justify-start items-center space-x-6">
                <MdOutlineAccountBalanceWallet />
                <p className={navLabelStyle}>Wallet</p>
              </div>
              <div className="opacity-0 transition-all group-hover:opacity-100">
                <IoMdArrowDropright />
              </div>
            </NavLink>

            <NavLink
              exact="true"
              className={({ isActive }) =>
                isActive
                  ? navLinkStyleDefault + " bg-black text-white"
                  : navLinkStyleDefault + " text-black"
              }
              to="transactions"
            >
              <div className=" flex justify-start items-center space-x-6">
                <BiTransferAlt />
                <p className={navLabelStyle}>Transactions</p>
              </div>
              <div className="opacity-0 group-hover:opacity-100">
                <IoMdArrowDropright />
              </div>
            </NavLink>

            <NavLink
              exact="true"
              className={({ isActive }) =>
                isActive
                  ? navLinkStyleDefault + " bg-black text-white"
                  : navLinkStyleDefault + " text-black"
              }
              to="profile"
            >
              <div className=" flex justify-start items-center space-x-6">
                <HiOutlineUserCircle />
                <p className={navLabelStyle}>Profile</p>
              </div>
              <div className="opacity-0 group-hover:opacity-100">
                <IoMdArrowDropright />
              </div>
            </NavLink>
          </div>
          <div>
            <div
              onClick={() => logOut()}
              className=" flex justify-start items-center p-2 space-x-6 hover:bg-black hover:text-white cursor-pointer rounded-md"
            >
              <BsBoxArrowInRight />
              <p className={navLabelStyle}>Log Out</p>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
