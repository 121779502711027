import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { rootLinks } from '../constants/links';
import useAuth from '../hooks/useAuth';

export const ProtectedRoutes = () => {
  const token = useAuth();
  return (token?
    <Outlet/>
    : <Navigate to={rootLinks.signin_link}/>
  )
}
