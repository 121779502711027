import React from 'react';
import { SecurityActionCard } from './SecurityActionCard';
import reset_pin_icon from '../../../assets/reset_pin_icon.png';
import reset_password_icon from '../../../assets/reset_password_icon.png';
import two_factor_icon from '../../../assets/two_factor_icon.png';
import lost_pin_icon from '../../../assets/lost_pin.png';

export const SecurityTab = () => {
    return (
        <div className='lg:grid lg:grid-cols-2 lg:auto-cols-auto px-5 py-2 flex flex-col items-center justify-center gap-4'>
            <SecurityActionCard icon={reset_pin_icon} title={`Reset Your Password`} description={`Change your Login Password`} link={'change-password'} action={'Change Password'} />
            <SecurityActionCard icon={reset_password_icon} title={`Change Your Pin`} description={`Change your Transaction PIN`} link={'change-pin'} action={'Manage Pin'} />
            <SecurityActionCard icon={lost_pin_icon} title={`Lost Pin`} description={`Create New Transaction Pin`} link={'reset-pin'} action={'Create New Pin'} />
            <SecurityActionCard icon={two_factor_icon} title={`Activate 2 Factor Authenticationn`} description={`Make your account more secure, manage your 2FA seetings`} link={'set-2fa'} action={'Manage'} />
        </div>
    )
}
