import React, { useState } from 'react';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';

export const PinInput = ({ name, placeholder, value, onChange, state }) => {
    const [show, setShow] = useState(false);
    const eyeStyle = ' hover:bg-gray-200 p-3 cursor-pointer';
  return (
    <div className=' flex justify-between items-center border border-solid border-gray-500 rounded-lg p-0 overflow-hidden mx-auto mb-2 focus:outline-none w-2/3'>
                    <input type={show? 'text': 'password'}
                        name={name}
                        className=' pl-6 pr-2 py-2 w-full focus:outline-none'
                        value={value}
                        inputMode='numeric'
                        pattern='^\d{4}$'
                        // typeof=''
                        max={9999}
                        min={1000}
                        maxLength={4}
                        minLength={4}
                        onChange={(e)=> onChange(e.target.value)}
                        disabled={state}
                        required
                        placeholder={placeholder}/>
                    {show?
                        <div className={eyeStyle} onClick={()=> setShow(!show)}>
                            <AiOutlineEyeInvisible />
                        </div> :
                        <div className={eyeStyle} onClick={()=> setShow(!show)}>
                            <AiOutlineEye />
                        </div> }
                </div>
  )
}
