import React from 'react'
import { WalletAction } from './WalletActions'

export const WalletCard = ({wallet, index}) => {
    return (
        <div className=' flex flex-col bg-white justify-between h-32 border border-slate-400 lg:w-60 w-48 shadow-slate-600 rounded-md hover:shadow-sm shadow-md p-4'>
            <div className=' flex justify-between'>
                <WalletAction index={index}/>
                <p className=' text-3xs font-bold'>Wallet Number: <span>{wallet?.wallet_number}</span></p>
            </div>
            <p className=' text-2xl font-Space-Grotesk font-bold'><span>{wallet?.currency.symbol}</span>{parseFloat(wallet?.balance).toLocaleString('en-US',{ currency: wallet.currency.code})}</p>
            <p className=' text-2xs'>{new Date(wallet?.createdAt).toDateString()}</p>
        </div>
    )
}
