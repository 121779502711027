import React from 'react'
import { useUserContext } from '../../../context/UserContext'
import { WalletCard } from './WalletCard'
import { RiAddCircleFill } from 'react-icons/ri'

export const AccountTab = () => {
    const {wallets} = useUserContext()
    return (
        <div className=' p-8 bg-slate-100 h-96 '>
            <h3 className=' text-left font-inter font-bold text-pri-grey'>Your Wallets</h3>
            <div className=' h-full overflow-y-auto grid lg:grid-cols-2 auto-cols-auto gap-2 items-center justify-center '>
                {wallets? wallets.map((wallet, index)=> (
                    <div key={index}>
                        <WalletCard wallet={wallet} index={index}/>
                    </div>
                ))
                :<p>loading</p>}
                <div className=' bg-slate-200 flex flex-col justify-center cursor-pointer items-center  border border-slate-400 h-32 lg:w-60 w-48 shadow-slate-600 rounded-md hover:shadow-sm shadow-md p-4'>
                    <RiAddCircleFill size={40}/>
                    <p className=' text-2xs font-inter font-bold mt-2 text-slate-600'>Add New Wallet</p>
                </div>
            </div>
        </div>
    )
}
