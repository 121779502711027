import React,{useEffect} from "react";
import { Outlet, NavLink } from "react-router-dom";
import KycHeader from "../Kyc/KycHeader";
import { useUserContext } from "../../context/UserContext";

export const ProfileInformation = () => {
  const { kyc, user, setKyc } = useUserContext();

  useEffect(() => {
    if (user?.kycLevel >= 2) {
      setKyc(true);
    }
  }, [user]);

  

  const linkStyle =
    "text-2xs lg:text-base hover:border-b-4 hover:font-bold hover:text-black hover:border-black py-2 ";
  const linkStyleActive = "  border-b-4 border-black text-black font-bold ";
  return (
    <div>
      {!kyc && <KycHeader />}
      <div className="  border border-amber-100 rounded-lg ml-4 w-max">
        <h2 className=" px-8 pt-8 font-Space-Grotesk font-bold text-left text-2xl">
          Edit Profile
        </h2>
        <div className=" px-8 my-8 flex gap-8 text-sm font-bold font-inter text-gray-600">
          <NavLink
            to={`bio`}
            className={({ isActive }) =>
              !isActive ? linkStyle : `${linkStyle} ${linkStyleActive}`
            }
          >
            My Information
          </NavLink>
          <NavLink
            to={`security`}
            className={({ isActive }) =>
              !isActive ? linkStyle : `${linkStyle} ${linkStyleActive}`
            }
          >
            Security Information
          </NavLink>
          <NavLink
            to={`account`}
            className={({ isActive }) =>
              !isActive ? linkStyle : `${linkStyle} ${linkStyleActive}`
            }
          >
            Account Details
          </NavLink>
          <NavLink
            to={`referrals`}
            className={({ isActive }) =>
              !isActive ? linkStyle : `${linkStyle} ${linkStyleActive}`
            }
          >
            Referrals
          </NavLink>
        </div>
        <div className=" mb-0 overflow-hidden">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
