import React from 'react'

const ProcessSteps = (props) => {
    const {title, description, icon, position} = props;
  return (
    <div className=' flex lg:justify-start justify-center items-start space-x-6 mb-8'>
      <div className='w-14'>
        <img src={icon} alt="" className=' w-full h-full'/>
      </div>
      <div className=' text-left'>
        <p className=' text-xl font-bold mb-2 font-Space-Grotesk'>{position}. {title}</p>
        <p className=' text-lg md:text-sm text-gray-500 w-2/3 font-inter'>{description}</p>
      </div>
    </div>
  )
}

export default ProcessSteps
