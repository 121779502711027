import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import SignupPage from '../pages/SignupPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import NotFound from '../pages/NotFound';

const AuthRoutes = () => {
    return (
        <Routes>
            <Route path='/'>
                <Route path='/signup' element={<SignupPage/>} />
                <Route path='/signin' element={<LoginPage/>} />
                <Route path='/forgot-password' element={<ForgotPasswordPage/>} />
                <Route path='/reset-password' element={<ResetPasswordPage/>} />
                <Route path='/*' element={<NotFound/>}/>
            </Route>
            
        </Routes>
    )
}

export default AuthRoutes
