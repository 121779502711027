import React from "react";
import MapWhite from "../assets/map_white.svg";

export const QuoteMapBanner = () => {
  return (
    <div className=" hidden lg:block bg-black text-white w-60 p-8 rounded-xl">
      <p className=" text-center font-Space-Grotesk mb-4">
        Be limitless, Get a secure wallet address in{" "}
        <span className=" text-yellow-200">5 seconds</span>
      </p>
      <div className=" w-full">
        <img src={MapWhite} alt="maps" />
      </div>
    </div>
  );
};
