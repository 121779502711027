import React from 'react'

export const Chatbubble = (props) => {
    const {name, sender, message, attachments} = props;
    const sendOrRecieve = sender? 'text-right' : 'text-left';
    const files = attachments? attachments : [];
    const time = new Date(props.timestamp)
    const timestamp = time.toLocaleTimeString()
  return (
    <div className={`' w-full flex ' ${sender? ' justify-end':' justify-start' } px-2 py-2`}>
        <div className={`${sender? ' items-center rounded-br-none rounded-bl-2xl ' :' rounded-br-2xl rounded-bl-none ' } ' m-1 px-4 py-2 whitespace-normal rounded-t-2xl bg-light-gray w-2/5 '`}>
            <p className='text-sm'>{name}</p>
            <div className=''>
              {files.length > 0 ? files.map((value,index)=>(
                <a href={value.url} key={index} target="_blank" rel="noopener noreferrer">
                  <div  className='mb-1 w-24 hover:bg-gray-500 p-1'>
                    <img src={value.url} alt={value.file_name} />
                  </div>
                </a>
                
              )): ''}
            </div>
            
            <p className='w-full text-left break-words'>
                {message}
            </p>
            <p className={`${sendOrRecieve} text-2xs`}>{timestamp}</p>
        </div>
    </div>
    
  )
}
