import React from 'react';
import {Route, Navigate, Routes } from 'react-router-dom';
import DashboardPage from '../pages/DashboardPage';
import {ProtectedRoutes} from './protectedRoutes';
import Overview from '../components/Overview';
import GiftCardPage from '../components/DashboardGiftcard';
import { MessagesPage } from '../components/MessagesPage';
import { TransactionsPage } from '../components/TransactionsPage';
import { ProfilePage } from '../components/ProfilePage';
import { BioForm } from '../components/profile/BioForm';
import NotFound from '../pages/NotFound';
import { SecurityTab } from '../components/profile/security/SecurityTab';
import { ChangePassword } from '../components/profile/security/ChangePassword';
import { ChangePin } from '../components/profile/security/ChangePin';
import ComingSoonPage from '../pages/ComingSoonPage';
import { ResetPin } from '../components/profile/security/ResetPin';
import { Set2Factor } from '../components/profile/security/Set2Factor';
import { AccountTab } from '../components/profile/account/AccountTab';
import { WalletPage } from '../components/Wallet/WalletPage';
import CryptoTradePage from '../pages/CryptoTradePage';
import TransactionDetailsPage from '../components/TransactionDetailsPage'; 

const DashBoardRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<ProtectedRoutes/>}>
                <Route path='/' element={<DashboardPage/>}>
                    <Route index element={<Navigate to='overview'/>}/>
                    <Route path='overview' element={<Overview />}/>
                    <Route path='giftcards' element={<GiftCardPage/>} />
                    <Route path='wallet' element={<WalletPage/>} />
                    <Route path='crypto' element={<CryptoTradePage/>} />
                    <Route path='transactions' element={<TransactionsPage/>} />
                    <Route path='transactions/:id' element={<TransactionDetailsPage/>} />
                    <Route path='messages/:chat_id' element={<MessagesPage/>} />
                    <Route path='profile' element={<ProfilePage/>}>
                        <Route index element={<Navigate to='bio'/>}/>
                        <Route path='bio' element={<BioForm/>} />
                        <Route path='account' element={<AccountTab/>} />
                        <Route path='security'>
                            <Route index element={<SecurityTab/>}/>
                            <Route path='change-password' element={<ChangePassword/>}/>
                            <Route path='change-pin' element={<ChangePin/>}/>
                            <Route path='reset-pin' element={<ResetPin/>}/>
                            <Route path='set-2fa' element={<Set2Factor/>}/>
                        </Route>
                        <Route path='referrals' element={<ComingSoonPage/>} />
                        <Route path='*' element={<NotFound/>} />
                    </Route>
                    {/* any unimplemented routes would render Overview component for now hence the "*" */}
                    <Route path='*' element={<NotFound/>} />
                </Route>
            </Route>
        </Routes>
    )
}

export default DashBoardRoutes;