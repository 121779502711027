import React from "react";
import { ProfileBalance } from "../ProfileBalance";
import { Bars } from "react-loader-spinner";
import MobileHeader from "../MobileHeader";

const SelectGiftCard = ({ giftcards, startTransaction }) => {
  return (
    <div className="mx-5 lg:mx-10">
      <div className="block lg:hidden">
        <MobileHeader />
      </div>

      {/* Desktop Table: Visible on small screens and above (768px and more) */}
      <div className="hidden lg:block ">
        <div className="  flex justify-between items-center py-8 mx-auto">
          <p className=" font-bold font-Space-Grotesk">
            Kindly Select The Type of Giftcard{" "}
          </p>

          <ProfileBalance />
        </div>
      </div>

      <p className=" block lg:hidden py-4 font-bold font-Space-Grotesk">
        Kindly Select The Type of Giftcard{" "}
      </p>

      <div className=" grid gap-3 lg:grid-cols-4 md:grid-cols-3 grid-cols-1 p-6 border border-solid border-black rounded-md mx-auto">
        {giftcards.length > 0 ? (
          giftcards.map((value, index) => (
            <div
              key={index}
              className="w-full cursor-pointer rounded-xl"
              onClick={() => startTransaction(value)}
            >
              <img
                className="h-40 w-full object-inherit rounded-xl"
                src={value?.picture_url}
                alt={value?.name}
              />
            </div>
          ))
        ) : (
          <div className=" shadow-lg z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Bars
              height="100"
              width="300"
              color="#FFBF00"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClassName=" mx-auto"
              visible={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectGiftCard;
