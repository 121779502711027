import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ComingSoonPage = () => {
    const [dice, setDice] =  useState(7);
    const dices = ["&#9856;", "&#9857;", "&#9858;", "&#9859;", "&#9860;", "&#9861;"]
    useEffect(() => {
        let intervalId = null;

    if ((dice <= 7) && (dice > 0)) {
      intervalId = setInterval(() => {
        if (dice <= 1) {
            setDice(7)
        }
        setDice((prevDice) => prevDice - 1);
      }, 1000);
    }
    // if (dice === 0) {
    //     intervalId = setInterval(() => {
    //       setDice((prevDice) => prevDice + 1);
    //     }, 1000);
    //   }

    return () => {
      clearInterval(intervalId);
    };
    }, [dice])
    return (
        <div className="flex items-center justify-center transition-all ease-in-out w-full h-full">
            <div className="px-4 lg:py-12">
                <div className="lg:gap-4 lg:flex">
                    <div className="flex flex-col items-center justify-center md:py-24 lg:py-32" >
                        <h1 dangerouslySetInnerHTML={{ __html: dices[dice-1] }} className="font-bold text-blue-600 text-9xl"></h1>
                        <p className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl" >
                        Page <span className="text-yellow-500">Under Construction</span>
                        </p>
                        <p className="mb-8 text-center text-gray-500 md:text-lg">
                        This page is coming soon
                        </p>
                        <Link to="/dashboard"
                            className="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100">Go home</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComingSoonPage;

