export const rootLinks = {
    signin_link: '/auth/signin',
    signup_link: '/auth/signup',
    forgot_password: '/auth/forgot-password',
    reset_password: '/auth/reset-password',
    dashboard: '/dashboard',
    rates: '/rates',
    about: '/about',
    giftcards: '/giftcards',
    crypto: '/crypto',
    faq: '/faq'
}

export const BASE_URL =  "https://aphacrunch-api.onrender.com";


//export const BASE_URL = 'https://aphacrunch-api.onrender.com'http://192.168.1.41:5003/


export const MaraPaymentUrl = "https://checkout.marasoftpay.live/initiate_transaction"
//auth routes

export const GET_USER_BY_EMAIL = `${BASE_URL}/auth/email`;

export const LOGIN_URL = `${BASE_URL}/auth/login`;

export const OTP_LOGIN_URL = `${BASE_URL}/auth/2fa-login`;

export const SET_2FACTOR = `${BASE_URL}/auth/set-2fa`;

export const SIGNUP_URL = `${BASE_URL}/auth/signup`;

export const CONFIRM_EMAIL_URL = `${BASE_URL}/auth/confirm-email`;

export const REQUEST_PASSWORD_CHANGE_URL = `${BASE_URL}/auth/request-password-change`;

export const REQUEST_OTP = `${BASE_URL}/auth/request-otp`;

export const PASSWORD_RESET_URL = `${BASE_URL}/auth/reset-password`;

export const CHANGE_PASSWORD = `${BASE_URL}/auth/change-password`;


//user routes

export const GET_USER_BY_ID = `${BASE_URL}/user/`;

export const UPDATE_USER = `${BASE_URL}/user/`;

export const DELETE_USER_PICTURE = `${BASE_URL}/user/picture/`;

export const GET_USERS = `${BASE_URL}/user/users`;

export const BASIC_KYC = `${BASE_URL}/user/kyc/basic`;

export const BIOMETRIC_KYC = `${BASE_URL}/user/kyc/biometric`;

//giftcard routes

export const GET_GIFTCARD = `${BASE_URL}/giftcard/giftcards`;

export const GET_GIFTCARD_RATES = `${BASE_URL}/giftcard/rates/`;

export const CREATE_GIFTCARD_TRANSACTION = `${BASE_URL}/giftcard/start-transaction`;

export const APPROVE_GIFTCARD_TRANSACTION_CARD = `${BASE_URL}/giftcard/transaction/approve-card/`; // /transaction/approve-card/:id,  patch request

//giftcard rate routes

export const GET_SINGLE_GIFTCARD_RATES = `${BASE_URL}/giftcard/rates/`;

export const GET_GIFTCARD_CHAT_ID = `${BASE_URL}/message/giftcard/chat/`

//image routes

export const UPLOAD_IMAGE = `${BASE_URL}/image/upload`;

export const DELETE_IMAGE = `${BASE_URL}/image/delete`;

//wallet routes

export const CREATE_WALLET = `${BASE_URL}/auth/wallet/create`;

export const GET_SUPPORTED_BANKS = `${BASE_URL}/wallet/banks`;

export const PAY_TO_BANK = `${BASE_URL}/wallet/pay-to-bank`;

export const CHECK_BANKNUMBER = `${BASE_URL}/wallet/check-bank`;


//message routes
export const START_USER_CHATS = `${BASE_URL}/message/start-chat/`;// add userId to the end

export const GET_USER_CHAT = `${BASE_URL}/message/chat/`;

export const GET_USER_CHAT_BY_USERID = `${BASE_URL}/message/user-chat/`;// add userId to the end

export const GET_TRANSACTION_MESSAGES = `${BASE_URL}/message/user-chat-messages/`;// add userId to the end

export const SEND_MESSAGE = `${BASE_URL}/message/send-message`;


//transaction routes

export const GET_USER_TRANSACTIONS = `${BASE_URL}/transaction/transactions/`; // add userId to the end

export const GENERATE_PAYMENT_LINK = `${BASE_URL}/transaction/get-payment-link`;

//wallet routes

export const GET_WALLET_BY_ID = `${BASE_URL}/wallet/`;

export const GET_USER_WALLETS = `${BASE_URL}/wallet/wallets/`;// add userId to the end

export const CHANGE_WALLET_PIN = `${BASE_URL}/wallet/change-pin`;

export const RESET_WALLET_PIN = `${BASE_URL}/auth/reset-pin`;

export const CREDIT_WALLET = `${BASE_URL}/wallet/credit`;

export const DEBIT_WALLET = `${BASE_URL}/wallet/debit`;

export const WALLET_TRANFER = `${BASE_URL}/wallet/transfer`;

export const CHECK_WALLET_PIN = `${BASE_URL}/wallet/check-pin`;

export const GET_BROADCAST = `${BASE_URL}/broadcast/active`;

