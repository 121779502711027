import React, { useState, useEffect } from 'react';
import { requestOtp } from '../../hooks/services';
import { Bar } from 'react-chartjs-2';
import { useUserContext } from '../../context/UserContext';
import { useAuthContext } from '../../context/AuthContext';

const TimerRetry = () => {
    const { otpExpiration } = useAuthContext();
    const { user } = useUserContext();
    const expire = Math.floor((new Date(otpExpiration).getTime() - Date.now()) / 1000) ?? 120
  const [timer, setTimer] = useState(expire);
  const [loading, setLoading] = useState(false);
  const requestNewOtp = (e) =>{
    e.preventDefault();
    setLoading(true)
    requestOtp(user?.email).then(result=>{
      const newExpire = Math.floor((new Date(result.data.expiresIn).getTime() - Date.now()) / 1000) ?? 120
      setTimer(newExpire)
    }).catch(error=>{
      console.log(error)
    });
    setLoading(false)
  }

  useEffect(() => {
    let intervalId = null;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60).toString().padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <div className=' flex gap-2 mx-auto justify-center font-sans'>
      <p>Expires In: </p>
      {timer > 0 ?
        <div style={{color: timer < ((expire/100) * 20) ? "red" : "green"}}>{formatTime(timer)}</div>
        :
        <p className=' underline cursor-pointer text-green-700' onClick={requestNewOtp}>Resend OTP</p>
      }
      {loading && <div className=''>
          <Bar
            height="20"
            width="30"
            color="#FFBF00"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClassName=" mx-auto"
            visible={true}
            />
        </div>}
    </div>
  );
};

export default TimerRetry;
