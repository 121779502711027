import React, { useRef, useState } from "react";
import axios from "axios";
import level2 from "../../assets/level2.svg";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useUserContext } from "../../context/UserContext";
import { useAuthContext } from "../../context/AuthContext";
import { Bars } from 'react-loader-spinner';
import {BASIC_KYC} from '../../constants/links'

const KycDob = ({ goToNextLevel }) => {
  const ref = useRef(null);
  const { token } = useAuthContext();
  const [inputType, setInputType] = useState("text");
  const { user } = useUserContext();
  const [dob, setDob] = useState("");
  const [bvn, setBvn] = useState("");
  const [error , setError] = useState('')
  const [isLoading , setIsLoading] = useState(false)


  const handleInputChange = (event) => {
    setError('')
    setDob(event.target.value);
  };

  const handleBVN = (event) => {
    setError('')
    setBvn(event.target.value)
  };


  const BasicKyc = async (payload) => {
    setIsLoading(true)
    try {
      const response = await axios.post(`${BASIC_KYC}/${user._id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token.token}`,
          },
        }
      );

      console.log(response.data.data)

      if (response.data.data.ResultText === "Exact Match") {
         goToNextLevel()
      }
      else {
        setError("There seems to be an issue with your details , Please confirm your profile again.")
      }
    
    } catch (error) {
      setError('There seems to be an Issue with connnectivity')
    }
    finally {
      setIsLoading(false); 
    }
  };

  function splitName(fullName) {
    const parts = fullName.trim().split(" ");

    let firstName = parts[0];
    let lastName = parts.length > 1 ? parts.slice(1).join(" ") : "";

    return { firstName, lastName };
  }

  const prepareJsonPayload = (userData) => {
    return {
      firstName: splitName(userData.fullName).firstName,
      lastName: splitName(userData.fullName).lastName,
      dob: dob,
      gender: userData.sex.charAt(0),
      bvn: bvn,
      id_type: "BVN",
      phone_number: userData.phoneNumber,
    };
  };

  const handleFocus = () => {
    setInputType("date");
  };

  const handleBlur = () => {
    if (!ref.current.value) {
      setInputType("text");
    }
  };


  const handleSubmit = async (userData) => {
    const jsonPayload = prepareJsonPayload(userData);
    await BasicKyc(jsonPayload); 
  };

  return (
    <div className="bg-light-grey mt-12 px-6 py-10 rounded-xl lg:mr-14">
      {isLoading && (
        <div className='bg-black opacity-50 absolute flex justify-center items-center w-screen h-screen z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <Bars
            height='100'
            width='300'
            color='#FFBF00'
            ariaLabel='bars-loading'
            wrapperStyle={{}}
            wrapperClassName=' mx-auto'
            visible={true}
          />
        </div>
      )}
      <div className="flex justify-between items-center">
        <h1 className="font-Space-Grotesk text-xl font-bold">Level 2</h1>
        <img className="w-1/6 h-1/6" src={level2} alt="level 2 progress" />
      </div>
      <p className="py-7 lg:w-3/4 w-full font-inter text-md capitalize ">
        kindly provide us more detail about yourself so that we can help keep
        your account secure and also for compliance related purposes{" "}
      </p>

      <p className="text-red-500 capitalize lg:text-base text-sm">
        Your Date Of Birth as it is on your BVN
      </p>

      <div className="lg:w-3/6 py-8">
        <input
          ref={ref}
          type={inputType}
          name="date"
          id="date"
          value={dob}
          className="block w-full rounded-md border-0 py-2 pl-7 pr-7 my-7 text-gray-900 placeholder:text-black focus:ring-1 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 text-md font-inter font-semibold"
          placeholder="Date Of Birth"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
        />

        <input
          type="number"
          name="bvn"
          id="bvn"
          value={bvn}
          className="block w-full rounded-md border-0 py-2 pl-7 pr-7 text-gray-900 placeholder:text-black focus:ring-1 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 text-md font-inter font-semibold"
          placeholder="Bank Verification Number (BVN)"
          onChange={handleBVN}
        />
      </div>

      <div className="my-5">
        <p className="text-red-500 capitalize">{error}</p>
      </div>

      <div className="flex items-center ">
        <FaRegQuestionCircle color="green-600" />
        {/* eslint-disable-next-line */}
        <a href="#" className="text-green-600 pl-2 font-inter capitalize">
          What do you need my BVN for ?
        </a>
      </div>
      
      <div className="flex justify-end mt-10">
        <button
          //onClick={goToNextLevel}
          onClick={() => handleSubmit(user)}
          className="bg-black text-white font-Space-Grotesk px-10 py-3 rounded-lg "
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default KycDob;
