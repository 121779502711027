import React, { useEffect, useState } from "react";
//import { RiArrowDownSFill } from "react-icons/ri";
import { Link } from "react-router-dom";

import profile_image_placeholder from "../assets/profile_image.png";
import DashboardActionBtn from "./buttons/DashboardActionBtn";
import withdraw_icon from "../assets/witdraw_icon.svg";
import deposit_icon from "../assets/deposit_icon.svg";
import winged_bitcoin from "../assets/winged_bitcoin.svg";
import { ReactComponent as MapWhite } from "../assets/map_white.svg";
import c_coin from "../assets/c_coin.svg";
import BarChart from "./BarChart";
import StatisticsOption from "./StatisticsOption";
import { BalanceDropDown } from "./BalanceDropDown";
import { useUserContext } from "../context/UserContext";
import { QuoteMapBanner } from "./QuoteMapBanner";
import RecentTransactions from "./RecentTransactions";
import { toast } from "react-toastify";
import { useAuthContext } from "../context/AuthContext";
import { getTransactions } from "../hooks/services";
import Broadcast from "./Broadcast";

const Overview = () => {
  // const [profileImage, setProfilePic] = useState(profile_image_placeholder);
  const { user, selectedWallet } = useUserContext();
  const cardtransactionstyle =
    " text-white text-center text-2xs w-1/2 hover:bg-gray-900 bg-black px-4 py-2";
  const user_actions = [
    { name: "Deposit", url: "/dashboard/wallet", icon: deposit_icon },
    { name: "Withdraw", url: "/dashboard/wallet", icon: withdraw_icon },
    { name: "Sell Crypto", url: "/dashboard/crypto", icon: winged_bitcoin },
    { name: "Buy Crypto", url: "/dashboard/crypto", icon: c_coin },
  ];

  const [state, setState] = useState({
    recentTransactions: [],
    allTransactions: [],
  });
  const { allTransactions, recentTransactions } = state;
  const { token } = useAuthContext();

  useEffect(() => {
    // Ensure all required information is available
    const userId = user?._id;
    const authToken = token?.token;
    if (userId && authToken) {
      getTransactions(userId, authToken)
        .then((res) => {
          const transactions = res.data.data.all; // Directly use the data
          setState((prevState) => ({
            ...prevState,
            allTransactions: transactions,
            recentTransactions: transactions.slice(0, 10),
          }));
        })
        .catch(() => {
          toast.error("Unable to fetch transactions. Please try again later."); // Using react-toastify to show the error
        });
    }
  }, [user?._id, token?.token]);

  return (
    <>
      <div className="lg:border-b border-light-grey">
        <div className="flex justify-between mt-5 mx-10 ">
          <h1 className=" hidden md:block font-Space-Grotesk font-bold text-lg p-2">
            Dashboard
          </h1>
          <div className="hidden md:hidden lg:flex space-x-1 items-center cursor-pointer p-2 mb-4">
            <p className="font-Montserrat font-semibold text-md md:text-lg ">
              Balance :
            </p>
            <BalanceDropDown />
            {/* <div className=" text-yellow-500 text-xl">
              <RiArrowDownSFill />
            </div> */}
          </div>
        </div>
      </div>
      <Broadcast />
      <div className=" flex justify-between lg:mr-10">
        <div className=" w-full lg:mx-10 mx-7 ">
          <div className=" md:pl-6 lg:mt-6 mt-3">
            <div className=" flex space-x-4 justify-between items-center">
              <div className=" flex space-x-4 items-center">
                <div className=" w-14">
                  <img
                    src={
                      user?.profilePicture_url
                        ? user?.profilePicture_url
                        : profile_image_placeholder
                    }
                    alt="profile"
                  />
                </div>
                <div className="font-Space-Grotesk">
                  <p className=" text-gray-600 font-inter text-xs md:text-base">
                    <span className="text-xs font-bold">Hello,</span>{" "}
                    <span className=" text-yellow-500 font-semibold">
                      {user?.fullName}
                    </span>
                  </p>
                  <p className=" text-xs font-semibold py-1.5">
                    Welcome to your Dashboard
                  </p>
                </div>
              </div>
            </div>

            <div className="lg:hidden mt-7 flex flex-col items-center justify-center">
              <p className="text-center text-base pr-4">Wallet Balance</p>
              <BalanceDropDown />
            </div>

            <div className="grid grid-cols-4 gap-5 mt-6 mb-8  w-full">
              {user_actions.map((act, index) => (
                <DashboardActionBtn
                  name={act.name}
                  icon={act.icon}
                  url={act.url}
                  key={index}
                />
              ))}
            </div>

            <div>
              <div className="flex justify-between md:hidden lg:hidden bg-black text-white w-full py-7 px-6 rounded-xl">
                <p className="w-1/2 text-base  text-left font-Space-Grotesk">
                  Be limitless, Get a secure wallet address in{" "}
                  <span className=" text-yellow-200">5 seconds</span>
                </p>
                <div className=" w-full">
                  <MapWhite width="100%" height="100%" />
                </div>
              </div>
            </div>

            <div className="lg:block hidden">
              <BarChart />
            </div>

            <div className="lg:hidden  block "></div>
            <RecentTransactions transactions={recentTransactions} />
          </div>
        </div>
        <div className=" flex flex-col">
          <div className=" hidden lg:block bg-gold-card bg-center bg-contain bg-no-repeat w-60 h-44 pt-8 px-4">
            <p className=" text-sm font-bold text-gray-600">Wallet Balance</p>
            <p className=" text-left font-semibold font-Space-Grotesk text-3xl mt-4 mb-2">
              {selectedWallet?.currency?.symbol}{" "}
              {parseFloat(selectedWallet?.balance).toLocaleString("en-US", {
                currency: selectedWallet?.currency?.code,
              })}{" "}
            </p>
            <div className=" flex space-x-1 w-full mx-auto justify-between mb-5">
              <Link
                to={"/dashboard/wallet"}
                className={`${cardtransactionstyle} rounded-l-md`}
              >
                <p>WITHDRAW</p>
              </Link>

              <Link
                to={"/dashboard/wallet"}
                className={`${cardtransactionstyle} rounded-r-md`}
              >
                <p>FUND</p>
              </Link>
            </div>
          </div>
          <QuoteMapBanner />

          <StatisticsOption transactions={allTransactions} />
        </div>
      </div>
    </>
  );
};

export default Overview;
