import React from "react";
import "./App.css";
import IndexRoutes from "./routes/indexRoutes";
import { AuthContextProvider } from "./context/AuthContext";
import { UserContextProvider } from "./context/UserContext";
import { TransactionContextProvider } from "./context/TransactionContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <GoogleOAuthProvider clientId="842287424040-2b9vc8gv31d2l3p7gabc3mdg0u7050vk.apps.googleusercontent.com">
      <AuthContextProvider>
        <UserContextProvider>
          <TransactionContextProvider>
            <IndexRoutes />
          </TransactionContextProvider>
        </UserContextProvider>
      </AuthContextProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
