import React from 'react';
import { ReactComponent as SuccessTick } from '../../assets/success_tick.svg';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { getWallets } from '../../hooks/services';
import { useUserContext } from '../../context/UserContext';
import { useAuthContext } from '../../context/AuthContext';

// eslint-disable-next-line no-unused-vars
export const SuccessOrFail = ({success, account, tran_ref}) => {
    const {user, setWallets} = useUserContext()
    const {token} = useAuthContext();
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const closeModal = () =>{
        setShow(false);
        navigate('');
        getWallets(user?._id, token?.token).then((res)=>{
            console.log(res.data?.data);
            setWallets(res.data?.data)
        }).catch((err)=>{ console.log(err);})
    }
    return (
        <div>
            <dialog open={show} className=' shadow-slate-400 shadow-lg top-18 lg:w-1/4 w-2/3 z-50'>
                <div className=' flex flex-col justify-center items-center'>
                    <div className=' flex w-full justify-end'>
                        <div onClick={()=> closeModal()}><AiOutlineClose className=' cursor-pointer' /></div>
                    </div>
                    <SuccessTick width={100} />
                    <p className=' uppercase text-green-700 font-semibold'>{success}</p>
                    <div className=' cursor-pointer border border-yellow-400 text-center hover:border-slate-800 hover:bg-yellow-400 py-2 px-8 rounded-md' onClick={()=> closeModal()}>Close</div>
                </div>

            </dialog>
        </div>
    )
}
