import React from "react";
import { Link } from "react-router-dom";
// import { Outlet, NavLink } from "react-router-dom";
import hand_bitcoin from "../assets/hand_bitcoin.svg";
import grey_suitcase from "../assets/grey_suitcase.svg";
import {
  giftcardSteps,
  product_name,
  trans_card_details,
} from "../constants/details";
import ProcessSteps from "../components/ProcessSteps";
import FeaturesCard from "../components/FeaturesCard";
import { rootLinks } from "../constants/links";
import BlackBtn from "../components/buttons/BlackBtn";
import group_phone_coins from "../assets/group_phone_coin.svg";
import { Animated } from "react-animated-css";

const HomePage = () => {
  return (
    <div className="flex flex-col">
      {/* Features */}
      <section className="">
        <div className="relative flex lg:flex-row flex-col">
          <div className="lg:w-3/5 ">
            <Animated animationIn="fadeInDown" isVisible={true}>
              <h2 className="lg:pl-20 lg:pt-32 whitespace-normal font-bold lg:leading-12 text-3xl md:text-4xl lg:text-5xl my-8 font-Space-Grotesk px-8 md:px-24 text-left block">
                Your single <br /> destination for{" "}
                <span className="text-transparent bg-gradient-to-r from-gold-light to-gold bg-clip-text">
                  giftcard
                </span>{" "}
                <br /> and{" "}
                <span className="text-transparent bg-gradient-to-r from-dark-gold-light to-dark-gold bg-clip-text">
                  crypto exchange
                </span>
              </h2>
            </Animated>
            <p className="lg:pl-20 font-inter w-4/5 lg:w-full mb-8 lg:text-xl text-left mx-auto lg:mx-0">
              An awesome place to trade securely with no worries or delay. It is
              perfectly built for you.
            </p>
            <div className="lg:pl-20 hidden lg:flex justify-start items-center space-x-4  mx-0">
              <BlackBtn url={rootLinks.dashboard} text="Trade With Us" />
              <Link to={rootLinks.rates}>
                <div className=" font-Space-Grotesk flex justify-center items-center bg-white hover:bg-black hover:text-white px-8 py-3 border border-black w-max rounded-2xl">
                  <p>
                    Check Our Rates{" "}
                    <span className=" transform rotate-45 inline-block text-opacity-0 stroke-1 stroke-black">
                      $
                    </span>
                  </p>
                </div>
              </Link>
            </div>
            <div className="lg:hidden mx-auto">
              <Link to={rootLinks.signup_link}>
                <div className=" font-Space-Grotesk flex justify-center items-center bg-black text-white hover:bg-white hover:text-black px-8 py-4 border mx-auto border-black w-4/5 my-4 rounded-2xl">
                  <p>Trade With Us</p>
                </div>
              </Link>
              <Link to={rootLinks.rates}>
                <div className=" font-Space-Grotesk flex justify-center items-center bg-white hover:bg-black hover:text-white px-8 py-4 border mx-auto border-black w-4/5 my-4 rounded-2xl">
                  <p>
                    Check Our Rates{" "}
                    <span className=" transform rotate-45 inline-block text-opacity-0 stroke-1 stroke-black">
                      $
                    </span>
                  </p>
                </div>
              </Link>
            </div>
          </div>


          <div className="lg:w-2/5">
            <Animated animationIn="bounceInRight" isVisible={true}>
              <div className="lg:ml-[-70px]">

                <img
                  src={group_phone_coins}
                  className=" w-full h-full "
                  alt="phone with bitcoin, ethereum, litecoin, and other shapes around"
                />

              </div></Animated>
          </div>

        </div>
        <div className="">
          <h2 className="tracking-tighter lg:leading-[60px] text-xl md:text-3xl lg:text-5xl text-center font-bold font-Space-Grotesk mb-10">
            We&apos;re bringing back the <br /> excitement,{" "}
            <span className="text-transparent bg-gradient-to-r from-gold-light to-gold bg-clip-text">
              Be Limitless
            </span>
          </h2>
          <p className=" lg:w-3/5 w-4/5 mx-auto font-inter  text-base lg:text-2xl text-center">
            We recognise your broader financial needs. Further possibilities can be explored here. You may exchange gift cards, pay your utility bill, and quickly recharge your phone.
          </p>

          <div className=" mt-20 grid gap-4 grid-cols desktop:grid desktop:grid-cols-2 ">
            {trans_card_details ? (
              trans_card_details.map((details, index) => (
                <div key={index}>
                  <FeaturesCard
                    color={details.color}
                    title={details.title}
                    description={details.description}
                    image={details.image}
                  />
                </div>
              ))
            ) : (
              <p>loading...</p>
            )}
          </div>
        </div>
      </section>
      {/* Services */}

      <section
        className={` flex flex-col-reverse lg:flex lg:flex-row justify-start items-center mx-auto py-24 bg-gray-100 w-full pt-4`}
      >
        <div className=" lg:w-3/5">
          <img
            className="lg:w-full 2xl:w-11/12 -ml-1"
            src={hand_bitcoin}
            alt="hand holding bitcoin"
          />
        </div>
        <div className=" w-10/12 lg:w-4/5 mb-4">
          <p className=" font-Space-Grotesk text-left md:text-center text-3xl lg:text-5xl font-bold my-16 text-pri-grey">
            Get started with <br /> {product_name} in {giftcardSteps.length}{" "}
            easy steps.
          </p>
          {giftcardSteps.map((step, index) => (
            <div key={index}>
              <ProcessSteps
                position={step.position}
                description={step.description}
                title={step.title}
                icon={grey_suitcase}
              />
            </div>
          ))}
          <div className="md:flex md:justify-center">
            <BlackBtn url={rootLinks.dashboard} text="Trade With Us" />
          </div>


        </div>
      </section>
    </div>
  );
};

export default HomePage;
