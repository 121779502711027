import React, { useState } from 'react'
import Toggle from '../../buttons/Toggle';
import { useUserContext } from '../../../context/UserContext';
import { requestOtp, set2FA } from '../../../hooks/services';
import { Bars } from 'react-loader-spinner';
import { PasswordInput } from '../PasswordInput';
import TimerRetry from '../../Modals/TimerRetry';
import { errStyle } from '../../../constants/details';
import { useAuthContext } from '../../../context/AuthContext';

export const Set2Factor = () => {
    const { user, setUser } = useUserContext();
    const { setOtpExpiration } = useAuthContext();
    const [enabled, setEnabled] = useState(user?.twoFactorAuth?.enabled);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false);

    const setting2fa = ( state ) => {
        setEnabled(state);
        setMessage('')
        setError(false)
        setErrorMessage('')
        setLoading(true)
        requestOtp(user?.email).then(result=>{
          setOtpExpiration(result.data?.expiresIn)
          setMessage(result.data?.message)
          setOtpSent(true)
        }).catch(error=>{
            console.log(error)
            setError(true)
            setErrorMessage(error.response?.data?.message)
        });
        setLoading(false)
    }
    function handleEnable() {
        setLoading(true);
        if (!otp) {
            return
        }
        const data = {email: user?.email, otp, state: enabled}
        set2FA(data).then(result=>{
            setUser(result.data?.data);
            setSuccess(result.data.success);
            setMessage(result.data?.message)
        }).catch(error=>{
            console.log(error)
            setError(true)
            setErrorMessage(error.response?.data?.message)
        }).finally(()=> {
            setLoading(false)
            setOtpSent(false)
        })
    }
    return (
        <div className=' flex flex-col justify-between w-full gap-8 px-8 py-2'>
            <div>
                <p className=' bg-pri-black-light p-4 text-sm w-2/3 mx-auto mb-4'>Enabling 2FA is a great way to protect your account from unauthorized access. If you are concerned about the security of your account, I recommend that you enable 2FA today.</p>
                <div className=' flex gap-2 justify-center items-center bg-pri-pink-light border border-orange-300 rounded-lg w-max mx-auto p-2 mb-4'>
                    <p>Enable 2 Factor Authentification</p><Toggle enabled={enabled} setEnabled={setting2fa} />
                </div>
                    {success && <p className=' text-green-500'>{message}</p>}
                    {error && <p className={errStyle}>{errorMessage}</p>}
                {(otpSent || error) &&
                <>
                    <p className=' text-green-500'>{message}</p>
                    <PasswordInput name={'Otp'} placeholder={'Enter OTP'} value={otp} onChange={setOtp} state={loading}/>
                    <TimerRetry/>
                </>}
            </div>

            <div className=' flex justify-end'>
            {loading? 
                    <div className=' w-max rounded-lg py-1 px-3 flex justify-center bg-black cursor-pointer'>
                        <Bars
                                height="20"
                                width="30"
                                color="#ffffff"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                                /> 
                        </div>
                    :
                <button className=' border px-3 py-1 bg-slate-900 rounded-md text-white disabled:bg-slate-600 disabled:text-slate-400' onClick={handleEnable} disabled={!otpSent}>Save</button>}
            </div>
        </div>
    )
}
