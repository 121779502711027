import React, { useEffect, useState } from 'react'
import { useUserContext } from '../../../context/UserContext';
import { getWallets } from '../../../hooks/services';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

export const SelectWallet = ({ setSelectedWallet }) => {
    const { wallets, setWallets, user } = useUserContext();
    const [selectedOption, setSelectedOption] = useState();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (wallets?.length < 1) {
          console.log(user);
          getWallets(user?._id).then(result=>{
            console.log(result);
            setWallets(()=> result.data)
          })
        }
      }, [wallets, setWallets, user])

    const handleOptionClick = (option) => {
        setSelectedWallet(option);
        setSelectedOption(option);
        setIsOpen(false);
      };
    return (
        <div>
            <h3 className=' font-Space-Grotesk'>Select Wallet</h3>
            <div className=" relative custom-dropdown mx-auto w-max rounded-md border border-yellow-400 mb-4 cursor-pointer">
                    <div className="selected-option font-bold font-Space-Grotesk text-center flex justify-center gap-2 items-center p-2" onClick={() => setIsOpen(!isOpen)}>
                        <p className=' text-2xl w-1/3 text-center text-green-400'>{selectedOption?.currency.symbol ?? " - "}</p>
                        <p>{ selectedOption?.wallet_number ?? "select"}</p> {isOpen? <AiFillCaretUp/> : <AiFillCaretDown/>}
                    </div>
                    {isOpen && (
                        <ul className=" absolute bg-pri-pink options-list text-left py-4 font-bold font-Space-Grotesk rounded-md border border-yellow-400 w-full">
                        {wallets?.map((wallet, index) => (
                          <div key={index}>
                            <li
                              className={`${wallet === selectedOption ? 'selected ' : ''} flex gap-2 items-center justify-start cursor-pointer hover:bg-yellow-300 px-4`}
                              onClick={() => handleOptionClick(wallet)}
                            >
                              <p className=' text-2xl w-1/3 text-center text-green-400'>{wallet.currency.symbol}</p>
                              <div className='flex flex-col justify-start w-2/3'>
                                <p className=' text-xs'>{wallet.currency.code}</p>
                                <p className=' text-sm font-bold'>{wallet.wallet_number}</p>
                              </div>
                            </li>
                            <hr className=' text-black'/>
                          </div>
                        ))}
                        </ul>
                    )}
              </div>
        </div>
    )
}
