import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import GiftcardsCard from '../components/Giftcards/GiftcardsCard';
import { cryptocard_card_details, giftcard_card_details, ratecard_card_details } from '../constants/details';
import DashBoardRoutes from './DashBoardRoutes.js';
import AuthRoutes from './AuthRoutes';
import { AboutUsPage } from '../components/About/AboutUsPage';
import { IndexPage } from '../pages/IndexPage';
import { RatesPage } from '../components/Rates/RatesPage';
import { HomeGiftcardPage } from '../components/Giftcards/HomeGiftcardPage';
import FAQ from '../pages/FAQ.js';
import { PrivacyPolicyPage } from '../pages/PrivacyPolicyPage.js';
import { T_and_CPage } from '../pages/T_and_CPage.js';
import NotFound from '../pages/NotFound.js';

const IndexRoutes = () => {

    return (
        <Router>
            <Routes>
                <Route path='/' element={<IndexPage />}>
                    <Route path='/' element={<HomePage />}>
                        {/* making sure the home page defaults to crypto in the services section */}
                        <Route index element={<Navigate to='cryptocurrency-card' />} />
                        <Route path='giftcard-card' element={<GiftcardsCard
                            list={giftcard_card_details.list}
                            title={giftcard_card_details.title}
                            image={giftcard_card_details.image}
                            description={giftcard_card_details.description} />} />
                        <Route path='cryptocurrency-card' element={<GiftcardsCard
                            list={cryptocard_card_details.list}
                            title={cryptocard_card_details.title}
                            image={cryptocard_card_details.image}
                            description={cryptocard_card_details.description} />} />
                        <Route path='rates-card' element={<GiftcardsCard
                            list={ratecard_card_details.list}
                            title={ratecard_card_details.title}
                            image={ratecard_card_details.image}
                            description={ratecard_card_details.description} />} />
                    </Route>
                    <Route path='/about' element={<AboutUsPage />} />
                    <Route path='/rates' element={<RatesPage />} />
                    <Route path='/crypto' element={<HomePage />} />
                    <Route path='/giftcards' element={<HomeGiftcardPage />} />
                    <Route path='/faq' element={<FAQ />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicyPage/>} />
                    <Route path='/terms-and-conditions' element={<T_and_CPage/>} />
                    <Route path='*' element={<NotFound/>} />
                </Route>
                <Route path='/auth/*' element={<AuthRoutes />} />


                <Route path='/dashboard/*' element={<DashBoardRoutes />} />
            </Routes>
        </Router>
    );
}

export default IndexRoutes;
