import React from 'react'
import { CultureCard } from './CultureCard'
import staff_meeting from '../../assets/staff.png';
import WhiteBtn from '../buttons/WhiteBtn';

export const CultureSection = () => {
    return (
        <div className=' bg-black p-8 lg:p-16 rounded-lg my-4'>
            <h3 className=' text-2xl lg:text-4xl font-Space-Grotesk font-bold text-white'>The culture we live by</h3>
            <div className=' lg:flex items-start py-4'>
                <div className=' w-full lg:w-2/3 lg:grid lg:grid-cols-2'>
                    <CultureCard title={'Flexible and Understanding'} description={'An awesome place to trade securely with no worries or delay. It is perfectly built for you.'}/>
                    <CultureCard title={'Growth and Development'} description={'An awesome place to trade securely with no worries or delay. It is perfectly built for you.'}/>
                    <CultureCard title={'Open and Transparent'} description={'An awesome place to trade securely with no worries or delay. It is perfectly built for you.'}/>
                    <CultureCard title={'Helpful and Support'} description={'An awesome place to trade securely with no worries or delay. It is perfectly built for you.'}/>
                </div>
                <div className=' lg:w-1/3 mx-auto hover:border hover:border-pri-pink rounded-lg transition-all ease-in'>
                    <img src={staff_meeting} alt="staff in meeting" />
                </div>
            </div>
            <div className=' w-max mx-auto lg:mx-0'>
                <WhiteBtn url='/dashboard' text='Trade With Us'/>
            </div>
            
        </div>
    )
}
