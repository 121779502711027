import React from 'react'
import { Link } from 'react-router-dom'

export const SecurityActionCard = ({action, link, icon, description, title}) => {
    return (
        <div className='flex items-center gap-2 w-72 p-6 duration-100 transition-all hover:shadow-sm shadow-pri-grey  lg:mb-0 mb-4 border-2 border-solid border-black'>
            <div className=' w-28'>
                <img src={icon} alt={action} />
            </div>
            <div className=' text-left'>
                <p className=' font-bold text-sm text-pri-grey'>{title}</p>
                <p className=' text-3xs mb-2'>{description}</p>
                <Link to={link} className=' px-4 py-2 text-2xs hover:bg-black hover:text-white rounded-md font-bold border border-black' >{action}</Link>
            </div>
        </div>
    )
}
