import React from "react";
import success from "../../assets/success_gold.svg";
import { useUserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

const KycSuccess3 = () => {
    const {setShowKyc} = useUserContext()
    const navigate = useNavigate()

    const handleclick = () => {
      setShowKyc(false);
      navigate('/dashboard/overview');
    };
   
  return (
    <div>
      <div className="flex justify-center items-center">
        <img className="w-1/4 h-1/4" src={success} alt="success" />{" "}
      </div>
      <h2 className="text-center font-inter my-5 font-bold text-3xl">Success</h2>
      <p className="text-center font-inter my-5">
        Your Level 3 verification is Processing, Please check your Email for your Verification status
      </p>
      <div className="flex justify-center mt-10">
        <button
          onClick={handleclick}
          className="bg-black text-white font-Space-Grotesk px-10 py-3 rounded-lg "
        >
          Go Home
        </button>
      </div>

    </div>
  );
};

export default KycSuccess3;
