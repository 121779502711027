import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CgSpinner } from "react-icons/cg";
// import signin_bg from "../assets/signin_bg.png";
import { emailRegex, product_name } from "../constants/details";
//import LogoBtn from "../components/buttons/LogoBtn";
import axios from "axios";
import { LOGIN_URL, OTP_LOGIN_URL, rootLinks, BASE_URL } from "../constants/links";
import { Bars } from "react-loader-spinner";
import { InputOtp } from "../components/Modals/InputOtp";
import { useUserContext } from "../context/UserContext";
import { useAuthContext } from "../context/AuthContext";
import logo from "../assets/logo.svg";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from '@react-oauth/google';

const LoginPage = () => {
  const { setUser, setWallets, setSelectedWallet } = useUserContext();
  const { setToken, setOtpExpiration } = useAuthContext();
  const [validEmail, setValidEmai] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [inputOtpModal, setInputOtpModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const eyeStyle = " hover:bg-gray-200 p-3 cursor-pointer ";
  const errStyle = ` mx-auto -mt-4 text-red-500 py-2 font-sans`;

  const onEmailChange = (e) => {
    const emailValue = e.currentTarget.value;
    setEmail(e.target.value);
    if (emailRegex.test(emailValue)) {
      setValidEmai(true);
    } else {
      setValidEmai(false);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(LOGIN_URL, {
        email: email,
        password: password,
      })
      .then((res) => {
        setLoading(false);
        setError(false);
        if (res.data.is2FactorEnabled) {
          setInputOtpModal(true);
          setOtpExpiration(res.data.expiresIn);
          setUser(() => res.data.data);
        } else {
          const tokenData = {
            token: res?.data?.token,
            expiresIn: res?.data?.expiresIn,
          };

          const userData = res?.data?.data;

          setLoading(false);
          setToken(tokenData);
          setUser(userData);
          setWallets(res.data.wallets);
          setSelectedWallet(Array(...res.data.wallets)[0]);
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        if (err.message === "Network Error") {
          setErrorMessage(
            "Network Error, Please check your internet connection"
          );
        } else {
          setErrorMessage("Invalid Email or Password");
        }
      });
  }

  async function submitOtp(e) {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(OTP_LOGIN_URL, {
        email: email,
        otp: otp,
      })
      .then((res) => {
        setLoading(false);
        const tokenData = {
          token: res.data.token,
          expiresIn: res.data.expiresIn,
        };
        const userData = res.data.data;

        setToken(tokenData);
        setUser(userData);
        setWallets(res.data.wallets);
        setSelectedWallet(Array(...res.data.wallets)[0]);
        navigate("/dashboard");
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        if (err.message) {
          setErrorMessage(err.response.data.message);
        }
      });
  }

  const login = useGoogleLogin({
    onSuccess: codeResponse => {
      const code = codeResponse.code;
      axios
        .post(`${BASE_URL}/auth/google/login/callback`, {
          code: code,
        })
        .then((res) => {
          console.log(res);
          const tokenData = {
            token: res?.data?.token,
            expiresIn: res?.data?.expiresIn,
          };

          const userData = res?.data?.data;

          setLoading(false);
          setToken(tokenData);
          setUser(userData);
          setWallets(res.data.wallets);
          setSelectedWallet(Array(...res.data.wallets)[0]);
          navigate("/dashboard");
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
        if (err.message === "Network Error") {
          setErrorMessage(
            "Network Error, Please check your internet connection"
          );
        } else {
          setErrorMessage("Unable to login with Google");
        }
        });
    },
    flow: 'auth-code'
  });


  const handleLogin = (event) => {
    event.preventDefault(); // Prevent the default form submission
    setLoading(true);
    login(); // Call the Google login function

  };

  return (
    <div className="overflow-hidden">
      <div className=" flex justify-start items-center">
        {loading && (
          <div className="bg-black bg-cover opacity-60 fixed inset-0 flex justify-center items-center w-full h-screen z-50">
            <Bars
              height="100"
              width="300"
              color="#FFBF00"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClassName="mx-auto"
              visible={true}
            />
          </div>
        )}
        {inputOtpModal ? (
          <InputOtp
            loading={loading}
            handleSubmit={submitOtp}
            value={otp}
            setVisible={setInputOtpModal}
            setValue={setOtp}
            error={error}
            errorMessage={errorMessage}
          />
        ) : (
          <div className="flex-1 bg-auth-pattern bg-cover w-full md:w-7/12 h-screen md:mt-0">
            <div>
              <Link to="/">
                <div className="block ipad-portrait:block ipad-landscape:block lg:block cursor-pointer w-52 py-4 pl-10">
                  <img
                    src={logo}
                    alt={`${product_name} logo`}
                    className="w-4/5 md:w-4/5 lg:w-full"
                  />
                </div>
              </Link>
            </div>

            <div className="flex">
              <div className="w-1/3 hidden lg:flex justify-center flex-col pl-10">
                <h1 className="font-Smooch text-gold-light text-6xl leading-none">
                  Hello !
                </h1>
                <p className="font-bold text-4xl leading-1">
                  Login to your <br></br> Cambio Account
                </p>
              </div>

              <form className=" lg:mx-auto flex-1 flex flex-col items-center">
                <div className="bg-white lg:w-[45%] w-11/12 mt-7 rounded-3xl py-8">
                  <h2 className=" text-2xl md:text-2xl lg:leading-3 font-bold text-center text-black lg:mb-4 ">
                    Sign In
                  </h2>
                  <p className=" text-center text-xs text-pri-grey/60">
                    Welcome back to Cambio world!
                  </p>

                  <div className="lg:w-4/5 w-4/5 mx-auto pt-3">
                    <label htmlFor="email" className="text-base text-pri-grey">
                      Email <span className="text-red-500">*</span>
                    </label>

                    <div
                      className=" border-2 border-solid border-gray-500 rounded-lg overflow-hidden focus-within:border-gold  "
                      style={{
                        border:
                          !validEmail && email !== "" ? "1px solid red" : "",
                      }}
                    >
                      <input
                        type="email"
                        className=" px-4 py-2 w-full placeholder:text-black/50 placeholder:text-xs  focus:outline-none"
                        name="email"
                        pattern={emailRegex.source}
                        inputMode="email"
                        disabled={loading}
                        value={email}
                        onClick={() => setError("")}
                        onChange={(e) => onEmailChange(e)}
                        placeholder="Enter Your Email"
                        required
                      />
                    </div>
                  </div>

                  <div className="lg:w-4/5 w-4/5 mx-auto mt-4">
                    <label htmlFor="email" className="text-base text-pri-grey">
                      Password <span className="text-red-500">*</span>
                    </label>

                    <div className=" flex justify-between items-center border-2 border-solid border-black rounded-lg overflow-hidden  mb-2 focus-within:border-gold  ">
                      <input
                        type={show ? "text" : "password"}
                        name="password"
                        className=" px-4 py-2 w-full placeholder:text-black/50 placeholder:text-sm focus:outline-none "
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onClick={() => setError("")}
                        disabled={loading}
                        required
                        placeholder="Password"
                      />
                      {show ? (
                        <div className={eyeStyle}>
                          <AiOutlineEyeInvisible
                            onClick={() => setShow(!show)}
                          />
                        </div>
                      ) : (
                        <div className={eyeStyle}>
                          <AiOutlineEye onClick={() => setShow(!show)} />
                        </div>
                      )}
                    </div>
                    <p className="text-sm text-pri-grey/50 cursor-pointer text-right mb-4">
                      <Link to={rootLinks.forgot_password}>
                        Forgot password?
                      </Link>
                    </p>

                    {error && <p className={errStyle}>{errorMessage}</p>}
                    <button
                      onClick={(e) => handleSubmit(e)}
                      disabled={loading}
                      className={`text-white block ${
                        loading
                          ? "bg-gray-700 cursor-wait"
                          : "bg-black cursor-pointer"
                      }  rounded-lg px-6 py-3 w-full mx-auto mb-2 text-center flex items-center justify-center gap-5 font-semibold`}
                    >
                      <CgSpinner
                        size={30}
                        className={`${
                          loading ? "block animate-spin" : "hidden"
                        } `}
                      />{" "}
                      Sign In
                    </button>

                    <div className="relative flex items-center mb-2">
                      <div className="flex-grow border-t border-gray-500"></div>
                      <span className="flex-shrink mx-4 text-gray-500 font-sans">
                        OR
                      </span>
                      <div className="flex-grow border-t border-gray-500"></div>
                    </div>

                    <button
                      //disabled={true}
                      onClick={handleLogin}
                      className={`text-white block ${
                        loading
                          ? "bg-gray-100 cursor-wait"
                          : "bg-gray-700 cursor-pointer"
                      }  border border-solid border-gray-800 rounded-lg px-6 py-3 w-full mx-auto mb-4 text-center text-sm text-[#9B9B9B] flex items-center justify-center gap-5 disabled:text-black`}
                    >
                      <FcGoogle size={20} className="" />
                      Continue with Google
                    </button>

                    <p className=" text-center mb-6 text-sm text-[#9B9B9B]">
                      Are you new to {product_name}?{" "}
                      <Link to={rootLinks.signup_link}>
                        <span className=" cursor-pointer text-yellow-500">
                          Create an account
                        </span>
                      </Link>
                    </p>
                    {/* <p className=" text-xs text-center">
                      By continuing you agree to the{" "}
                      <Link to={"/privacy-policy"} className=" text-yellow-500">
                        Policy and Rules
                      </Link>
                    </p> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
