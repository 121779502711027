import React, { useEffect, useState } from 'react';
import rate_calculator_icon from '../../assets/rate_calculator_icon.png';
import { DropDownSelect } from './DropDownSelect';
import { getSingleGiftcardRates, getSupportedGiftcards } from '../../hooks/services';
import { AmountInput } from './AmountInput';
import { Animated } from "react-animated-css";

export const RateCalculator = () => {
    const [cardType, setCardType] = useState('');
    const [cardTypes, setCardTypes] = useState([]);
    const [currency, setCurrency] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [giftcards, setGiftcards] = useState([]);
    const [giftcardRates, setGiftcardRates] = useState([]);
    const [giftcardNames, setGiftcardNames] = useState([]);
    const [rate, setRate] = useState(0)
    const [amount, setAmount] = useState('');

    const onSelectGiftCard = (value)=> {
        const selectedGiftcard = giftcards?.find((c) => c.name === value)
        getSingleGiftcardRates(selectedGiftcard?._id).then(res=>{
            const result = res.data.data;
            setGiftcardRates(()=> result)
            setCurrencies(()=> Array(...new Set(result?.map((giftcardRate)=> giftcardRate?.currency?.code))))
            setCardTypes(()=> Array(...new Set(result?.map((giftcardRate)=> giftcardRate?.cardType))))
        }).catch(error=> {
            console.log(error);
        })
    }

    useEffect(() => {
        
        getSupportedGiftcards().then(res=>{
            const resCards = res.data.data;
            setGiftcards(()=> resCards)

            setGiftcardNames(()=> resCards.map((resCard)=> resCard.name))
        }).catch(error=> {
            console.log(error);
        })
    },[]);

    //Setting the rate
    useEffect(() => {
        const newRate = giftcardRates?.find((rate) => (rate?.currency?.code === currency) && (rate?.cardType === cardType) )?.rate;
        setRate(newRate);
    }, [rate,cardType,giftcardRates, currency, giftcards]);

    return (
        <div className=' flex justify-start'>
                <div className=' w-full lg:w-1/2 py-4'>
                <Animated animationIn="fadeInDown" isVisible={true}>
                    <h1 className=' font-Space-Grotesk font-bold text-3xl lg:text-6xl mb-8'>Rate Calculator</h1>
                    <form>
                        <div className=''>
                            <DropDownSelect options={giftcardNames} setValue={onSelectGiftCard} placeholder={'Select Card'}/>
                        </div>
                        <div className=' lg:flex lg:gap-1 justify-between'>
                            <div className=' lg:w-1/2'>
                                <DropDownSelect options={cardTypes} setValue={setCardType} placeholder={'Select Card Types'}/>
                            </div>
                            <div className=' lg:w-1/2'>
                                <DropDownSelect options={currencies} setValue={setCurrency} placeholder={'Select Currency'}/>
                            </div>
                        </div>
                        
                        <div>
                            <AmountInput name={'amount'} value={amount} onChange={setAmount} placeholder={'Enter Amount'}/>
                        </div>
                        <p className=' text-sm font-inter font-semibold text-slate-600 mt-8'>Rate <span>{rate ?? 0}/{currency ?? 'Currency'}</span></p>
                        <hr className=' border border-dotted border-yellow-400 mt-2 mb-8'/>
                        <div className=' flex justify-between'>
                            <p className=' text-lg font-inter font-semibold text-slate-600'>You Get:</p>
                            <p className=' text-lg font-inter font-semibold text-slate-600'>₦{isNaN(rate) || isNaN(amount) ? 0 : (rate * amount).toLocaleString('en-US', { currency: 'NGN' })}</p>
                        </div>
                        <hr className=' border border-solid border-yellow-100'/>
                    </form>
                </Animated>
                </div>
                <Animated animationIn="fadeInRight" animationInDelay={1000} isVisible={true}>
                <div className=' lg:block hidden w-96 ml-12'>
                    <img src={rate_calculator_icon} alt="calculator with stack of card coming out" />
                </div>
                </Animated>
            </div>
    )
}
