import React from 'react'
import { Link } from 'react-router-dom'

export const WalletFeatureCard = ({icon, url, name, title}) => {
    return (
        <Link to={url} className=' flex lg:flex-row relative items-center p-8 justify-center rounded-lg overflow-hidden w-56'>
            <div className=' absolute bg-black opacity-80 w-full h-full flex justify-center items-center z-10'>
                <p className=' text-white text-2xl font-bold'>{name}</p>
            </div>
            <div ><img className=' w-32 object-contain h-20' src={icon} alt={name} /></div>
            <p>{title}</p>
        </Link>
    )
}
