import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import { AmountInput } from '../Rates/AmountInput';
import { useUserContext } from '../../context/UserContext';
import { getBanks, sendToBank } from '../../hooks/services';
import { useAuthContext } from '../../context/AuthContext';
import { AccountNumberInput } from './AccountNumberInput';
import { paymentBtnStyle } from '../../constants/styles';
import { WithdrawReceipt } from './WithdrawReceipt';
import { Bars } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

export const Withdraw = () => {
    const {user} = useUserContext()
    const [ show, setShow] = useState(false);
    const [ loading, setLoading] = useState(false);
    const [ transaction, setTransaction ] = useState();
    const [ done, setDone] = useState();
    const [ error, setError] = useState();
    const [ showBankList, setShowBankList ] = useState(false);
    const [ amount, setAmount ] = useState(0);
    const [ banks, setBanks ] = useState([]);
    const [ hoverBank, setHoverBank ] = useState(0);
    const [ bankInput, setBankInput ] = useState('');
    const [ filteredBanks, setFilteredBanks ] = useState([]);
    const [ selectedBank, setSelectedBank ] = useState();
    const [ accountNumber, setAccountNumber] = useState('');
    const [ accountName, setAccountName ] = useState('');
    const { selectedWallet, setWallets } = useUserContext();
    const { token } = useAuthContext();
    const bankListRef = useRef(null)
    const bankInputRef = useRef(null)
    const navigate = useNavigate()

    console.log(user)

    const handleModalToggle = () => {
        if (user.kycLevel > 1) {
            setShow(!show); // Toggle modal only if user is logged in and has a positive balance
          } 
        else {
           navigate('/dashboard/profile/bio')
          }
    }

    const fetchBanks = useCallback(async (signal) => {
        try {
           const result = await getBanks(token?.token);
           setBanks(()=> result.data.data)
        } catch (error) {
           if (!signal.aborted) {
            console.log(error);
           }
        }
     }, [token]);
    
    const filter = (bankName) => {
        setError();
        setAccountName('');
        setShowBankList(true);
        setBankInput(bankName);
        const result = banks.filter((bank)=> (String(bank.bank_name?.toLowerCase())?.includes(bankName.toLowerCase())));
        setFilteredBanks(()=> result);
        
    }

    useEffect(() => {
        
        const controller = new AbortController();
        const signal = controller.signal

        try {
            fetchBanks(signal);
        } catch (error) {
            console.log(error);
        }
        return () => controller.abort(signal.reason);
    }, [fetchBanks])

    const handleInputChange = (e, setter) => {
        setAccountName('');
        setter(e.target.value);
    };

    const selectOnEnterKey = (e) => {
        if (e.key === 'Enter') {
            setBankInput(filteredBanks?.length > 0? filteredBanks[0]?.bank_name: banks[0].bank_name);
            setSelectedBank(filteredBanks?.length > 0? filteredBanks[0]: banks[0])
            setShowBankList(false);

          } 
        else if (e.key === 'ArrowDown') {
            if (filteredBanks.length > 0 && bankListRef.current) {
                if (hoverBank >= filteredBanks.length) {
                    bankListRef.current.children[filteredBanks.length - 1].focus();
                    setHoverBank(filteredBanks.length -1)
                }else {
                    bankListRef.current.children[hoverBank].focus();
                    setHoverBank(hoverBank + 1)
                }
            }
          }
        else if (e.key === 'ArrowUp') {
            if (filteredBanks.length > 0 && bankListRef.current) {
                if (hoverBank === 0) {
                    bankListRef.current.children[0].focus();
                    setHoverBank(0)
                }
                else if (hoverBank >= filteredBanks.length) {
                    setHoverBank(filteredBanks.length -1)
                }
                else {
                    bankListRef.current.children[hoverBank].focus();
                    setHoverBank(hoverBank - 1)
                }
            }
        }
        else if (e.key === 'Backspace') {
            bankInputRef.current.focus()
        }
    }

    const selectBank = (bank) => {
        setSelectedBank(bank);
        setBankInput(bank?.bank_name);
        setShowBankList(false);
    }

    const makeTransfer = async () => {
        
        setError();
        if (!selectedBank) {
            setError('please select bank');
            return;
        }
        
        if (!selectedWallet) {
            setError('please select wallet');
            return;
        }
        if (amount > selectedWallet?.balance) {
            setError('insufficient funds');
            return;
        }
        if (amount < 100) {
            setError('please enter amount above 100');
            return;
        }
        if (accountNumber === '') {
            setError('please enter account number');
            return;
        }
        if (accountNumber.length < 10 || accountNumber.length > 10) {
            setError('invalid account number');
            return;
        }
        const payload ={
            amount,
            bank_code: selectedBank?.nip_bank_code,
            wallet_number: selectedWallet?.wallet_number,
            account_number: accountNumber
        }
        setLoading(true);
        try {
            console.log(payload);
            const result = await sendToBank(token.token, payload);
            console.log(result);
            setWallets(result.data.data)
            const finished = {
                success: true,
                message: result.data.message
            }
            const newTransaction = {amount, accountNumber, accountName, walletNumber: selectedWallet?.wallet_number, bank: selectedBank?.bank_name, date: result.data.transaction?.debitTransaction?.createdAt}

            setTransaction(()=> newTransaction);
            setDone(()=> finished );
            if (result?.response && result.response?.data?.message) {
                setError(result.response?.data?.message)
            }
            setLoading(false);
        } catch (error) {
            console.log(error.response);
            setLoading(false);
            setError(error.response?.data?.message);
            const failedTransaction = {
                amount, accountNumber, accountName, walletNumber: selectedWallet?.wallet_number, bank: selectedBank?.bank_name, date: Date.now()}
                setTransaction(()=> failedTransaction);
            const finished = {
                success: false,
                message: error.response?.data?.message
            }
            setDone(()=> finished );
        }
        
    }

    const closeModal = () => {
        setShow(false);
        setAmount(0);
        setDone();
        setTransaction();
        setAccountName('');
        setAccountNumber('');
        setBankInput('')
        setSelectedBank();
        setError('');
    }
    return (
        <div>
            <dialog open={show} className=' shadow-slate-400 shadow-lg top-0 lg:w-2/4 w-11/12 h-screen z-50'>
                <div className='flex w-full justify-end'>
                    <div onClick={closeModal}><AiOutlineClose className=' cursor-pointer' /></div>
                </div>
                {(!loading && done?.message === undefined) && <div className=' flex flex-col justify-evenly h-full w-full p-4'>
                    <p className=' font-bold py-2'>Withdraw funds</p>
                    <p className=' text-sm font-bold text-slate-600 mb-2'><span>Wallet Number:</span>{selectedWallet?.wallet_number}</p>
                    <AmountInput value={amount} onChange={setAmount} placeholder={'Enter amount'} state={false}/>
                    <input type='text'
                            name={'accountName'}
                            className=' pl-6 pr-2 py-2 w-full focus:outline-none border border-solid border-yellow-400 mb-2 rounded-lg'
                            value={accountName}
                            required
                            disabled
                            placeholder={'Account Name'}/>
                    {/* Bank Select */}
                    <div className=' relative flex flex-col justify-between items-center border border-solid border-yellow-400 rounded-t-lg p-0 mx-auto mb-2 focus:outline-none w-full transition-all ease-in-out'>
                        <input type='text'
                            name={'bankName'}
                            ref={bankInputRef}
                            className=' bg-transparent pl-6 pr-2 py-2 w-full focus:outline-none'
                            value={bankInput}
                            onBlur={()=> setTimeout(()=> setShowBankList(false), 200)}
                            min='0'
                            onChange={(e)=> filter(e.target.value)}
                            onKeyDown={(e)=> selectOnEnterKey(e)}
                            required
                            placeholder={'Bank Name'}/>
                        {showBankList && <div className=' absolute top-full border border-t-0 w-full border-solid border-yellow-400 bg-white overflow-y-auto px-6 py-2 z-50 transition-all ease-in-out h-24' ref={bankListRef}>
                            <hr className=' h-1'/>
                            {filteredBanks? filteredBanks.map((bank)=> (
                                <button
                                    onKeyDown={(e)=> selectOnEnterKey(e)}
                                    className=' p-1 hover:bg-pri-pink cursor-pointer active:outline-none focus:outline-none focus:bg-pri-pink w-full'
                                    key={bank.nip_bank_code}
                                    onClick={()=> selectBank(bank)}
                                >{bank.bank_name}</button>
                            )): <p className=' text-center'>&#x22EF;</p>}
                        </div>}
                    </div>
                    {/* Account Number input */}
                    <AccountNumberInput setter={setAccountNumber} onChange={handleInputChange} setAccountName={setAccountName} accountNumber={accountNumber} selectedBank={selectedBank}/>
                    <p className={paymentBtnStyle} onClick={makeTransfer}>Withdraw</p>
                    {error? <p className=' text-red-500 uppercase text-xs text-center transition-all ease-in-out'>{error}</p> : <p className=' text-red-500 uppercase text-xs text-center transition-all ease-in-out h-4'>{error}</p> }
                </div>}
                {done && <div className=' h-full'>
                    <WithdrawReceipt transaction={transaction} done={done}/> 
                    {/* selectedBank?.bank_name */}
                </div>}
                {loading && <div className=' h-full w-full flex flex-col justify-center items-center'>
                    <Bars
                    height="100"
                    width="300"
                    color="#FFBF00"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClassName=" m-auto"
                    visible={true}
            />
                </div>}
            </dialog>
            <p className=' cursor-pointer text-slate-300 py-2 px-8 bg-black hover:text-white rounded-md' onClick={handleModalToggle}>Withdraw</p>
        </div>
    )
}
