import React from "react";
import Header from "../components/header/mainHeader";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/footer";
import map_white from "../assets/map_white.svg";
import phone_screen from "../assets/phone_screen.svg";

export const IndexPage = () => {
  return (
    <div className="h-full w-full">
      <Header />

      <Outlet />
      {/* map section */}

      <section className="relative -z-50 lg:z-0 flex flex-col lg:flex-row bg-black text-white lg:text-4xl text-3xl md:text-5xl lg:px-20 lg:py-24 lg:space-x-10">
        <div className="flex p-10 items-center">
          <p className="font-Space-Grotesk font-bold text-left">
            Be Limitless, Get a <br /> secure wallet address <br /> in 10{" "}
            <span className="text-pri-pink">seconds.</span>
          </p>
        </div>
        <div className="relative">
          {/* First Image (map_white) */}
          <img
            src={map_white}
            alt="map of the world in dotted black and white with black background"
            className="lg:block hidden"
          />
          {/* Background Image (map_white) */}
          <div
            className="lg:hidden absolute w-full h-full bg-center bg-no-repeat bg-cover mt-14"
            style={{ backgroundImage: `url(${map_white})` }}
          ></div>

          <img
            src={phone_screen} 
            alt="overlay"
            className="lg:hidden md:mx-auto relative h-[125%]"
          />
        </div>

        <img
          src={phone_screen} 
          alt="overlay"
          className="hidden lg:block lg:absolute lg:bottom-0 lg:right-28 h-[125%]"
        />
      </section>
      <Footer />
    </div>
  );
};
