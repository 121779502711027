import React, { useState } from 'react';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import { MdOutlineGppBad, MdOutlineGppGood } from 'react-icons/md';
import loading_animate from '../../assets/loading_image.gif';
import { checkWalletPin } from '../../hooks/services';
import { useUserContext } from '../../context/UserContext';
import { useAuthContext } from '../../context/AuthContext';
import { useEffect } from 'react';

export const WalletPinInput = ({ value, onChange, state, success }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const { selectedWallet, user } = useUserContext();
    const [pin, setPin] = useState('');
    const { token } = useAuthContext();

    const eyeStyle = ' hover:bg-gray-200 p-3 cursor-pointer';

    const checkPin = async (value) => {
        setPin(value);
        if (String(value).length > 3) {
            setLoading(true)
            await checkWalletPin(token.token, {
                wallet_number: selectedWallet?.wallet_number,
                pin: value,
                user_id: user._id
            }).then((result)=> {
                console.log(result.data);
                setLoading(false)
                onChange(true);
            }).catch((error)=>{
                console.log(error);
                setLoading(false)
                onChange(false);
            })
        }
    }
    useEffect(() => {
        setPin('');
    }, [success])
  return (
    <div className=' flex items-center gap-1 mb-2'>
        <div className=' flex justify-between items-center border border-solid border-yellow-400 rounded-lg p-0 overflow-hidden mx-auto focus:outline-none w-full'>
                    <input type={show? 'text': 'password'}
                        name={'walletPin'}
                        className=' pl-6 pr-2 py-2 w-full focus:outline-none'
                        value={pin}
                        inputMode='numeric'
                        pattern='^\d{4}$'
                        // typeof=''
                        max={9999}
                        min={1000}
                        maxLength={4}
                        minLength={4}
                        onChange={(e)=> checkPin(e.target.value)}
                        disabled={loading || state}
                        required
                        placeholder={'Wallet Pin'}/>
                    {show?
                        <div className={eyeStyle} onClick={()=> setShow(!show)}>
                            <AiOutlineEyeInvisible />
                        </div> :
                        <div className={eyeStyle} onClick={()=> setShow(!show)}>
                            <AiOutlineEye />
                        </div> }
        </div>
        <div className=''>
            {value? <MdOutlineGppGood color={'green'}/> :loading? <div className=' w-3'><img src={loading_animate} alt="loading" /></div>: <MdOutlineGppBad color={'red'}/> }
        </div>
    </div>

  )
}
