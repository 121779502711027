import React, {useState, useEffect} from 'react'
import statistics_pending from '../assets/statistics_pending.svg';
import statistics_failed from '../assets/statistics_failed.svg';
import statistics_successful from '../assets/statistics_successful.svg';

const StatisticsOption = ({transactions}) => {
  const [state, setState] = useState({
    pendingCount: 0,
    successfulCount: 0,
    failedCount: 0
  });
  const { pendingCount, successfulCount, failedCount } = state;

  useEffect(() => {
    if (transactions) {
      const pendingCount = transactions.filter(transaction => transaction.status === 'pending').length;
      const successfulCount = transactions.filter(transaction => transaction.status === 'successful').length;
      const failedCount = transactions.filter(transaction => transaction.status === 'failed').length;
      setState(s => ({ ...s, pendingCount, successfulCount, failedCount }));
    }
  },[transactions]);

  const StatisticsOptions = [
    { name: "Pending Transactions", amount: pendingCount, image: statistics_pending },
    { name: "Successful Transactions", amount: successfulCount, image: statistics_successful },
    { name: "Failed Transactions", amount: failedCount, image: statistics_failed }
  ]
  return (
    <div className=' hidden lg:block p-4 border border-yellow-300 rounded-lg mt-4'>
      <h3 className=' font-Space-Grotesk font-bold text-lg'>Statistics</h3>
      <div>
        {StatisticsOptions.map((option, index) => (
          <div key={index} className=' flex p-2 space-x-4 my-4'>
            <div>
              <img src={option.image} alt={option.name} />
            </div>
            <div>
              <p className=' text-2xs font-inter font-bold text-gray-700'>{option.name}</p>
              <p className=' font-Space-Grotesk font-bold'>{option.amount}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StatisticsOption;
