import React from 'react';
import { Link } from 'react-router-dom';

const WhiteBtn = (props) => {
    const {url, text} = props;
  return (
    <div>
        <Link  to={url}>
            <p className='font-Space-Grotesk w-max bg-white text-black hover:text-white hover:bg-black hover:border-black border-2 border-transparent px-14 py-3 rounded-2xl'>{text}</p>
        </Link>
    </div>
  )
}

export default WhiteBtn
