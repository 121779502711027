import React, { useEffect, useState } from "react";
import padlock from "../../assets/padlock.svg";
import {
  AiOutlineClose,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { errStyle } from "../../constants/details";
import TimerRetry from "./TimerRetry";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";

export const InputOtp = ({
  loading,
  handleSubmit,
  value,
  setValue,
  setVisible,
  error,
  errorMessage,
  otpExpiration,
}) => {
  useEffect(() => {}, [otpExpiration]);
  const [show, setShow] = useState(false);
  const eyeStyle = " hover:bg-gray-200 cursor-pointer";
  return (
    <div className=" bg-whiteshadow-lg  w-full ipad-portrait:w-full lg:w-1/2 ">

      <div className="block cursor-pointer w-52 lg:py-0 ipad-portrait:py-4 py-4 pl-6">
        <Link to="/">
          <img src={logo} alt={`logo`} className="w-4/5 md:w-4/5 lg:w-full" />
        </Link>{" "}
      </div>

      <div
        className=" flex justify-end px-5 cursor-pointer"
        onClick={() => setVisible(false)}
      >
        <AiOutlineClose size={20}/>
      </div>
      <div className="h-[80vh] flex justify-center flex-col ">
        <div className=" mx-auto w-1/5 md:mb-4">
          <img src={padlock} alt="success" />
        </div>
        <form
          className=" text-center md:w-3/4 mx-auto py-10"
          onSubmit={(e) => handleSubmit(e)}
        >
          <p className=" text-lg font-bold">Input Otp</p>
          <p className=" md:text-sm text-xs">
            An OTP has been sent to you, Please input it below
          </p>
          <div className=" bg-gray-300  px-2 my-4 flex justify-between items-center rounded-lg">
            <input
              type={show ? "text" : "password"}
              className=" bg-gray-300 w-full tracking-widest focus:outline-none text-xl text-center py-3 px-4"
              placeholder="123456"
              name="otp"
              inputMode="numeric"
              disabled={loading}
              value={value}
              pattern="^\d{6}$"
              // typeof=''
              max={9999}
              min={1000}
              maxLength={6}
              minLength={6}
              onChange={(e) => setValue(e.currentTarget.value)}
            />
            {show ? (
              <div className={eyeStyle} onClick={() => setShow(!show)}>
                <AiOutlineEyeInvisible />
              </div>
            ) : (
              <div className={eyeStyle} onClick={() => setShow(!show)}>
                <AiOutlineEye />
              </div>
            )}
          </div>
          {error && <p className={errStyle}>{errorMessage}</p>}
          <div>
            <button className=" p-2 bg-black hover:bg-gray-900 text-white text-center rounded-md w-full">
              Confirm
            </button>
          </div>
          <p className=" text-xs py-3 text-red-500">
            Do not share your pin with another person.
          </p>
          <TimerRetry />
        </form>
      </div>
    </div>
  );
};
