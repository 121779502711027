import React from 'react'
import { WalletCardDetails } from './WalletCardDetails';
import circle_transact from '../../assets/circles_transact.png';
import { Withdraw } from './Withdraw';
import { Deposit } from './Deposit';
import { WalletFeatureCard } from './WalletFeatureCard';
import { walletFeatures } from '../../constants/details';
import { Transfer } from './Transfer';
import { useLocation } from 'react-router-dom';
import { SuccessOrFail } from '../Modals/SuccessOrFail';

export const WalletPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status')
    const txn_ref = queryParams.get('txn_ref') // wallet number passed as merchant ref
    const msft_ref = queryParams.get('msft_ref')
    
    return (
        <div className=' pt-12 lg:pt-24 px-12'>
            {status && <SuccessOrFail success={status} account={txn_ref} tran_ref={msft_ref} />}
            <WalletCardDetails/>
            <div className=' flex lg:flex-row flex-col justify-between items-center mt-8'>
                <div className=' flex lg:justify-between justify-evenly items-center mb-2 w-full lg:w-1/2'>
                    {
                        
                    }
                    <Withdraw/>

                    <Deposit/>
                    <Transfer/>
                </div>
                
                <div className=' bg-black py-4 px-4 flex justify-between items-center gap-4 rounded-lg'>
                    <div>
                        <img src={circle_transact} alt="difference colored circles" />
                    </div>
                    <p className=' text-white text-2xs lg:text-base'>Fund and transact with local currency</p>
                </div>
            </div>
            <div>
                <p className=' font-Space-Grotesk font-bold my-4 text-lg lg:text-left text-center'>Upcoming features</p>
                <div className=' flex lg:flex-row flex-col w-full lg:gap-0 gap-4 justify-between items-center'>
                    {walletFeatures.map((walletFeature, index)=> (
                        <div key={`feature-${index}`}>
                            <WalletFeatureCard  {...walletFeature}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
