import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getOneTransaction } from '../hooks/services';
import { useAuthContext } from '../context/AuthContext';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ProfileBalance } from './ProfileBalance';
import { MdChat } from "react-icons/md";
import { MdOutlineFileDownload } from "react-icons/md";
import styles from './TransactionDetails.module.css';
import { IoArrowBackSharp } from "react-icons/io5";
import { Bars } from "react-loader-spinner";
import axios from 'axios';
import { config } from '../hooks/services';
import { GET_GIFTCARD_CHAT_ID } from '../constants/links';


export default function TransactionDetailsPage() {
  const { token } = useAuthContext();
  const [chatId , setChatId] = useState("")
  const [state, setState] = useState({
    transaction: null,
    loading: true,
  });
  const { transaction, loading } = state;
  const { id } = useParams();
  const navigate = useNavigate();
  const configObj = config(token?.token);

  useEffect(() => {
    getOneTransaction(id, token?.token)
      .then((res) => {
        setState((s) => ({ ...s, transaction: res.data.data, loading: false }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, token]);

  useEffect(() => {
    axios.get (GET_GIFTCARD_CHAT_ID + transaction?.transaction_number,configObj)
    .then((res) => {
      setChatId(res.data.data._id)
    })
    .catch((error) => {
      console.log(error)
    })
  },[transaction])

  if (loading) {
    return (
    <div className='flex justify-center items-center w-full h-screen'>
      <Bars
      height="100"
      width="300"
      color="#FFBF00"
      ariaLabel="bars-loading"
      wrapperStyle={{}}
      wrapperClassName=" mx-auto"
      visible={true}
    />
    </div>
    )
  }

  const downloadOptionsDropDown = (
    <ul className={styles.downloadOptions} id='downloadOptions'>
      <li onClick={downloadAsPdf}>download as pdf</li>
      <li onClick={downloadAsImage}>download as image</li>
    </ul>
  );

  function downloadAsPdf() {
    document.querySelector('#downloadOptions').style.display = 'none';
    const element = document.getElementById('pdf-container');
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('receipt.pdf');
    });
  }

  function downloadAsImage() {
    document.querySelector('#downloadOptions').style.display = 'none';
    const element = document.getElementById('pdf-container');
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'receipt.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  function showHideDownloadOptions() {
    const options = document.querySelector('#downloadOptions');
    if (options.style.display === 'none') {
      options.style.display = 'block';
    } else {
      options.style.display = 'none';
    }
  }

  return (
    <div className='p-8'>
      <div className=' flex justify-between'>
        <h2 className=' font-Space-Grotesk font-bold'>Transactions</h2>
        <ProfileBalance />
      </div>
      <div className={styles.container} id='pdf-container'>
        <div>
          <IoArrowBackSharp className={styles.backArrow} onClick={() => navigate('/dashboard/transactions')} />
          <div className='px-10'>{properCase(transaction.transaction_type)}</div>
          <div className={`${styles.icons}`}>
            <MdChat onClick={() => navigate(`/dashboard/messages/${chatId}`)} />
            <MdOutlineFileDownload onClick={showHideDownloadOptions} title='download receipt'/>
            {downloadOptionsDropDown}
          </div>
        </div>
        <ul className=''>
          <li>
            <div>Transaction</div>
            <div>{transaction.transaction_number}</div>
          </li>
          <li>
            <div>Total Value</div>
            <div className={styles.amount}>{transaction.description.charAt(0)}{transaction.amount}</div>
          </li>
          <li>
            <div>Description</div>
            <div>{transaction.description}</div>
          </li>
          <li>
            <div>Status</div>
            <div>{transaction.status}</div>
          </li>
          <li>
            <div>Transaction Type</div>
            <div>{transaction.transaction_type}</div>
          </li>
          <li>
            <div>Date</div>
            <div>{formatDate(transaction.createdAt)}</div>
          </li>
        </ul>
        <ul className={styles.cardpics}>
          {transaction.transaction_details.cards && transaction.transaction_details.cards.map((card) => (
            <li key={card._id}>
              <img src={card.card_item} alt="card" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function formatDate(date) {
  const d = new Date(date);
  return d.toGMTString();
}

function properCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}