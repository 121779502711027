import React, { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineUpload } from "react-icons/ai";
import profile_image_placeholder from '../../assets/profile_image.png';
import { deletePicture } from '../../hooks/services';
import spinner from '../../assets/Spinner.gif';
import { useUserContext } from '../../context/UserContext';
import { useAuthContext } from '../../context/AuthContext';

export const ProfileImage = ({onChange, preview, value, loadingState}) => {
    const { user, setUser } = useUserContext();
    const { token } = useAuthContext();
    const [loading, setLoading] = useState(loadingState ?? false);
    const [image, setImage] = useState(value);
    useEffect(() => {
        setLoading(loadingState)
        if(!loading){
            setImage(preview)
        }
    }, [value, preview, loadingState])
    const deleteImage = async (e)=>{
        e.preventDefault();
        setLoading(true)
        if (preview) {
            setImage('');
        }
        if (user.profilePicture_url) {
            const result = await deletePicture(user._id, token?.token);
            setUser(result.data.data.data);
        }
        setLoading(false)
    }
    return (
        <div className=' relative rounded-full'>
            {(user?.profilePicture_url !== "")?
                <div className=' absolute bg-pri-pink p-2 hover:bg-slate-50 cursor-pointer left-3/4 rounded-full' onClick={deleteImage}>
                    <AiOutlineDelete />
                </div>
                :loading?
                <div className=' absolute bg-pri-pink p-2 hover:bg-slate-50 cursor-pointer left-3/4 rounded-full' onClick={deleteImage}>
                    <img src={spinner} alt="loading"/>
                </div>
                :
                <label htmlFor='profile_pic' className=' absolute bg-pri-pink p-2 hover:bg-slate-50 cursor-pointer left-3/4 rounded-full'>
                    <AiOutlineUpload/>
                    <input type="file" className='hidden' name="profile_pic" id='profile_pic' onChange={onChange}/>
                </label>}

            <img src={loading? spinner:user?.profilePicture_url? user?.profilePicture_url :image !== ""? image: profile_image_placeholder} className=' rounded-full' alt=""/>
        </div>
    )
}
