import React from 'react';
// import { RiArrowDownSFill } from 'react-icons/ri';
import profile_image_placeholder from '../assets/profile_image.png';
import { BalanceDropDown } from './BalanceDropDown';
import { useUserContext } from '../context/UserContext';

export const ProfileBalance = () => {
    const { user } = useUserContext()
  return (
    <div className=' flex'>
        <div className=' flex space-x-4 items-center  rounded-md p-2'>
            <BalanceDropDown />
            {/* <div className=' text-yellow-500 text-xl'>
                <RiArrowDownSFill/>
            </div> */}
            <div className='rounded-full overflow-hidden w-14 cursor-pointer'>
                <img src={user?.profilePicture_url ?? profile_image_placeholder} alt="profile"/>
            </div>
        </div>
    </div>
  )
}
