import React, { useEffect, useState } from "react";
import { useUserContext } from "../context/UserContext";
import { Link } from "react-router-dom";
import Notransaction from "../assets/notransaction.svg";

function RecentTransactions({ transactions }) {
  const [transactionsData, setTransactionsData] = useState([]);
  const { user } = useUserContext();

  useEffect(() => {
    setTransactionsData(() => transactions);
  }, [transactions]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="w-full rounded-2xl border border-yellow-200  pb-2 my-10">
        <div className=" flex justify-between w-full items-center p-4 ">
          <p className=" font-Space-Grotesk font-bold">Recent activity</p>
          <Link
            to={"/dashboard/transactions"}
            className=" flex items-center  p-1 cursor-pointer"
          >
            <p className=" text-2xs  rounded-md bg-yellow-200 px-4 py-2">
              View more
            </p>
          </Link>
        </div>
        <div className="">
          {transactionsData?.length > 0 ? (
            transactionsData.slice(0, 5).map((transaction) => (
              <div key={transaction._id} className=" p-4 ">
                <div className="w-full grid grid-cols-12 gap-4 items-center">
                  <p className="col-span-3 font-bold text-gray-800 text-sm">
                    {new Date(transaction.createdAt).toDateString()}
                  </p>
                  <div className="col-span-6">
                    <p className="font-bold text-gray-800 text-base">
                      {transaction.description}
                    </p>
                    <p className="font-bold text-2xs text-gray-600">
                      {transaction.status}
                    </p>
                  </div>
                  <div className="col-span-3 flex justify-end pr-4">
                    <p className="font-bold text-gray-800 text-sm">
                      ${transaction.amount}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <img
                src={Notransaction}
                alt="empty"
                className="lg:w-2/5 w-4/5 mx-auto"
              />
              <div className="flex flex-col items-center pb-5">
                <p className=" text-black font-inter text-base font-bold">
                  No recent transactions
                </p>
                <p className="text-xs font-semibold py-1.5">
                  Looks like you have not made any transactions
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RecentTransactions;
