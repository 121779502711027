import React, { useState } from "react";
import { Link } from "react-router-dom";
import { product_name } from "../constants/details";
import axios from "axios";
import {
  GET_USER_BY_EMAIL,
  REQUEST_PASSWORD_CHANGE_URL,
  rootLinks,
} from "../constants/links";
import { Bars } from "react-loader-spinner";
import logo from "../assets/logo.svg";

const ForgotPasswordPage = () => {
  const [foundEmail, setFoundEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [sentEmail, setSentEmail] = useState(false);

  const [email, setEmail] = useState("");

  async function checkEmail(e) {
    setLoading(true);
    if (email === "") {
      e.preventDefault();
      setMessage("email is required");
      setTimeout(() => {
        setMessage("");
        setLoading(false);
      }, 1000);

      return;
    }

    await axios
      .get(GET_USER_BY_EMAIL, {
        params: { email },
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        localStorage.setItem("user", JSON.stringify(res.data.data));
        setFoundEmail(true);
        setMessage("found user, request for reset password otp?");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setMessage(err.response.data.message);
        setFoundEmail(false);
        setTimeout(() => {
          setMessage("");
          setLoading(false);
        }, 1000);
      });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(REQUEST_PASSWORD_CHANGE_URL, {
        email: email,
      })
      .then((res) => {
        setLoading(false);
        console.log(res);
        setMessage(res.data.message);
        setSentEmail(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div className="overflow-hidden">
      <div className=" flex justify-start items-center">
        <div className="flex-1 bg-auth-pattern bg-cover w-full md:w-7/12 h-screen md:mt-0">
          <div>
            <Link to="/">
              <div className="block ipad-portrait:block ipad-landscape:block lg:block cursor-pointer w-52 py-4 pl-10">
                <img
                  src={logo}
                  alt={`${product_name} logo`}
                  className="w-4/5 md:w-4/5 lg:w-full"
                />
              </div>
            </Link>
          </div>
          <div className="flex">
            <div className="w-1/3 hidden lg:flex justify-center flex-col pl-10">
              <h1 className="font-Smooch text-gold-light text-6xl leading-none">
                Hello !
              </h1>
              <p className="font-bold text-4xl leading-1">
                Login to your <br></br> Cambio Account
              </p>
            </div>

            <form
              className="flex-1 flex justify-center items-center"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="bg-white lg:w-[45%] w-11/12 mt-7 rounded-3xl py-8">
                <p className=" text-center text-xl mb-8">
                  Forgot Your Password.
                </p>
                <div className=" border border-solid border-gray-500 rounded-lg p-0 overflow-hidden focus:outline-none mx-auto w-2/3 mt-4 mb-1">
                  <input
                    type="email"
                    className=" pl-6 pr-1 py-2 w-full"
                    name="email"
                    disabled={loading}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Your Email"
                    required
                  />
                </div>
                <p
                  className={` text-center ${
                    foundEmail ? "text-green-700" : "text-red-700"
                  } -mt-2 mb-4`}
                >
                  {message}
                </p>

                {!loading && !foundEmail ? (
                  <input
                    type="button"
                    value="Check For Email"
                    onClick={checkEmail}
                    disabled={loading}
                    className=" text-white block bg-black cursor-pointer rounded-lg px-6 py-4 w-4/6 mx-auto mb-10 text-center"
                  />
                ) : foundEmail ? (
                  <input
                    type="submit"
                    value="Requst Password Change"
                    disabled={sentEmail}
                    className={` text-white block bg-black ${
                      sentEmail ? " cursor-text" : "cursor-pointer"
                    } rounded-lg px-6 py-4 w-4/6 mx-auto mb-10 text-center`}
                  />
                ) : (
                  <button
                    className=" text-white flex justify-center bg-black cursor-pointer rounded-lg px-6 py-4 w-4/6 mx-auto mb-10 text-center"
                    type="submit"
                    disabled={loading}
                  >
                    <div>
                      <Bars
                        height="20"
                        width="60"
                        color="#ffffff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClassName=" mx-auto"
                        visible={true}
                      />
                    </div>
                  </button>
                )}

                {sentEmail && (
                  <Link to={rootLinks.reset_password}>
                    {" "}
                    <p type="button" className=" text-center text-green-600">
                      Go to Reset Password
                    </p>
                  </Link>
                )}
                <p className=" text-center mb-6">
                  New to {product_name}?{" "}
                  <Link to={rootLinks.signup_link}>
                    <span className=" cursor-pointer text-gray-500">
                      Sign Up
                    </span>
                  </Link>{" "}
                  here
                </p>
                <p className=" text-xs text-center">
                  By continuing you agree to the{" "}
                  <b className=" text-gray-700">Policy and Rules</b>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
