import React from 'react';
import { AboutHeroBanner } from './AboutHeroBanner';
import { CultureSection } from './CultureSection';
import { MessageFromCEO } from './MessageFromCEO';
import { MeetTeam } from './MeetTeam';
import { ContactUs } from './ContactUs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const AboutUsPage = () => {
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const targetElement = document.getElementById(hash.slice(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  
  return (
    <div className=' flex flex-col min-h-screen' >
      {/* Hero banner section */}
      <section id='about' className=' lg:px-24'>
        <AboutHeroBanner />
      </section>

      {/* Culture Section */}
      <section className=' mb-8'>
        <CultureSection />
      </section>
      <section className=' mb-0'>
        <MessageFromCEO />
      </section>
      <section className=' mb-8'>
        <MeetTeam />
      </section>
      <section className='py-8'>
        <ContactUs />
      </section>
    </div>
  )
}
