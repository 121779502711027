import { useUserContext } from '../../context/UserContext';
import React, { useState } from 'react';
import { paymentBtnStyle } from '../../constants/styles';
import { getPaymentUrl } from '../../hooks/services';
import { useAuthContext } from '../../context/AuthContext';

export const Checkout = ({amount}) => {
    const {user, selectedWallet} = useUserContext();
    const { token } = useAuthContext();
    const [ error, setError ] = useState();


    const getLink = async (e) => {
        setError();
        e.preventDefault()

        const data = {
                wallet_number: selectedWallet?.wallet_number,
                amount,
                phoneNumber: user?.phoneNumber,
                email: user?.email,
                fullName: user?.fullName,
        }
        const result = await getPaymentUrl(data, token.token);
        console.log(result.data);
        if (result.data.success && result.data.url) {
            console.log("Response from the endpoint:", data);
            const paymentUrl = result.data.url;
            // Redirect to the payment URL in a new tab
            window.open(paymentUrl, "_blank");
          } else {
            console.error("Error in response:", data);
          }

  }

    return (
        <div className=' mx-auto w-max  '>
            <button className={paymentBtnStyle} onClick={(e)=> getLink(e)}>Confirm</button>
            {error && <p className=' text-sm text-center'>{error}</p>}
        </div>
    )
}

// import React from 'react'
// import { useUserContext } from '../../context/UserContext'
// import { BASE_URL } from '../../constants/links';

// export const Checkout = ({amount}) => {
//     const {user, selectedWallet} = useUserContext();
//     return (
//         <div className=' mx-auto w-max  '>
//             <form action="https://checkout.marasoftpay.live/" method="POST"  className=''>
//                 <input type="hidden" name="public_key" value={process.env.REACT_APP_MARAPUBLICKEY} />
//                 <input type="hidden" name="merchant_ref" value={selectedWallet?.wallet_number} />
//                 <input type="hidden" name="email_address" value={user?.email} />
//                 <input type="hidden" name="user_bear_charge" value={'no'} />
//                 <input type="hidden" name="name" value={user?.fullName} />
//                 <input type="hidden" name="amount" value={amount} />
//                 <input type="hidden" name="phone_number" value={user?.phoneNumber} />
//                 <input type="hidden" name="request_type" value="live" />
//                 <input type="hidden" name="description" value="Deposit to wallet" />
//                 <input type="hidden" name="currency" value="NGN" />
//                 <input type="hidden" name="webhook_url" value={`${BASE_URL}/transaction/deposit`} />
//                 <input type="hidden" name="redirect_url" value={`${process.env.REACT_APP_APPURL}/dashboard/wallet`} />
//                 <input type="hidden" name="user_bear_charge" value="no" />
//                 <input type='submit' value={'Make Payment'} className=' p-2 border border-yellow-300 mx-auto cursor-pointer font-inter font-semibold hover:bg-yellow-200' />
//             </form>
//         </div>
//     )
// }