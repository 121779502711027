import React from 'react';
import { useState } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import { AmountInput } from '../Rates/AmountInput';
import { useUserContext } from '../../context/UserContext';
import { WalletPinInput } from './WalletPinInput';
import { useAuthContext } from '../../context/AuthContext';
import { walletTransfer } from '../../hooks/services';
import { paymentBtnStyle } from '../../constants/styles';
import { Bars } from 'react-loader-spinner';
import { TransferReceipt } from './TransferReceipt';

export const Transfer = () => {
    const [show, setShow] = useState(false);
    const [ transaction, setTransaction ] = useState();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [ done, setDone] = useState();
    const [error, setError] = useState('');
    
    const [pinChecked, setPinChecked] = useState(false);
    const [reciever_wallet_number, setReciever_wallet_number] = useState('');
    const { selectedWallet, setWallets, user } = useUserContext();
    const { token } = useAuthContext();

    const handleInputChange = (e, setter) => {
        setError('');
        setter(e.target.value);
    };
    const closeModal = () => {
        setShow(false);
        setAmount(0);
        setError('');
        setPinChecked(false);
        setReciever_wallet_number('');
        setLoading(false)
    }

    const sendMoney = async () => {
        setLoading(true);
        setSuccess(false)
        if (amount < 1) {
            setLoading(false);
            console.log('error');
            setError('invalid amount')
            return;
        }
        if (reciever_wallet_number === '') {
            setLoading(false);
            console.log('error');
            setError('invalid reciever wallet number')
            return;
        }
        if (!pinChecked) {
            setLoading(false);
            console.log('error');
            setError('pin not checked');
            return;
        }
        console.log(token?.token,{
            wallet_number : selectedWallet.wallet_number, 
            amount, 
            reciever_wallet_number 
        });
        await walletTransfer(token?.token, {
            wallet_number : selectedWallet.wallet_number, 
            amount, 
            reciever_wallet_number 
        }).then((res)=> {
            console.log(res.data);
            setWallets(res.data.data);
            const transaction = {
                amount, 
                senderName: user?.fullName, 
                walletNumber: selectedWallet?.wallet_number, 
                recieverWalletNumber: reciever_wallet_number, 
                recieverName: res.data.recievingWallet?.user_id?.fullName, 
                date: res.data.walletTransaction?.createdAt
            }
            const done = {
                success: true, 
                message: res.data.message
            }
            setTransaction(()=> transaction);
            setAmount(0);
            setReciever_wallet_number('');
            setSuccess(true);
            setDone(()=> done);
        }).catch((error)=>{
            console.log(error.response?.data)
            setSuccess(false)
        });
        setLoading(false);
    }

    return (
        <div>
            <dialog open={show} className=' shadow-slate-400 shadow-lg top-12 lg:w-2/4 w-11/12 h-3/4 z-50'>
                <div className='flex w-full justify-end'>
                    <div onClick={closeModal}><AiOutlineClose className=' cursor-pointer' /></div>
                </div>
                {!success && <div className=' w-full p-4'>
                    <p className=' font-bold py-2'>Wallet Transfer</p>
                    <p className=' text-sm font-bold text-slate-600 mb-2'><span>Wallet Number:</span>{selectedWallet?.wallet_number}</p>
                    <WalletPinInput value={pinChecked} onChange={setPinChecked} state={loading} success={success} />
                    
                    <AmountInput value={amount} onChange={setAmount} placeholder={'Enter amount'} state={false}/>
                    <div className=' flex justify-between items-center border border-solid border-yellow-400 rounded-lg p-0 overflow-hidden mx-auto mb-2 focus:outline-none w-full'>
                        <input 
                            type='text'
                            name={'walletNumber'}
                            className=' pl-6 pr-2 py-2 w-full focus:outline-none disabled:text-gray-500'
                            value={reciever_wallet_number}
                            onChange={(e)=> handleInputChange(e,setReciever_wallet_number)}
                            required
                            disabled={loading}
                            placeholder={'Reciever Wallet Number'}/>
                    </div>
                    <div className=' mx-auto w-max'>
                        {(pinChecked && !loading) && <button className={paymentBtnStyle} onClick={sendMoney}>Send</button>}
                        {loading && <div className=' h-full w-full flex flex-col justify-center items-center'>
                                    <Bars
                                        height="50"
                                        width="150"
                                        color="#FFBF00"
                                        ariaLabel="bars-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=" m-auto"
                                        visible={true}
                                />
                        </div>}
                    </div>
                    
                    {error !== '' && <p className=' text-red-500 text-sm uppercase text-center'>{error}</p>}
                </div>}
                {success && <div>
                    <TransferReceipt transaction={transaction} done={done}/>
                </div>}
            </dialog>
            <p className=' cursor-pointer border hover:border-slate-800 py-2 px-8 rounded-md' onClick={()=> setShow(!show)}>Transfer</p>
        </div>
    )
}
