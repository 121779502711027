import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  CardTypes,
  //Status
} from "../../constants/details";
import {
  CREATE_GIFTCARD_TRANSACTION,
  GET_GIFTCARD_CHAT_ID,
  START_USER_CHATS,
} from "../../constants/links";
//import spinner from "../../assets/Spinner.gif";
import { useNavigate } from "react-router-dom";
import { config } from "../../hooks/services";
import { ProfileBalance } from "../ProfileBalance";
import { useUserContext } from "../../context/UserContext";
import { useAuthContext } from "../../context/AuthContext";
import { useTransactionContext } from "../../context/TransactionContext";
// import {RiArrowDownSFill} from 'react-icons/ri'
import { IoMdArrowDropdown } from "react-icons/io";
import MapWhite from "../../assets/map_white.svg";
import MobileHeader from "../MobileHeader";

const GiftCardPage = ({ selectedGiftcard, Giftcard, setShow }) => {
  const { wallets } = useUserContext();
  const { token } = useAuthContext();
  const { setGiftcardTransactionData } = useTransactionContext();
  const navigate = useNavigate();
  const [loading] = useState(false);
  const configObj = config(token?.token);
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown open/close
  const [isOpenNumber, setIsOpenNumber] = useState(false); // State to manage dropdown open/close
  const [selectedCardType, setSelectedCardType] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCardDetails, setSelectedCardDetails] = useState({});
  const [amount, setAmount] = useState("");
  const [card_type, setCardType] = useState(CardTypes.physical);
  const [selectedNumber, setSelectedNumber] = useState(1);

  //const [rates] = useState([]);
  // const [show, setShow] = useState(false);
  // const [eCode, setECode] = useState("_");
  // const [giftcards, setGiftcards] = useState(GIFTCARDS);
  //  const [selectedGiftcard, setSelectedGiftcard] = useState({});

  // const [cardImage, setCardImage] = useState(spinner);
  // const [cardName, setCardName] = useState("");
  // const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  // const [currencyType, setCurrencyType] = useState({});

  // const [amount, setAmount] = useState(0);
  // const [file, setFile] = useState({});
  // const [preview, setPreview] = useState();
  // const [error, setError] = useState(false);

  // const [cardQuantity, setCardQuantity] = useState(0);
  // const [cardQuantityInput, setCardQuantityInput] = useState('text');

  useEffect(() => {
    if (selectedGiftcard.length > 0) {
      const filtered = selectedGiftcard.filter(
        (giftcard) => giftcard.cardType === card_type
      );
      setSelectedCardType(filtered);
    }
  }, [selectedGiftcard, card_type]);

  const toggling = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const togglingNumber = (e) => {
    e.preventDefault();
    setIsOpenNumber(!isOpenNumber);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setIsOpenNumber(false);
    }
  };

  const disable = !selectedCardDetails || !amount || !selectedNumber;

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCurrencyChange = (e) => {
    setIsOpen(!isOpen);
    setSelectedCurrency(e);
    const selectedGiftcardObj = selectedCardType.find(
      (giftcard) => giftcard.currency.code === e
    );
    setSelectedCardDetails(selectedGiftcardObj);
  };

  const handleNumberChange = (e) => {
    setSelectedNumber(e);
    setIsOpenNumber(!isOpenNumber);
  };

  const handleInputChange = (e) => {
    const value = e.currentTarget.value;

    if (value.length <= 5) {
      setAmount(value);
    }
  };

  const handleBackToSelectCards = () => {
    setShow(true);
  };

  const handleSubmitGiftCard = (e) => {
    e.preventDefault();
    if (selectedCardDetails && amount && selectedNumber) {
      const giftcardTransaction = {
        receiverWalletId: wallets[0]._id,
        user_id: wallets[0].user_id,
        giftcard_id: Giftcard._id,
        total_amount: amount * selectedNumber,
        cards: [],
        description: `${selectedCardDetails.currency.symbol}${amount} ${Giftcard.name} ${selectedCardDetails.cardType}`,
      };

      setGiftcardTransactionData(giftcardTransaction);
      axios
        .post(CREATE_GIFTCARD_TRANSACTION, giftcardTransaction, configObj)
        .then((result) => {
          return axios.post(
            START_USER_CHATS,
            {
              client: result.data.data.user_id,
              transaction_number: result.data.data.transaction_number,
            },
            configObj
          );
        })
        .then((secondResult) => {
          return axios.get(
            GET_GIFTCARD_CHAT_ID + secondResult.data.data.transaction_number,
            configObj
          );
        })
        .then((thirdResult) => {
          navigate(
            `/dashboard/messages/${thirdResult.data.data._id}`
          );
        })
        .catch((error) => {
          alert(error.message);
        });
    } else {
      alert("Please fill all fields");
    }
  };

  return (
    <div className="mx-5 lg:mx-10">
      {/* Mobile Table: Only visible on small screens (less than 768px) */}
      <div className="block lg:hidden">
        <MobileHeader />
      </div>

      {/* Desktop Table: Visible on small screens and above (768px and more) */}
      <div className="hidden lg:block ">
        <div className="  flex justify-end items-center py-8 mx-auto">
          <ProfileBalance />
        </div>
      </div>

      <div>
        <p className=" font-bold font-Space-Grotesk lg:py-0 pt-3 pb-0">
          Kindly input your details{" "}
        </p>
      </div>

      <form>
        <div
          className={`flex w-full justify-between gap-20 lg:mx-auto lg:py-10 py-5 transition-all`}
        >
          <div className=" border-2 border-gray-500 w-full rounded-md px-4 lg:px-8 lg:w-4/6">
            <div className=" flex flex-col lg:flex-row lg:items-center items-start lg:space-x-4 outline-none focus:outline-none my-10">
              <p className=" font-Space-Grotesk font-bold text-base pb-2 lg:pb-0">
                Select Currency Type
              </p>
              <div ref={dropdownRef} className="z-10 relative w-64">
                <button
                  className="w-full px-8 py-2 font-inter font-semibold bg-pri-pink text-left flex justify-between items-center"
                  onClick={toggling} // Toggles the visibility of the dropdown
                >
                  {selectedCurrency || "Select Currency"}
                  <IoMdArrowDropdown
                    size={25}
                    className={`${
                      isOpen ? "transform rotate-180" : ""
                    } transition-transform duration-300`}
                  />
                </button>

                {isOpen && (
                  <ul className="absolute w-full bg-white shadow-lg max-h-60 overflow-auto">
                    {selectedCardType?.map((option) => (
                      <li
                        key={option.currency._id}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer font-medium"
                        onClick={() => {
                          handleCurrencyChange(option.currency.code); // This function will now handle updating the SelectedCurrency and any additional functionality
                        }}
                      >
                        {option.currency.code} - {option.currency.code}
                        {option.currency.symbol}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <p className=" font-Space-Grotesk font-semibold">
              Kindly select the type of card
            </p>
            <div className=" flex gap-4 justify-between mt-2 mb-10">
              <button
                onClick={() => setCardType(CardTypes.physical)}
                className={`font-Space-Grotesk px-5 py-3 ${
                  card_type === CardTypes.physical
                    ? " bg-black text-white "
                    : " bg-white text-black border border-soild border-black"
                } w-3/6`}
                type="button"
              >
                {CardTypes.physical}
              </button>
              <button
                onClick={() => setCardType(CardTypes.eCode)}
                className={` font-Space-Grotesk px-5 py-3 ${
                  card_type === CardTypes.eCode
                    ? " bg-black text-white "
                    : " bg-white text-black border border-soild border-black"
                } w-3/6`}
                type="button"
              >
                {CardTypes.eCode}
              </button>
            </div>
            <div className="my-2 flex justify-between ">
              <label
                htmlFor="amount"
                className=" font-Space-Grotesk font-semibold"
              >
                Input Amount
              </label>
              <p className=" text-sm font-Space-Grotesk">
                Current rate:{" "}
                <span className=" text-yellow-400">
                  {selectedCardDetails?.rate}/$
                </span>
              </p>
            </div>
            <div className="flex flex-col lg:flex-row gap-5 mb-14 ">
              <div className="w-full lg:w-8/12 border-2 border-gray-800 px-2 py-2">
                <span className=" mr-2 font-Space-Grotesk">
                  {selectedCardDetails?.currency?.symbol}
                </span>
                <input
                  className=" bg-transparent focus:outline-none w-10/12"
                  type="number"
                  min={0}
                  name="amount"
                  disabled={loading}
                  value={amount}
                  onChange={handleInputChange}
                />
              </div>

              <div className="lg:w-52 w-36">
                <div className="flex items-center">
                  <div ref={dropdownRef1} className="relative lg:w-64 w-52">
                    <button
                      className="relative w-full lg:px-10 px-4 py-2 font-inter text-sm font-semibold bg-pri-pink flex justify-between items-center"
                      onClick={togglingNumber} // Toggles the visibility of the dropdown
                    >
                      {selectedNumber || "No of Cards"}
                      <IoMdArrowDropdown
                        size={25}
                        className={`${
                          isOpenNumber ? "transform rotate-180" : ""
                        } transition-transform duration-300`}
                      />
                    </button>

                    {isOpenNumber && (
                      <ul className="absolute w-full bg-white shadow-lg max-h-60 overflow-auto">
                        <li
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            handleNumberChange(1); // This function will now handle updating the SelectedCurrency and any additional functionality
                          }}
                        >
                          1
                        </li>
                        <li
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            handleNumberChange(2); // This function will now handle updating the SelectedCurrency and any additional functionality
                          }}
                        >
                          2
                        </li>
                        <li
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            handleNumberChange(3); // This function will now handle updating the SelectedCurrency and any additional functionality
                          }}
                        >
                          3
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <hr className=" h-0.5 w-full bg-gray-500  mb-8" />
            <p className=" text-3xl font-Space-Grotesk font-bold text-center mb-8">
              You will get: ₦
              {(
                (Number(amount) || 0) *
                (Number(selectedCardDetails?.rate) || 0) *
                (Number(selectedNumber) || 0)
              ).toLocaleString("en-US")}
            </p>
          </div>

          <div className="lg:flex flex-col hidden w-1/4 ">
            <div className="mb-4">
              <div className=" bg-pri-pink py-4 w-full rounded-md">
                <p className=" font-Space-Grotesk pl-6 pb-2 font-semibold text-base">
                  Order Summary
                </p>
                <div className=" w-11/12 h-40 mx-auto border-black">
                  <img
                    src={Giftcard.picture_url}
                    alt="giftcard"
                    className="h-40 object-inherit w-full rounded-md"
                  />
                </div>
                <div className=" text-center">
                  {selectedCurrency !== "" ? (
                    <p className=" text-base font-normal py-4">
                      <span className="px-4 ">{selectedCurrency}</span> |{" "}
                      <span className="px-2">{card_type}</span>{" "}
                    </p>
                  ) : null}

                  {selectedCardDetails &&
                  selectedCardDetails.currency &&
                  amount &&
                  selectedNumber ? (
                    <p className="text-base font-inter font-medium">
                      <span>{selectedCardDetails.currency.symbol}</span>
                      <span>{amount}</span> x <span>{selectedNumber}</span> ={" "}
                      {selectedCardDetails.currency.symbol}{" "}
                      {amount * selectedNumber}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className=" hidden lg:block bg-black text-white w-full p-8 rounded-xl">
              <p className=" text-center font-Space-Grotesk mb-4">
                Be limitless, Get a secure wallet address in{" "}
                <span className=" text-yellow-200">5 seconds</span>
              </p>
              <div className=" w-full">
                <img src={MapWhite} alt="maps" />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        className={`flex justify-between lg:justify-end space-x-4 lg:pl-4 py-10`}
      >
        <button
          className=" block w-full lg:w-1/5 px-2 py-0.5 lg:py-2 border border-black rounded-md hover:bg-gray-200"
          type="button"
          onClick={() => handleBackToSelectCards()}
        >
          Back
        </button>
        <button
          className="block w-full lg:w-1/5 px-4 py-1 lg:py-2 bg-black hover:bg-gray-900 text-white rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
          onClick={(e) => handleSubmitGiftCard(e)}
          disabled={disable}
        >
          Proceed with Trade
        </button>
      </div>
    </div>
  );
};

export default GiftCardPage;
