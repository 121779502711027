import React, { useState } from 'react';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import { eyeStyle } from '../../constants/details';

export const PasswordInput = ({ name, placeholder, value, onChange, state }) => {
    const [show, setShow] = useState(false);
  return (
    <div className=' flex justify-between items-center border border-solid border-gray-500 rounded-lg p-0 overflow-hidden mx-auto mb-2 focus:outline-none w-2/3'>
                    <input type={show? 'text': 'password'} 
                        name={name}
                        className=' pl-6 pr-2 py-2 w-full focus:outline-none'
                        value={value}
                        onChange={(e)=> onChange(e.target.value)}
                        disabled={state}
                        required
                        placeholder={placeholder}/>
                    {show? 
                        <div className={eyeStyle} onClick={()=> setShow(!show)}>
                            <AiOutlineEyeInvisible />
                        </div> : 
                        <div className={eyeStyle} onClick={()=> setShow(!show)}>
                            <AiOutlineEye />
                        </div> }
                </div>
  )
}
