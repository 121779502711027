import React, { useState } from "react";
import youtube from "../../assets/group_881.svg";
import facebook from "../../assets/group_880.svg";
import twitter from "../../assets/group_878.svg";
import instagram from "../../assets/group_877.svg";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
  AiFillYoutube,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { linkStyle } from "../../constants/styles";
import { product_name } from "../../constants/details";
import { rootLinks } from "../../constants/links";
import Modal from "./modal";

const Footer = () => {
  const socialMedialContainerStyle =
    "flex items-center space-x-1 pointer-events-auto";
  const footerColumnHeadStyle =
    "font-semibold pb-2 lg:text-2xl md:text-2xl text-xl lg:pt-0 md:pt-0 pt-10 font-Space-Grotesk";
  const [state, setState] = useState({
    showModal: null,
  });

  function toggleModal(modalId) {
    setState((s) => ({
      ...s,
      showModal: s.showModal === modalId ? null : modalId,
    }));
  }

  return (
    <>
      <div className=" flex flex-col lg:flex-row justify-between align-middle bg-pri-pink px-8 lg:px-16 py-24 mt-auto">
        <div className="md:w-1/3  lg:px-16 mb-3">
          <p className=" font-bold text-2xl mb-4">{product_name}</p>
          <p className="font-normal text-base font-inter">
            {product_name} is a company duly registered with RC Number: 1775651
            under the Laws of the Federal Republic of Nigeria
          </p>
        </div>
        <div className="lg:hidden md:hidden flex-col space-x-2 lg:pt-0 md:pt-0 pt-8">
          <div className=" flex space-x-2">
            <a
              className={socialMedialContainerStyle + linkStyle}
              href="https://www.twitter.com"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-full h-full" src={twitter} alt="twitter-icon" />
            </a>
            <a
              className={socialMedialContainerStyle + linkStyle}
              href="https://www.instagram.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-full h-full"
                src={instagram}
                alt="instagram-icon"
              />
            </a>
            <a
              className={socialMedialContainerStyle + linkStyle}
              href="https://www.youtube.com"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-full h-full" src={youtube} alt="youtube-icon" />
            </a>
            <a
              className={socialMedialContainerStyle + linkStyle}
              href="https://www.facebook.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-full h-full"
                src={facebook}
                alt="facebook-icon"
              />
            </a>
          </div>
        </div>

        <div className="hidden md:flex justify-evenly md:justify-between space-x-10 lg:px-8">
          <div>
            <p className={footerColumnHeadStyle}>Company</p>
            <Link className={linkStyle} to={rootLinks.about + "#about"}>
              <p className="py-3 text-base font-inter font-normal text-grey">
                About Us
              </p>
            </Link>
            <Link
              to=""
              onClick={() => toggleModal("terms")}
              className={linkStyle}
            >
              <p className="py-3 text-base font-inter font-normal text-grey">
                Terms of Service
              </p>
            </Link>
            <Link
              onClick={() => toggleModal("privacy")}
              className={linkStyle}
              to=""
            >
              <p className="py-3 text-base font-inter font-normal text-grey">
                Privacy Polices
              </p>
            </Link>
          </div>
          <div>
            <p className={footerColumnHeadStyle}>Support</p>
            <Link className={linkStyle} to={rootLinks.faq}>
              <p className="py-3 text-base font-inter font-normal text-grey">
                FAQ
              </p>
            </Link>
            <Link className={linkStyle} to="/about">
              <p className="py-3 text-base font-inter font-normal text-grey">
                Contact Us
              </p>
            </Link>
          </div>
          <div>
            <p className={footerColumnHeadStyle}>Products</p>
            <Link
              className={linkStyle}
              to={rootLinks.giftcards}
            >
              <p className="py-3 text-base font-inter font-normal text-grey">
                Giftcards
              </p>
            </Link>
            <Link className={linkStyle} to="/dashboard/crypto">
              <p className="py-3 text-base font-inter font-normal text-grey">
                Buy and Sell Crypto Assets
              </p>
            </Link>
            <Link className={linkStyle} to="/dashboard/wallet">
              <p className="py-3 text-base font-inter font-normal text-grey">
                Wallet
              </p>
            </Link>
          </div>
          <div>
            <p className={footerColumnHeadStyle}>Community</p>
            <a
              className={socialMedialContainerStyle + linkStyle}
              href="https://www.twitter.com"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillTwitterSquare size={20} />
              <p className="py-1 text-base font-inter font-normal text-grey">
                Twitter
              </p>
            </a>
            <a
              className={socialMedialContainerStyle + linkStyle}
              href="https://www.instagram.com"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillInstagram size={20} />
              <p className="py-1 text-base font-inter font-normal text-grey">
                Instagram
              </p>
            </a>
            <a
              className={socialMedialContainerStyle + linkStyle}
              href="https://www.youtube.com"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillYoutube size={20} />
              <p className="py-1 text-base font-inter font-normal text-grey">
                Youtube
              </p>
            </a>
            <a
              className={socialMedialContainerStyle + linkStyle}
              href="https://www.facebook.com"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillFacebook size={20} />
              <p className="py-1 text-base font-inter font-normal text-grey">
                Facebook
              </p>
            </a>
          </div>
        </div>

        <div className="lg:hidden md:hidden flex justify-between  lg:px-8">
          <div>
            <p className={footerColumnHeadStyle}>Products</p>
            <Link
              className={linkStyle}
              to={rootLinks.giftcards}
            >
              <p className="py-3 text-base font-inter font-medium text-grey">
                Giftcards
              </p>
            </Link>
            <Link className={linkStyle} to="/dashboard/crypto">
              <p className="py-3 text-base font-inter font-medium text-grey">
                Buy and Sell <br/> Crypto Assets
              </p>
            </Link>
            <Link className={linkStyle} to="/dashboard/wallet">
              <p className="py-3 text-base font-inter font-medium text-grey">
                Wallet
              </p>
            </Link>
          </div>
          <div>
            <p className={footerColumnHeadStyle}>Company</p>
            <Link className={linkStyle} to={rootLinks.about}>
              <p className="py-3 text-base font-inter font-medium text-grey">
                About Us
              </p>
            </Link>
            <Link
              to=""
              onClick={() => toggleModal("terms")}
              className={linkStyle}
            >
              <p className="py-3 text-base font-inter font-medium text-grey">
                Terms of Service
              </p>
            </Link>
            <Link
              onClick={() => toggleModal("privacy")}
              className={linkStyle}
              to=""
            >
              <p className="py-3 text-base font-inter font-medium text-grey">
                Privacy Polices
              </p>
            </Link>
            <Link className={linkStyle} to={rootLinks.faq}>
              <p className="py-3 text-base font-inter font-medium text-grey">
                FAQ
              </p>
            </Link>
            <Link className={linkStyle} to="/about">
              <p className="py-3 text-base font-inter font-medium text-grey">
                Contact Us
              </p>
            </Link>
          </div>
        </div>
      </div>

      {/* terms of service modal */}
      <Modal state={state} toggleModal={toggleModal} modalId={"terms"} />
      {/* Privacy policies modal */}
      <Modal state={state} toggleModal={toggleModal} modalId={"privacy"} />
    </>
  );
};

export default Footer;
