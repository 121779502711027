import React from "react";
import { IoClose } from "react-icons/io5";


const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  return (
    <div className="">
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
    <div className="fixed inset-0 flex items-center justify-center lg:p-4">
      <div
        className="bg-white rounded-lg shadow-lg lg:p-6 lg:w-4/6 lg:h-full lg:mx-20 lg:my-10 z-50 w-4/5 h-2/3 mx-10 overflow-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-end py-2 px-2">
          <IoClose size={25} onClick={onClose} />
        </div>
        {children}
      </div>
    </div>
  </div>
</div>

  );
};

export default Modal;
