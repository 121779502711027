import React, { useState, useEffect } from "react";
import { ProfileImage } from "./profile/ProfileImage";
import { ProfileInformation } from "./profile/ProfileInformation";
import { updateUser } from "../hooks/services";
import { useUserContext } from "../context/UserContext";
// import { RiArrowDownSFill } from "react-icons/ri";
// import { BalanceDropDown } from "./BalanceDropDown";
import KycDob from "./Kyc/KycDob";
import KycUpload from "./Kyc/KycUpload";
import { useAuthContext } from "../context/AuthContext";

export const ProfilePage = () => {
  const { user, setUser, showKyc } = useUserContext();
  const [file, setFile] = useState({});
  const [preview, setPreview] = useState("");
  const { token } = useAuthContext();
  const [ loading, setLoading ] =useState(false)
  const [currentKycLevel, setCurrentKycLevel] = useState(0); // Start with KYC Level 0

  useEffect(() => {
   setCurrentKycLevel(user?.kycLevel)
  }, [user]);

  const handleFile = async (e) => {
    e.preventDefault();
    setFile(e.currentTarget.files[0]);
    setPreview(URL.createObjectURL(e.currentTarget.files[0]));
    const formData = new FormData();
    setLoading(true)
    formData.append("profile_pic", e.currentTarget.files[0]);
    const result = await updateUser(user._id, formData, token?.token);
    if (result.data.status !== 200) {
      alert(result.message);
    } else {
      // setPreview(result.data.data.data.profilePicture_url);
      setUser(result.data.data.data);
    }
    setLoading(false)
  };
  return (
    <div className="mx-auto pl-4 md:pl-8 pr-4 pt-8">
      {/* <div className="flex justify-between ">
        <h1 className=" hidden md:block font-Space-Grotesk font-bold text-lg p-2">
          Profile Settings
        </h1>
        <div className=" flex space-x-4 items-center cursor-pointer rounded-md hover:bg-gray-200 p-2">
          <BalanceDropDown />
          <div className=" text-yellow-500 text-xl m-0">
            <RiArrowDownSFill />
          </div>
        </div>
      </div> */}
      {showKyc ? (
        currentKycLevel === 0 ? (
          <KycDob goToNextLevel={() => setCurrentKycLevel(1)} />
        ) : (
          <KycUpload />
        )
      ) : (
        <div className=" lg:flex lg:flex-row flex flex-col align-top w-full text-center py-10">
          <div className=" w-64 mx-auto lg:mx-0 lg:w-52 bg-pri-pink rounded-lg p-4 h-max">
            <h4 className=" font-Space-Grotesk font-bold capitalize">
              {user?.fullName}
            </h4>
            <p className=" text-xs mb-4">{user?.email}</p>
            <ProfileImage
              value={file}
              preview={preview}
              onChange={handleFile}
              loadingState={loading}
            />
            <label
              htmlFor="profile_pic"
              type="button"
              className=" bg-black text-slate-300 hover:text-white px-6 py-2 text-sm mt-4"
            >
              Upload New Photo
              <input
                type="file"
                name="profile_pic"
                className="hidden"
                id="profile_pic"
                onChange={handleFile}
              />
            </label>
            <div className=" p-2 bg-slate-50 mt-8 rounded-md">
              <p className=" text-2xs">
                Upload a new avatar. Larger image will be resized automatically{" "}
                <br /> <br />
                Maximum upload size is 1MB
              </p>
            </div>
            <p className=" text-2xs mt-4">
              Member Since:{" "}
              <span className=" font-bold font-Space-Grotesk">
                {new Date(user?.createdAt).toLocaleDateString()}
              </span>
            </p>
            <p className=" text-2xs mt-4">
              Last Updated:{" "}
              <span className=" font-bold font-Space-Grotesk">
                {new Date(user?.modifiedAt).toLocaleDateString()}
              </span>
            </p>
          </div>
          <div className=" mb-2 px-8">
            <ProfileInformation />
          </div>
        </div>
      )}
    </div>
  );
};
