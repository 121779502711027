import React from "react";
import image_placeholder from "../../assets/profile_image.png";
import { ceo_name } from "../../constants/details";

export const MessageFromCEO = () => {
  return (
    <div className=" mb-8">
      <h2 className=" text-2xl mt-12 mb-8 lg:text-4xl font-bold font-Space-Grotesk text-center">
        A little message from <span className=" text-pri-pink">Our CEO</span>
      </h2>

      <div className=" flex lg:flex-row flex-col gap-5 justify-center lg:justify-evenly w-3/4 mx-auto lg:h-96">
        <div className=" lg:w-2/4 flex lg:bg-pri-pink rounded-lg">
          <div className=" w-32 lg:w-48 h-48 mx-auto my-auto">
            <img src={image_placeholder} alt="ceo profile" />
          </div>
        </div>

        <div className=" lg:w-2/5 lg:h-full lg:text-base md:text-sm">
          <p>
            An awesome place to trade securely with no worries or delay. It is
            perfectly built for you. An awesome place to trade securely with no
            worries or delay. It is perfectly built for you.An awesome place to
            trade securely with no worries or delay. An awesome place to trade
            securely with no worries or delay.
            <br /> 
            <br />
            An awesome place to trade securely with no worries or delay. It is
            perfectly built for you.An awesome place to trade securely with no
            worries or delay. An awesome place to trade securely with no worries or delay. It
            is perfectly built for you.{" "}
          </p>
          <p className="py-2 font-Space-Grotesk font-semibold">
            {ceo_name}, CEO
          </p>
        </div>
      </div>
    </div>
  );
};
