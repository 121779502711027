import React from 'react';
import { Link } from 'react-router-dom';

const BlackBtn = (props) => {
    const {url, text ,classname} = props;
  return (
    <div className={`${classname}`}>
        <Link  to={url} >
            <p className=' md:ml-0 font-Space-Grotesk w-max bg-black text-white hover:text-black hover:bg-white hover:border-black border-2 border-transparent px-14 py-3 rounded-2xl'>{text}</p>
        </Link>
    </div>
  )
}

export default BlackBtn


//mx-auto