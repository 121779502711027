import bank_card from '../assets/bank_cards.svg';
import phone_crypto from '../assets/phone_crypto.svg';
import bitcoin_gift from '../assets/bitcoin_gift.svg';
import calculator from '../assets/calculator.svg';
import gift_card from '../assets/gift_card.svg';
import airtime from '../assets/airtime.svg';
import spinner from '../assets/Spinner.gif';
import airtime_icon from '../assets/airtime_icon.png';
import electric_bill from '../assets/electric_bill.png';
import tv_bill from '../assets/tv_bill.png';

export const product_name = 'Cambio';
export const ceo_name = 'Joseph Godfrey';
export const logo_url = "https://res.cloudinary.com/dkgblnkxm/image/upload/v1694926535/w2wnxtjjbydmtjru2gjg.png"

// eslint-disable-next-line
export const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})");
// eslint-disable-next-line
export const emailRegex = new RegExp("^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$");

export const giftcardSteps = [
    {
        position: '1',
        title: 'Smooth Payments',
        description: 'An awesome place to trade securely with no worries or delays.'
    },
    {
        position: '2',
        title: 'Smooth Payments',
        description: 'An awesome place to trade securely with no worries or delays.'
    },{
        position: '3',
        title: 'Smooth Payments',
        description: 'An awesome place to trade securely with no worries or delays.'
    },
]

export const walletFeatures = [
    {
        name: "Airtime",
        icon : airtime_icon,
        url: ""
    },
    {
        name: "TV Bills",
        icon : tv_bill,
        url: ""
    },
    {
        name: "Electric Bills",
        icon : electric_bill,
        url: "",
        title: "Electric Bills"
    }

]

export const giftcard_card_details = {
    title : 'Giftcards',
    description: 'Our mission at Cambio is to offer a safe and uniquely user-friendly platform for everyday Nigerians to access the world of cryptocurrency starting with these recognised coins.',
    list:['Itunes', 'Steam', 'Google', 'Visa', 'Amazon', 'Nike'],
    image: bank_card
}

export const cryptocard_card_details = {
    title : 'Cryptocurrency',
    description: 'Our mission at Cambio is to offer a safe and uniquely user-friendly platform for everyday Nigerians to access the world of cryptocurrency starting with these recognised coins.',
    list:['Bitcoin', 'Ethereum', 'Litecoin', 'Tether', 'Dai'],
    image: phone_crypto
}

export const ratecard_card_details = {
    title : 'Rates',
    description: 'Our mission at Cambio is to offer a safe and uniquely user-friendly platform for everyday Nigerians to access the world of cryptocurrency starting with these recognised coins.',
    list:['Cheap', 'Current', 'Trusted'],
    image: bank_card
}

// transactions card details
export const trans_card_details = [
    {
        color:'cream',
        title : 'Swift Transactions',
        description: 'An awesome place to trade securely with no worries or delay. It is perfectly built for you',
        image: gift_card
    },
    {
        color:'light-purple',
        title : 'Best Rates',
        description: 'An awesome place to trade securely with no worries or delay. It is perfectly built for you',
        image: bitcoin_gift
    },
    {
        color:'light-blue',
        title : 'Smooth Payments',
        description: 'An awesome place to trade securely with no worries or delay. It is perfectly built for you',
        image: airtime
    },
    {
        color:'light-brown',
        title : 'On-The-Go Solutions',
        description: 'An awesome place to trade securely with no worries or delay. It is perfectly built for you',
        image: calculator
    }
]

export const GIFTCARDS = Array.from({ length: 4 }, () => ({
    name: '',
    picture_url: spinner,
    rate: 0
  }));

export const SupportedCurrencies = [
    {name: "US Dollar", symbol: "$", code: "USD"}, 
    {name: "NG Naira", symbol: "₦", code: "NGN"},
];


export const CustomEvents = {
    newMessage: 'new_message',
    deleteMessage: 'delete_message',
    debitTransaction: 'debit_transaction',
    creditTransaction: 'credit_transaction',
    newNotification: 'new_notification'
}

export const Status = {
    approved: 'approved',
    pending : 'pending',
    failed: 'failed',
    successful: 'successful'
}

export const CardTypes= {
    physical: "Physical Card",
    eCode: "E-Code"
}

export const roles = {
    admin: 'ADMIN',
    staff:  'STAFF',
    client: 'CLIENT'
}

export const sex = ['male', 'female', 'others']

export const eyeStyle = ' hover:bg-gray-200 p-3 cursor-pointer';
export const errStyle = `w-10/12 pb-2 mx-auto text-red-500`;

// eslint-disable-next-line
export const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
export const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");


export const teamMembers = [
    {
        name:'Joseph Godfrey',
        image: '',
        role: 'CEO',
        url: ''
    },
    {
        name:'Emmanuel Ukoha',
        image: 'https://media.licdn.com/dms/image/C4D03AQFwDKhpi1zLRQ/profile-displayphoto-shrink_800_800/0/1641329483105?e=1697068800&v=beta&t=dHhD0c6S6fg8CRSfHFvkOVuPWJWhc401vLk6BLna8Ts',
        role: 'Technical Consultant',
        url: 'https://www.linkedin.com/in/emmanuelukoha'
    },
    {
        name:'Seun Daniel Omatsola',
        image: 'https://media.licdn.com/dms/image/D4D03AQHwJ8p6UTilUw/profile-displayphoto-shrink_800_800/0/1665324334810?e=1697068800&v=beta&t=pKBpACSzB-40kIM_hCnWXkkklH1_ZIw6EgHAyTiLJqw',
        role: 'Software Engineer',
        url: 'https://www.linkedin.com/in/seun-omatsola-429a69166/'
    },
    {
        name:'Jude Aghanenu',
        image: 'https://media.licdn.com/dms/image/D4D35AQHAT9YVKLJvGQ/profile-framedphoto-shrink_800_800/0/1682940589391?e=1692342000&v=beta&t=SjpudE79DfovAqhe6FHUxi9K2r-OmBBdPjG67lLSVAo',
        role: 'Designer',
        url: 'https://www.linkedin.com/in/aghanenu'
    },
]

export const privacyPolicyUrl = `https://docs.google.com/document/d/e/2PACX-1vTig505x7K3go7O4ir6Fh_obwM9M-p-GCxjy0mmne03C99zQBbkyVBsUKyihhqSt3UAe5AMRLn_q9CV/pub?embedded=true`
export const termNConditionsUrl = `https://docs.google.com/document/d/e/2PACX-1vTig505x7K3go7O4ir6Fh_obwM9M-p-GCxjy0mmne03C99zQBbkyVBsUKyihhqSt3UAe5AMRLn_q9CV/pub?embedded=true`