import React, { useState, useEffect } from "react";
import { Status } from "../../constants/details";
import { AiFillCaretDown } from "react-icons/ai";
import { useUserContext } from "../../context/UserContext";
import { useAuthContext } from "../../context/AuthContext";
import { getTransactions, shortenString } from "../../hooks/services";
import { useNavigate } from "react-router-dom";

export const TransactionTable = () => {
  const options = ["All", ...Object.values(Status)];
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUserContext();
  const { token } = useAuthContext();
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const transactionsPerPage = 5;
  const navigate = useNavigate();

  const selectStatusColor = (status) => {
    switch (status) {
      case Status.approved:
        return "green";
      case Status.pending:
        return "gray";
      case Status.failed:
        return "red";
      case Status.successful:
        return "blue";
      default:
        return "gray";
    }
  };

  useEffect(() => {
    getTransactions(user?._id, token?.token)
      .then((res) => {
        // getTransactions('', token?.token).then(res => {  // using global transactions for now
        const resTrans = res.data.data.all;
        const resTransactions = [];
        resTrans.forEach((element) => {
          if (element.active) {
            const singeTransaction = {
              date: new Date(element.modifiedAt).toDateString(),
              id: element.transaction_number,
              description: element.description,
              status: element.status,
              amount: element.amount,
              selected: false,
              color: selectStatusColor(element.status),
            };
            resTransactions.push(singeTransaction);
          }
        });

        setTransactions(() => resTransactions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token, user?._id]);


  const filteredTransactions = selectedOption === "All"
    ? transactions
    : transactions.filter(transaction => transaction.status === selectedOption);

  // Pagination logic
  const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setCurrentPage(1); // Reset to the first page on option change
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
 
  return (
    <div>
      <div className=" flex justify-between items-center">
        <div className=" flex items-center gap-2 justify-between">
          <div className=" relative custom-dropdown w-32 rounded-md border border-yellow-400 mb-4 cursor-pointer">
            <div
              className="selected-option font-bold font-Space-Grotesk text-center flex justify-center gap-2 items-center p-2"
              onClick={() => setIsOpen(!isOpen)}
            >
              <p>
                {selectedOption[0].toLocaleUpperCase() +
                  selectedOption.toLocaleLowerCase().slice(1)}
              </p>{" "}
              <AiFillCaretDown />
            </div>
            {isOpen && (
              <ul className=" absolute bg-pri-pink options-list text-left py-4 font-bold font-Space-Grotesk rounded-md border border-yellow-400 w-32">
                {options.map((option, index) => (
                  <li
                    key={index}
                    className={`${
                      option === selectedOption ? "selected " : ""
                    } cursor-pointer hover:bg-yellow-300 px-4`}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option[0].toLocaleUpperCase() +
                      option.toLocaleLowerCase().slice(1)}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div></div>
        </div>

        <div>
          <p className=" text-sm font-bold font-inter text-gray-500">
            Total Transactions: <span>{filteredTransactions?.length}</span>
          </p>
        </div>
      </div>

      <div className="w-full rounded-xl border border-yellow-400 py-4">
        <table className="w-full">
          <thead className="font-Space-Grotesk font-bold text-center">
            <tr>
              <th className="px-4 w-1/5">Date</th>
              <th className="px-4 w-1/5">ID</th>
              <th className="px-4 w-1/5">Description</th>
              <th className="px-4 w-1/5">Status</th>
              <th className="px-4 w-1/5">Amount</th>
            </tr>
          </thead>
          {currentTransactions.length > 0 ? (
            <>
              <tbody className="overflow-y-auto h-3/4 text-center">
                {currentTransactions.map(
                  (item, index) =>
                    (item.status === selectedOption ||
                      selectedOption === "All") && (
                      <tr
                        key={index}
                        onClick={() => navigate(item.id)}
                        className="hover:bg-pri-pink-light cursor-pointer"
                      >
                        <td className="px-4 py-4 text-sm font-medium">{item.date}</td>
                        <td className="px-4 py-4 text-sm font-medium">{shortenString(item.id, 10)}</td>
                        <td className="px-4 py-4 text-sm font-medium">{item.description}</td>
                        <td
                          className="px-4 py-4 text-sm font-medium"
                          style={{ color: item.color }}
                        >
                          {item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)}
                        </td>
                        <td className="px-4 py-4 text-sm font-medium">{item.amount}</td>
                      </tr>
                    )
                )}
              </tbody>
              <div className="flex">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (number) => (
                    <button
                      className="border rounded-lg border-solid border-gold bg-black text-white mx-4 my-4 px-3 py-1"
                      key={number}
                      onClick={() => paginate(number)}
                    >
                      {number}
                    </button>
                  )
                )}
              </div>
            </>
          ) : (
            //   <div>
            //   {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            //     (number) => (
            //       <button className="border rounded-lg border-solid border-gold bg-black text-white mx-4 my-4 px-3 py-1" key={number} onClick={() => paginate(number)}>
            //         {number}
            //       </button>
            //     )
            //   )}
            // </div>
            <tbody>
              <tr>
                <td colSpan="5" className="text-center">
                  No transactions available
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
