import React from 'react';
import profile_image_placeholder from '../../assets/profile_image.png';
import { AiFillLinkedin } from 'react-icons/ai'

export const TeamCard = ({ url, name = 'name', role ='role'}) => {
    return (
        <div className='p-2'>
            <div className=' relative rounded-full mx-auto w-32'>
                <div className=' rounded-full overflow-hidden w-32'>
                    <img src={profile_image_placeholder} alt={name} />
                </div>
                
                <a href={url} target="_blank" rel="noopener noreferrer" className=' absolute bg-white cursor-pointer left-3/4 top-3/4 p-1 rounded-full'>
                    <AiFillLinkedin/>
                </a>
            </div>
            <p className=' font-Space-Grotesk font-semibold'>{name}</p>
            <p className=' text-xs lg:text-sm text-slate-700 font-semibold font-inter'>{role}</p>
        </div>
    )
        
}
