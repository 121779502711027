import React from 'react'
import GiftcardsCard from './GiftcardsCard'
import { giftcard_card_details } from '../../constants/details'
import { GiftcardSteps } from './GiftcardSteps'
import { GiftCardsHero } from './GiftCardsHero'
import {useLocation} from 'react-router-dom';
import { useEffect } from 'react';

export const HomeGiftcardPage = () => {
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const targetElement = document.getElementById(hash.slice(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
    return (
        <div id='home-giftcard'>
            <section>
                <GiftCardsHero/>
            </section>
            <section>
                <GiftcardsCard
                list={giftcard_card_details.list}
                title={giftcard_card_details.title}
                image={giftcard_card_details.image}
                description={giftcard_card_details.description} />
            </section>
            <section>
                <GiftcardSteps/>
            </section>
        </div>
    )
}
