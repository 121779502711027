import React, { useState } from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

export const WalletAction = ({ index }) => {
    const wallets = ['view','disable'];
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option) => {
        console.log(option);
        setIsOpen(false);
      };
    return (
        <div>
            <div className=" relative custom-dropdown mx-auto w-max shadow-sm cursor-pointer">
                    <div className="selected-option font-bold text-2xs font-Space-Grotesk text-center flex justify-center gap-2 items-center" onClick={() => setIsOpen(!isOpen)}>

                        <p className=' '>Wallet <span>{index + 1}</span></p> {isOpen? <AiFillCaretUp/> : <AiFillCaretDown/>}
                    </div>
                    {isOpen && (
                        <ul className=" absolute options-list text-2xs text-left py-1 font-bold font-Space-Grotesk bg-white w-full">
                        {wallets?.map((wallet, index) => (
                          <div key={index}>
                            <li
                              className='cursor-pointer hover:bg-yellow-300 px-1'
                              onClick={() => handleOptionClick(wallet)}
                            >
                              <p className='text-left'>{wallet}</p>
                            </li>
                            <hr className=' text-black'/>
                          </div>
                        ))}
                        </ul>
                    )}
              </div>
        </div>
    )
}
