import React from 'react';
import logo from '../../assets/logo.svg';
import { Link } from 'react-router-dom';
import { product_name } from '../../constants/details';

const LogoBtn = () => {
  return (
    <Link to='/'>
        <div className='block cursor-pointer w-40 ease-in-out mx-auto'>
          <img src={logo} alt={`${product_name} logo`} className='w-3/5 md:w-4/5 lg:w-full' />
        </div>
    </Link>
  )
}

export default LogoBtn;