import React, { useState, useEffect } from "react";
import SelectGiftCard from "./SelectGiftCard";
import { GIFTCARDS } from "../../constants/details";
import axios from "axios";
import { GET_GIFTCARD_RATES } from "../../constants/links";
import { useAuthContext } from "../../context/AuthContext";
import { config, getSupportedGiftcards } from "../../hooks/services";
import GiftCardPage from "./GiftCardPage";

const Index = () => {
  const [show, setShow] = useState(true);
  const [giftcards, setGiftcards] = useState(GIFTCARDS);
  const [selectedGiftcardImg, setSelectedGiftcardImg] = useState({});
  const [selectedGiftcardRates, setSelectedGiftcardRates] = useState({});
  const { token } = useAuthContext();
  const configObj = config(token?.token);


  const updateGiftcards = (newGiftcardsArray) => {
    setGiftcards(newGiftcardsArray);
  };

  useEffect(() => {
    getSupportedGiftcards()
      .then((res) => {
        const resCards = res.data.data;
        updateGiftcards(resCards);

        // resCards.forEach((element, index) => {
        //   setGiftcards((prev) => {
        //     const newCard = prev.map((card, cardIndex) => {
        //       if (index === cardIndex) {
        //         return element;
        //       }
        //       return card;
        //     });
        //     return newCard;
        //   });
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const startGiftcardTransaction = async (Giftcard) => {
    setShow(false);
    setSelectedGiftcardImg(Giftcard);
    await axios
      .get(`${GET_GIFTCARD_RATES}${Giftcard._id}`, configObj)
      .then((result) => {
        console.log(result);
        setSelectedGiftcardRates(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {show ? (
        <SelectGiftCard
          giftcards={giftcards}
          startTransaction={startGiftcardTransaction}
        />
      ) : (
        <GiftCardPage
          selectedGiftcard={selectedGiftcardRates}
          Giftcard={selectedGiftcardImg}
          setShow={setShow}
        />
      )}
    </div>
  );
};

export default Index;
