import React from 'react'
import { privacyPolicyUrl } from '../constants/details'

export const PrivacyPolicyPage = () => {
    return (
        <div className=' h-screen w-full'>
            <div className=' h-2/3 p-8'>
                <h2 className=' font-Space-Grotesk text-2xl font-extrabold h-max p-1 mb-2 text-center'>Privacy Policies</h2>
                <iframe src={privacyPolicyUrl} width={'100%'} height={'100%'} />
            </div>
            
        </div>
    )
}
