import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { checkBankDetails } from '../../hooks/services';
import loading_animate from '../../assets/loading_image.gif';

export const AccountNumberInput = ({accountNumber, onChange, setter, selectedBank, setAccountName}) => {
    const [ checkingBankNumber, setCheckingBankNumber ] = useState(false);
    const [ bankVerified, setBankVerified ] = useState(false);
    const [ error, setError ] = useState(false);
    const { token } = useAuthContext();

    useEffect(() => {
        setBankVerified(false);
        if (accountNumber?.length === 10) {
            setCheckingBankNumber(true);
            setBankVerified(false);
            setError(false);
            checkBankDetails(token?.token, accountNumber, selectedBank?.nip_bank_code).then((res)=>{
                console.log(res)
                if (res.data?.success === true) {
                    setAccountName(res.data?.data?.account_name)
                    setBankVerified(true);
                } else { 
                    setError(true);
                    setBankVerified(false);
                }

                setCheckingBankNumber(false);

            }).catch((error)=>{
                setError(true)
                setCheckingBankNumber(false);
                console.log(error);
            });
        }
    }, [accountNumber, setAccountName, selectedBank,token])

    return (
        <div className=' flex justify-between items-center border border-solid border-yellow-400 rounded-b-lg pr-2 overflow-hidden mx-auto mb-2 focus:outline-none w-full'>
            <input type='number'
                name={'accountNumber'}
                 className=' pl-6 pr-2 py-2 w-full focus:outline-none'
                 value={accountNumber}
                 inputMode='numeric'
                 pattern='^[0-9]+$'
                min='0'
                onChange={(e)=> onChange(e,setter)}
                required
                placeholder={'Account Number'}/>
            {bankVerified && <p className=' text-green-700'>&#10003;</p>}
            {error && <p className=' text-red-700'>&#x2715;</p>}
            {checkingBankNumber && <div className=' w-3'><img src={loading_animate} alt="loading" /></div>}
        </div>
    )
}
