import React from 'react';
import { useState } from 'react';
import {AiOutlineClose} from 'react-icons/ai';
// import MakePayment from '../Payment/MakePayment';
import { AmountInput } from '../Rates/AmountInput';
import {  useUserContext } from '../../context/UserContext';
import { Checkout } from '../marasoft/Checkout';

export const Deposit = () => {
    const [show, setShow] = useState(false);
    const [amount, setAmount] =useState(0);
    const { selectedWallet } = useUserContext();
    const closeModal = () => {
        setShow(false);
        setAmount(0);
    }
    return (
        <div>
            <dialog open={show} className=' shadow-slate-400 shadow-lg top-12 lg:w-2/4 w-11/12 h-3/4 z-50'>
                <div className=' flex w-full justify-end'>
                    <div onClick={closeModal}><AiOutlineClose className=' cursor-pointer' /></div>
                </div>
                <div className=' flex flex-col justify-evenly h-2/3 w-full p-4 transition-all'>
                    <p  className=' font-bold py-2'>Deposit funds</p>
                    <p className=' text-sm font-bold text-slate-600 mb-2'><span>Wallet Number:</span>{selectedWallet?.wallet_number}</p>
                    <AmountInput value={amount} onChange={setAmount} placeholder={'Enter amount'} state={false}/>
                    {(amount > 0) && <div onClick={()=> setShow(false)}><Checkout amount={amount} selectedWallet={selectedWallet} /></div>}
                </div>
            </dialog>
            <p className=' cursor-pointer border hover:border-slate-800 py-2 px-8 rounded-md' onClick={()=> setShow(!show)}>Deposit</p>
        </div>
    )
}
