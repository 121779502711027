import React from 'react'

import { useUserContext } from '../../context/UserContext'
import { useState } from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { useEffect } from 'react';

export const WalletCardDetails = () => {
    const {wallets, setSelectedWallet, selectedWallet} = useUserContext();
    const [selectedOption, setSelectedOption] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setSelectedWallet(option)
        setIsOpen(false);
      };

    useEffect(() => {
        setSelectedOption(selectedWallet);
    }, [selectedWallet])
    return (
        <div className=' p-8 border border-solid border-slate-500 rounded-lg w-full lg:w-1/2'>
            <div className=' flex justify-between items-center '>
                <p className=' font-bold text-lg w-3/5'><span className=' text-slate-400 text-sm'>Wallet Number: </span>{selectedOption?.wallet_number}</p>
                <div className=" relative custom-dropdown w-2/6 rounded-md border border-yellow-400 cursor-pointer" onMouseLeave={() => setIsOpen(false)}>
                        <div className="selected-option font-bold font-Space-Grotesk text-center flex justify-center gap-2 items-center px-1" onClick={() => setIsOpen(!isOpen)} >
                            <p className=' text-2xl w-1/3 text-center text-green-400'>{selectedOption?.currency.symbol}</p>
                            <p>{ selectedOption?.currency.code}</p> {isOpen? <AiFillCaretUp/> :<AiFillCaretDown/>}
                        </div>
                        {isOpen && (
                            <ul className=" absolute bg-white options-list text-left py-2 font-bold font-Space-Grotesk rounded-md border border-yellow-400 w-full">
                            {wallets?.map((wallet, index) => (
                            <div key={index}>
                                <li
                                className={`${wallet === selectedOption ? 'selected ' : ''} flex gap-2 items-center justify-start cursor-pointer hover:bg-yellow-300 px-4`}
                                onClick={() => handleOptionClick(wallet)}
                                >
                                <p className=' text-2xl w-1/3 text-center text-green-400'>{wallet.currency.symbol}</p>
                                <div className='flex flex-col justify-start w-2/3'>
                                    <p className=''>{wallet.currency.code}</p>
                                </div>
                                </li>
                                <hr className=' text-black'/>
                            </div>
                            ))}
                            </ul>
                        )}
                </div>
            </div>
            <p className=' font-Space-Grotesk font-bold text-4xl my-2'><span>{selectedOption?.currency?.symbol}</span>{parseFloat(selectedOption?.balance).toLocaleString('en-US',{ currency: selectedOption?.currency?.code})}</p>
            <p className=' text-sm'><span className=' font-bold text-slate-400'>Last Operation: </span>{new Date(selectedOption?.modifiedAt).toLocaleString()}</p>
        </div>
        
    )
}
