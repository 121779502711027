import React from "react";
import { ProfileBalance } from "../ProfileBalance";

const CryptoOverview = () => {
  return (
    <div>
      <div className="flex pr-6 pt-2">
        <div className="ml-auto">
          <ProfileBalance />
        </div>
      </div>
      <div className="flex">
        <div className="p-6 m-6 mt-[2.1875rem] w-[30.25rem] h-[17rem] flex-shrink-0 border border-solid border-slate-500 rounded-lg ">
          <div className="flex justify-between">
            <h1 className="w-237  text-black text-23 font-bold ">
              Your Crypto portfolio
            </h1>
            <div className="p-2 flex justify-between border border-solid rounded-md">
              <img src="/image 62.jpg" alt="nigeriaflag" />
              &nbsp; &nbsp; <span>NGN</span>&nbsp;&nbsp;&nbsp;
              <span className="pt-2">
                <img src="/Vector (1).jpg" alt="downarrow" />
              </span>
            </div>
          </div>
          <div>
            <strong className="text-6xl">#0.</strong>
            <span className="text-3xl">00</span>
          </div>
          <p className="mt-8 fs-">
            This is the total value of all your <br />
            cryptocurrencies at current prices
          </p>
        </div>
        <div className="p-6 m-6 mt-[2.1875rem] mr-[5.125rem] bg-black border border-solid border-slate-500 rounded-lg w-full lg:w-1/2">
          <h1 className="w-237 h-29.143 flex-shrink-0 text-white text-center font-inter text-23 font-bold leading-24 tracking-wide">
            Top Crypto Assets
          </h1>
          <div className="w-507.857 h-203 flex-shrink-0 bg-cover bg-no-repeat bg-center">
            <div className="w-123 h-50 flex-shrink-0 rounded-full border border-solid border-0.6 border-gray-400"></div>
          </div>
        </div>
      </div>

      <div className="flex pl-6 ">
        <button className=" p-4 bg-FFF9EB text-gray-900 rounded-md mr-4  border-[0.5px] border-black w-44">
          Sell crypto
        </button>
        <button className=" p-4 bg-FFF9EB text-gray-900 rounded-md mr-4 border-[0.5px] border-black w-44">
          Buy crypto
        </button>
        <button className=" p-4 bg-FFF9EB text-gray-900 rounded-md mr-4 border-[0.5px] border-black w-44">
          Convert
        </button>
        <button className=" p-4 bg-FFF9EB text-gray-900 rounded-md mr-4 border-[0.5px] border-black w-44">
          Send
        </button>
      </div>

      <div className="p-6 m-6 mb-[1.9375rem] mr-[5.125rem]  w-[65.8125rem] h-[23.5rem] flex-shrink-0 rounded-[0.5625rem] border-[0.5px] border-black">
        <h1 className="Grotesk'] flex-shrink-0 w-[8.5rem] h-9 text-black font-['Space text-[1.4375rem] font-bold leading-[normal]">
          Your Assets
        </h1>
        <div className="flex justify-between  space-x-8">
          <div className="">
            <h3 className="font-semibold mt-6">Name</h3>
            <p className="flex mt-8">
              <img src="/Vector.jpg" alt="" />
              &nbsp;&nbsp; Bitcoin <span className="">&nbsp; BTC</span>
            </p>
            <p className="flex mt-8">
              <div className="w-[2.5625rem] h-[2.5625rem] bg-purple-700 rounded-full">
                <img src="/" alt="" />
              </div>
              &nbsp;&nbsp; Ethereum{" "}
              <span className="text-gray-800">&nbsp; ETH</span>
            </p>
            <p className="flex mt-8">
              <div className="w-[2.5625rem] h-[2.5625rem] bg-green-600 rounded-full">
                <img src="/" alt="" />
              </div>
              &nbsp;&nbsp; Naira <span className="">&nbsp; NGN</span>
            </p>
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="">
            <h3 className="font-semibold mt-6">Balance</h3>
            <p className="mt-8">0.00</p>
            <p className="mt-12">0.00</p>
            <p className="mt-14">20,000,000</p>
          </div>
          &nbsp; &nbsp; &nbsp;
          <div className="">
            <h3 className="font-semibold mt-6">Fiat Value</h3>
            <p className="mt-8">#0.00</p>
            <p className="mt-12">#0.00</p>
            <p className="mt-14">#0.00</p>
          </div>
          &nbsp; &nbsp; &nbsp;
          <div className="">
            <h3 className="font-semibold mt-6">24h Change</h3>
            <p className="text-green-800 mt-8">+0.30%</p>
            <p className="text-green-800 mt-12">+0.30%</p>
            <p className="text-green-800 mt-14">+0.30%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoOverview;
