import React from 'react';
import { rootLinks } from '../../constants/links';
import { Link } from 'react-router-dom';
import giftcard_phone from '../../assets/giftcard_phone.png';
import { Animated } from "react-animated-css";

export const GiftCardsHero = () => {
    return (
        <div className={` block lg:flex justify-center items-center mx-auto pt-8 w-full bg-cream-pink bg-striped-up bg-no-repeat bg-contain bg-right-top`}>
                <div className=' p-4 lg:w-8/12'>
                    <Animated animationIn="bounceInRight" animationInDelay="800" isVisible={true}>
                    <h2 className='lg:pl-16 lg:pt-16 whitespace-normal font-bold leading-12 text-4xl lg:text-6xl my-8 font-Space-Grotesk w-3/4 lg:w-full text-center lg:text-left mx-auto'>Love Delivered <br/> Happiness Created</h2>
                    <p className='lg:pl-16 font-inter w-4/5 lg:w-11/12 mb-8 text-center text-2xl lg:text-left mx-auto lg:mx-0'>Securely buy and sell your gift cards of any kind either in physical card or e-code. Receive fantastic rates for your gift cards today!</p>
                    <div className='lg:pl-16 hidden lg:flex lg:justify-start justify-center lg:gap-4 items-center mx-auto'>
                    <Link to={rootLinks.dashboard}>
                            <div className=' font-Space-Grotesk flex justify-center items-center bg-black text-white hover:bg-white hover:text-black px-8 py-3 border mx-auto border-black w-max my-4 rounded-2xl'>
                                <p>Trade With Us</p>
                            </div>
                        </Link>
                        <Link to={rootLinks.rates}>
                            <div className=' font-Space-Grotesk flex justify-center items-center bg-white hover:bg-black hover:text-white px-8 py-3 border border-black w-max rounded-2xl'>
                                <p>Rates Calculator <span className=' transform rotate-45 inline-block text-opacity-0 stroke-1 stroke-black'>$</span></p>              
                            </div>
                        </Link>
                    </div>
                    <div className=' lg:hidden mx-auto'>
                        <Link to={rootLinks.dashboard}>
                            <div className=' font-Space-Grotesk flex justify-center items-center bg-black text-white hover:bg-white hover:text-black px-8 py-4 border mx-auto border-black w-4/5 my-4 rounded-2xl'>
                                <p>Trade With Us</p>
                            </div>
                        </Link>
                        <Link to=''>
                            <div className=' font-Space-Grotesk flex justify-center items-center bg-white hover:bg-black hover:text-white px-8 py-4 border mx-auto border-black w-4/5 my-4 rounded-2xl'>
                                <p>Check Our Rates <span className=' transform rotate-45 inline-block text-opacity-0 stroke-1 stroke-black'>$</span></p>
                            </div>
                        </Link>
                    </div>
                    </Animated>
                </div>
                <Animated animationIn="bounceInLeft" animationInDelay="1200" isVisible={true}>
                <div className=''>
                    <img src={giftcard_phone} className=' ml-10 w-4/5' alt="phone with bitcoin, etheruem, litecoin and other shapes around"/>
                </div>
                </Animated>
            </div>
    )
}
