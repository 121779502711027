import React, { useEffect } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useAuthContext } from "../context/AuthContext";
import { getBroadcast } from "../hooks/services";

const Broadcast = () => {
  const { token } = useAuthContext();
  const [broadcast, setBroadcast] = React.useState([]);

  useEffect(() => {
    const authToken = token?.token;
    if (authToken !== undefined) {
      getBroadcast(authToken)
        .then((res) => {
          const data = res.data.data;
          data.filter((item) => {
            return new Date() > item.expiration;
          });
          setBroadcast(data);
        })
        .catch((error) => {
          console.log("Unable to fetch broadcast. Please try again later.",error);
        });
    }
  }, [token?.token]);
 
  const ClickClose = (index) => {
    setBroadcast(prevBroadcast => [
      ...prevBroadcast.slice(0, index),
      ...prevBroadcast.slice(index + 1)
    ]);
  };
  
  return (
    <>
    {broadcast.map((broadcastItem, index) => (
      <div
        key={index}  // Ensure each child in a list has a unique "key" prop
        className={`bg-light-green mx-4 lg:mx-10 my-4 py-2 rounded-lg flex justify-between items-center px-3 cursor-pointer`}
      >
        <p className="w-3/5 pl-3 text-sm font-medium">{broadcastItem.message}</p>
        <button className="bg-dark-green text-white text-sm rounded-lg py-1 px-7 mr-2">
          Button
        </button>
        <IoCloseCircleOutline size={18} onClick={() => ClickClose(index)} />
      </div>
    ))}
  </>
  
  );
};

export default Broadcast;
