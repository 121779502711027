import React, { useEffect, useState } from 'react';
import {AiFillCaretDown, AiOutlineArrowLeft} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { PinInput } from '../PinInput';
import { useUserContext } from '../../../context/UserContext';
import { changeWalletPin, getWallets } from '../../../hooks/services';
import { useAuthContext } from '../../../context/AuthContext';
import { Success } from './Success';

export const ChangePin = () => {
    const { wallets, setWallets, user } = useUserContext();
    const { token } = useAuthContext();
    const [currentPin , setCurrentPin] = useState('');
    const [confirmPin , setConfirmPin] = useState('');
    const [pin , setPin] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedOption, setSelectedOption] = useState(wallets[0]);
    const [isOpen, setIsOpen] = useState(false);
    const errStyle = ` mx-auto w-2/3 -mt-4 text-red-500`;

    async function handleSubmit(e){
          e.preventDefault();
          setLoading(true);
        if (error) {
          setLoading(false);
          return;
        }
        const payload = {
          wallet_number : selectedOption?.wallet_number,
          current_pin: currentPin,
          pin 
        }
        changeWalletPin(token.token, payload).then(res=>{
          console.log(res.data);
          setLoading(false);
          setSuccess(res.data.success);
          setMessage(res.data.message);
        }).catch(error=>{
          console.log(error);
          setLoading(false);
          setError(true)
          setErrorMessage(error.response?.data?.message)
        })
      }
      useEffect(() => {
        if (confirmPin !== pin) {
            setError(true)
            setErrorMessage('Confirm pin does not match')
        }
        else{
            setError(false)
        }
      }, [confirmPin, pin])

      useEffect(() => {
        if (wallets?.length < 1) {
          console.log(user);
          getWallets(user?._id).then(result=>{
            console.log(result);
            setWallets(()=> result.data)
          })
        }
      }, [wallets, setWallets, user])

      
      const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
      };


  return (
    <div className=' px-8 py-2'>
        {success?
        <Success message={message}/>
        :
        <div className=' w-full mt-12 md:mt-2'>
            <Link to={-1} className=' text-yellow-500 text-left cursor-pointer mb-2 flex gap-1 w-max items-center'>
                <AiOutlineArrowLeft/>
                <p>Back</p>
            </Link>
            
            <h3 className=' font-Space-Grotesk'>Select Wallet</h3>
            <div className=" relative custom-dropdown mx-auto w-max rounded-md border border-yellow-400 mb-4 cursor-pointer">
                    <div className="selected-option font-bold font-Space-Grotesk text-center flex justify-center gap-2 items-center p-2" onClick={() => setIsOpen(!isOpen)}>
                        <p className=' text-2xl w-1/3 text-center text-green-400'>{selectedOption?.currency.symbol}</p>
                        <p>{ selectedOption?.wallet_number}</p> <AiFillCaretDown/>
                    </div>
                    {isOpen && (
                        <ul className=" absolute bg-pri-pink options-list text-left py-4 font-bold font-Space-Grotesk rounded-md border border-yellow-400 w-full">
                        {wallets?.map((wallet, index) => (
                          <div key={index}>
                            <li
                              className={`${wallet === selectedOption ? 'selected ' : ''} flex gap-2 items-center justify-start cursor-pointer hover:bg-yellow-300 px-4`}
                              onClick={() => handleOptionClick(wallet)}
                            >
                              <p className=' text-2xl w-1/3 text-center text-green-400'>{wallet.currency.symbol}</p>
                              <div className='flex flex-col justify-start w-2/3'>
                                <p className=' text-xs'>{wallet.currency.code}</p>
                                <p className=' text-sm font-bold'>{wallet.wallet_number}</p>
                              </div>
                            </li>
                            <hr className=' text-black'/>
                          </div>
                        ))}
                        </ul>
                    )}
              </div>
            <form className=' mx-auto p-8 block mb-2' onSubmit={(e)=>handleSubmit(e)}>
                <PinInput name="currentPin" placeholder="Current Pin'" value={currentPin} onChange={(e)=>setCurrentPin(e)} state={loading} />
                <PinInput name="newPin" placeholder="New Pin" value={pin} onChange={(e)=>setPin(e)} state={loading} />
                <PinInput name="confirmPin" placeholder="Confirm Pin" value={confirmPin} onChange={(e)=>setConfirmPin(e)} state={loading} />
                {loading? <div className=' w-4/6 mx-auto rounded-lg p-1 flex justify-center bg-black cursor-pointer'>
                  <Bars
                          height="40"
                          width="100"
                          color="#FFBF00"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                          /> 
                </div>
                          : <input type='submit' value='Change Pin' disabled={loading} className={`text-white block ${loading? 'bg-gray-700 cursor-wait': 'bg-black cursor-pointer'}  rounded-lg px-6 py-4 w-4/6 mx-auto mb-6 text-center`}/>}
                
                {error && <p className={errStyle}>{errorMessage}</p>}
            </form>
            
          </div>
        }
    </div>
  )
}
