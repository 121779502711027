import React from 'react'
import ProcessSteps from '../ProcessSteps'
import { giftcardSteps } from '../../constants/details'
import BlackBtn from '../buttons/BlackBtn'
import { rootLinks } from '../../constants/links';
import giftcard_hand from '../../assets/giftcard_hand.png';
import white_on_black_good_tick from '../../assets/white_black_good_tick.svg';

export const GiftcardSteps = () => {
    return (
        <div className={` flex flex-col-reverse md:flex md:flex-row justify-center items-center mx-auto mt-12 bg-gray-100 w-full pt-4`}>
                <div className=' w-10/12 lg:w-1/2 mb-20 text-center lg:text-left'>
                    <p className=' font-Space-Grotesk text-3xl font-bold my-16 text-pri-grey'>Sell your cards in {giftcardSteps.length} easy steps.</p>
                    {giftcardSteps.map((step, index) =>
                        <div key={index}>
                            <ProcessSteps position={step.position} description={step.description} title={step.title} icon={white_on_black_good_tick}/>
                        </div>
                    )}
                    <BlackBtn url={rootLinks.dashboard} text='Trade With Us'/>
                </div>
                <div className=' lg:w-1/3'>
                    <img className='md:w-10/12 2xl:w-11/12 -ml-1' src={giftcard_hand} alt="hand holding bitcoin"/>
                </div>
            </div>
    )
}
